import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { logout } from './../../actions';
import { parseJwt } from '../../@helpers/jwt';

const Container = styled.div`
    border-bottom: 2px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    ${props => props.mode.feat === true ? `background-color: ${props.mode.colors.headerBg};` : ``};
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px 8px;
`;

const Logo = styled(Link)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 13px auto 13px 0px;

    img {
        height: ${props => props.mode.feat===true?'84px':'64px'};
    }
    @media (max-width: 999px) {
        img {
            height: ${props => props.mode.feat===true?'74px':'54px'};
        }
    }
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 20px;
`;

const PhoneNumber = styled.a`
    display: block;
	text-decoration: none;
    font-style: normal;
    font-size: 20px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;
const Email = styled.a`
    display: block;
	text-decoration: none;
    font-family: 'Futura Light Italic';
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    font-size: 20px;
    @media (max-width: 999px) {
        & {
            font-size: 16px;
        }
    }
`;
const LinkOrders = styled(Link)`
    display: block;
    margin-top: 4px;
	// text-decoration: none;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    font-size: 16px;
    cursor: pointer;
    @media (max-width: 999px) {
        & {
            font-size: 14px;
        }
    }
`;

const LogoutButton = styled(Link)`
    width: 50px;
    height: 50px;
    border: none;
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    -webkit-mask: url(${require('./../../assets/img/logout.svg')}) no-repeat center;
    mask: url(${require('./../../assets/img/logout.svg')}) no-repeat center;
    cursor: pointer;
`;


class Header extends React.Component {

    logout = () => {
        this.props.logout();
    }

    render() {
		const {
            isLoggedIn,
            mode,
        } = this.props;
        
        if (!isLoggedIn) {
            return null;
        }

        if(mode.options.uiHeader === false) {
            return null;
        }
        
        const jwt = parseJwt(this.props.jwt);
        const isAdmin = jwt.scope.includes('Admin');

        return (
            <Container mode={mode}>
                <Content mode={mode}>
                    {mode.feat === true ? (
                            <Logo to="/" mode={mode}>
                                <img src={mode.logo.main} alt={`Les Ripeurs logo, en partenariat avec ${mode.name}`} />
                            </Logo>
                    ) : (
                        <Logo to="/" mode={mode}>
                            <img src={(require("../../assets/img/logo-ripeurs-orange.svg"))} alt="Logo Les Ripeurs" />
                        </Logo>
                    )}
                    <ContactContainer>
                        <PhoneNumber href="tel:+33186767046" mode={mode}>{isAdmin ? `Connecté en tant que "${jwt.id}"` : '01.86.76.70.46'}</PhoneNumber>
                        <Email href="mailto:contact@lesripeurs.com" mode={mode}>{isAdmin ? `${jwt.email}` : 'contact@lesripeurs.com'}</Email>
                        <LinkOrders to="/orders" mode={mode}>Mes commandes</LinkOrders>
                    </ContactContainer>
                    <LogoutButton to="/" onClick={this.logout} mode={mode}></LogoutButton>
                </Content>
            </Container>
        )
    }
};

const mapDispatchToProps = {
    logout
}

const mapStateToProps = state => ({
    isLoggedIn: state.userReducer.isLoggedIn,
	mode: state.mainReducer.mode,
	message: state.initializationReducer.message,
    jwt: state.userReducer.token,
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);