import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 20px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding: 20px 0px;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
    width: 100%;
    height: 98px;
    cursor: ${props => props.checked ? 'default' : 'pointer'};
    background-color: #ffffff;
`;
const FieldContainerLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
    width: 100%;
    height: 98px;
    text-decoration: none;
    cursor: pointer;
    background-color: #ffffff;
`;

const CustomLabel = styled.label`
    padding-right: 10px;
    cursor: ${props => props.checked ? 'default' : 'pointer'};
`;

const LabelTitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 16px;
    color: #8C8D8C;
    padding-bottom: 5px;
`;

const Info = styled.div`
    font-size: 14px;
    color: #AAAAAA;
`;

const CustomField = styled(Field)`
    display: none;
`;

const CustomCheckbox = styled.div`
    align-self: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#FFFFFF"};
`;

const Image = styled.img`
    padding-top: 1px;
`;

const DumpsterDropTypeSelector = ({
    dropType,
    setFieldValue,
    mode,
}) => {
    return (
        <div>
            <Title mode={mode}>Type de benne</Title>
            <FieldContainer checked={dropType === "depot"} onClick={() => setFieldValue("dropType", "depot")}>
                <CustomLabel checked={dropType === "depot"} htmlFor="depot">
                    <LabelTitle>Dépôt de benne</LabelTitle>
                    <Info>Nous vous déposons une benne sur un terrain privé ou sur la voie publique si vous avez une autorisation préfectorale, pour une durée maximale de 2 semaines.</Info>
                </CustomLabel>
                <CustomField
                    id="depot"
                    type="radio"
                    name="dropType"
                    value="depot"
                    checked={dropType === "depot"}
                />
                <CustomCheckbox mode={mode} checked={dropType === "depot"}>
                    <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                </CustomCheckbox>
            </FieldContainer>
            <FieldContainer checked={dropType === "echange"} onClick={() => setFieldValue("dropType", "echange")}>
                <CustomLabel checked={dropType === "echange"} htmlFor="echange">
                    <LabelTitle>Échange de benne</LabelTitle>
                    <Info>Nous venons récupérer votre benne pleine et nous vous déposons une benne vide.</Info>
                </CustomLabel>
                <CustomField
                    id="echange"
                    type="radio"
                    name="dropType"
                    value="echange"
                    checked={dropType === "echange"}
                />
                <CustomCheckbox mode={mode} checked={dropType === "echange"}>
                    <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                </CustomCheckbox>
            </FieldContainer>
            <FieldContainer checked={dropType === "passante"} onClick={() => setFieldValue("dropType", "passante")}>
                <CustomLabel checked={dropType === "passante"} htmlFor="passante">
                    <LabelTitle>Benne passante</LabelTitle>
                    <Info>Nous vous déposons une benne pour une durée de 30min à 45min que vous chargez. Cela ne nécessite pas d'autorisation préfectorale.</Info>
                </CustomLabel>
                <CustomField
                    id="passante"
                    type="radio"
                    name="dropType"
                    value="passante"
                    checked={dropType === "passante"}
                />
                <CustomCheckbox mode={mode} checked={dropType === "passante"}>
                    <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                </CustomCheckbox>
            </FieldContainer>
            <FieldContainerLink to="/orders">
                <CustomLabel>
                    <LabelTitle>Retrait définitif</LabelTitle>
                    <Info>
                        Historique de vos commandes bennes, vous permettant d'effectuer un retrait définitif.
                    </Info>
                </CustomLabel>
                <CustomCheckbox mode={mode} >
                    <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                </CustomCheckbox>
            </FieldContainerLink>
        </div>
    )
}

export default DumpsterDropTypeSelector;