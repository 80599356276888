/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Content from './../components/common/Content';
import Spinner from './../components/common/Spinner';
import FlowButtonsContainer from './FlowButtonsContainer';
import TruckWasteSelector from './../components/order/TruckWasteSelector';
import AddressSelectorContainer from './AddressSelectorContainer';
import TimeSlotSelectorContainer from './TimeSlotSelectorContainer';
import AccessSelector from '../components/order/AccessSelector';
import AdditionalData from '../components/order/AdditionalData';
import { validateFlow } from '../actions';
import * as Yup from 'yup';
import { Formik } from 'formik';

let truckSchemaItems = {
    rubbleBag: 'bag',
    mixBag: 'bag',
    plasterBag: 'bag',
    ironBag: 'bag',
    woodBag: 'bag',
    vegetalBag: 'bag',
    bulky: 'bag',
    mixM3: 'volume',
    plasterM3: 'volume',
    woodM3: 'volume',
    cardboardM3: 'volume',
    ironM3: 'volume',
    vegetalM3: 'volume',
    slateM3: 'volume',
};
for(let key in truckSchemaItems) {
    if(truckSchemaItems[key] === 'bag') {
        truckSchemaItems[key] = Yup.number()
            .required('Veuillez indiquer un nombre')
            .integer('Veuillez indiquer un nombre entier')
            .moreThan(-1, 'Le nombre indiqué doit être positif')
            .max(999, 'Le nombre indiqué est trop élevé');
    } else if(truckSchemaItems[key] === 'volume') {
        truckSchemaItems[key] = Yup.number()
            .required('Veuillez indiquer un nombre')
            .min(0, 'Le nombre indiqué doit être positif')
            .max(999, 'Le nombre indiqué est trop élevé')
            .test(
                'MAX_ONE_DIGIT',
                `Le nombre indiqué ne peut comporter qu'une seule décimale`,
                (value) => {
                    return value !== undefined && value.toString().match(/^((\d|[1-9]\d{1,2})(\.\d)?|\.\d)$/);
                },
            );
    }
}

const truckSchema = Yup.object().shape({
    ...truckSchemaItems,
    address:
        Yup.string(),
    postalCode:
        Yup.string(),
    timeSlot:
        Yup.string(),
    access:
        Yup.string(),
    floor:
        Yup.number()
            .when('access', {
                is: 'upstairs',
                then: Yup.number()
                    .integer(`Vous devez saisir un nombre entier`)
                    .required(`Vous devez saisir un étage`)
                    .min(1, `L'étage doit être supérieur à 0`)
                    .max(10, `L'étage doit être inférieur ou égal à 10`)
            }),
    contact:
        Yup.string(),
    comment:
        Yup.string(),
    reference:
        Yup.string(),
}).test(
    'AT_LEAST_ONE_VALUE',
    `Vous devez saisir au moins un type de déchet à évacuer`,
    (value) => {
        for(let key in truckSchemaItems) {
            if(value[key] > 0) { return true; }
        }
        return false;
    },
);

const Container = styled(Content)``;

const CustomForm = styled.form`
    display:flex;
    flex-direction: column;
`;

const AccessContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 40px;
    padding-bottom: 60px;
    justify-content: space-around;
`;

const AccessSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    @media (min-width: 1000px) {
        &:nth-child(1) {
            margin-right: 8px;
        }
        &:nth-child(2) {
            margin-left: 8px;
        }
    }
`;

const InfoText = styled.div`
    margin-bottom: 16px;
    text-align: center;
    color: #aaaaaa;
    font-style: italic;
`;

const Subtitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    line-height: 100%;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-top: 45px;
`;

const CustomError = styled.div`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 50px;
    font-size: 14px;
    text-align: center;
`;

const SubmitButton = styled.button`
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    cursor: pointer;
    border-radius: 5px;
    width: 265px;
    height: 52px;
    align-self: center;
    font-family: 'Futura';
    font-size: 18px;
    color: white;
    margin-top: 30px;
    margin-bottom: 40px;
`;

class TruckFlowContainer extends React.Component {
    decrementQuantity = (value) => {
        if (value > 1) {
            return value - 1;
        } else {
            return 0;
        }
    }

    decrementQuantityFloor = (value) => {
        if (value > 1) {
            return value - 1;
        } else {
            return 1;
        }
    }

    incrementQuantity = (value) => {
        if (value) {
            if(value < 999) {
                return value + 1;
            }
            return value;
        } else {
            value = 0;
            return value + 1;
        }
    }

    incrementQuantityFloor = (value) => {
        if (value && value < 10) {
            return value + 1;
        } else if (value >= 10) {
            return value;
        } else {
            value = 0;
            return value + 1;
        }
    }

    isInitialValid = (props) => {
        if (!props.validationSchema) {
            return true;
        }
        return props.validationSchema.isValidSync(props.initialValues);
    }

    render() {
        const {
            flowtype,
            flowValidationLoading,
            validateFlow,
            orderDetails,
            userBadSubmit,
            badSubmitErrors,
            mode,
        } = this.props;

        if (flowtype === "noflow" || undefined) {
            return <Redirect to="/home" />
        }
        
        return (
            <Container>
                <FlowButtonsContainer />

                <Formik
                    enableReinitialize={true}
                    isInitialValid={this.isInitialValid}
                    initialValues={{
                        rubbleBag: orderDetails.rubbleBag || 0,
                        mixBag: orderDetails.mixBag || 0,
                        plasterBag: orderDetails.plasterBag || 0,
                        ironBag: orderDetails.ironBag || 0,
                        woodBag: orderDetails.woodBag || 0,
                        vegetalBag: orderDetails.vegetalBag || 0,
                        bulky: orderDetails.bulky || 0,
                        mixM3: orderDetails.mixM3 || 0,
                        plasterM3: orderDetails.plasterM3 || 0,
                        woodM3: orderDetails.woodM3 || 0,
                        cardboardM3: orderDetails.cardboardM3 || 0,
                        ironM3: orderDetails.ironM3 || 0,
                        vegetalM3: orderDetails.vegetalM3 || 0,
                        slateM3: orderDetails.slateM3 || 0,
                        address: orderDetails.address || '',
                        postalCode: orderDetails.postalCode || '',
                        timeSlot: orderDetails.timeSlot || '',
                        access: orderDetails.access || '',
                        floor: orderDetails.floor || 1,
                        contact: orderDetails.contact || '',
                        comment: orderDetails.comment || '',
                        reference: orderDetails.reference || '',
                    }}
                    initialStatus={{
                        addressUnvalid: undefined
                    }}
                    validationSchema={truckSchema}
                    onSubmit={(values, actions) => {
                        const { history } = this.props;
                        validateFlow({ ...values, flowtype });
                        history.push('/summary');
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        values, status, isSubmitting, isValid, handleSubmit, setFieldValue, setStatus
                    }) => {
                        if(values.access === 'upstairs'
                        && (new Date(values.timeSlot)).getHours() >= 13) {
                            setFieldValue('timeSlot', '');
                        }

                        return (
                            <CustomForm onSubmit={handleSubmit}>

                                <TruckWasteSelector
                                    mode={mode}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    decrementQuantity={this.decrementQuantity}
                                    incrementQuantity={this.incrementQuantity}
                                />

                                <Subtitle mode={mode}>Les modalités d'accès à votre chantier</Subtitle>

                                <AccessContainer>
                                    <AccessSubContainer>
                                        <AddressSelectorContainer
                                            address={values.address}
                                            postalCode={values.postalCode}
                                            setFieldValue={setFieldValue}
                                            setStatus={setStatus}
                                            status={status.addressUnvalid}
                                            userBadSubmit={(userBadSubmit && (badSubmitErrors.indexOf("address") > -1 || badSubmitErrors.indexOf("postalCode") > -1)) && (!values.address || !values.postalCode)}
                                        />
                                        <AccessSelector
                                            mode={mode}
                                            flowtype="truck"
                                            access={values.access}
                                            floor={values.floor}
                                            setFieldValue={setFieldValue}
                                            decrementQuantity={this.decrementQuantityFloor}
                                            incrementQuantity={this.incrementQuantityFloor}
                                            userBadSubmit={userBadSubmit && (badSubmitErrors.indexOf("access") > -1 || badSubmitErrors.indexOf("floor") > -1) && (!values.access || !values.floor)}
                                        />
                                        {values.access==='upstairs'?(
                                            <InfoText>
                                                Les opérations en étage ne sont disponibles que le matin.
                                            </InfoText>
                                        ):null}
                                    </AccessSubContainer>

                                    <AccessSubContainer>
                                        <AdditionalData />
                                    </AccessSubContainer>
                                </AccessContainer>

                                <TimeSlotSelectorContainer
                                    timeSlot={values.timeSlot}
                                    setFieldValue={setFieldValue}
                                    userBadSubmit={userBadSubmit && badSubmitErrors.indexOf("timeSlot") > -1 && !values.timeSlot}
                                    disablePickupAfter={values.access==='upstairs' ? 661 : undefined}
                                />

                                {!isValid && (
                                    <CustomError>Vous devez saisir au moins un type de déchet à évacuer</CustomError>
                                )}

                                {flowValidationLoading ? (
                                    <Spinner mode={mode}/>
                                ) : (
                                        <SubmitButton type="submit" mode={mode}>
                                            Calculer le devis
                                        </SubmitButton>
                                    )}
                            </CustomForm>
                        )
                    }}
                </Formik>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    flowtype: state.orderReducer.flow,
    orderDetails: state.orderReducer.truckOrderDetails,
    flowValidationLoading: state.actionStateReducer.flowValidationLoading,
    userBadSubmit: state.orderReducer.userBadSubmit,
    badSubmitErrors: state.orderReducer.badSubmitErrors,
	mode: state.mainReducer.mode,
});

const mapDispatchToProps = {
    validateFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(TruckFlowContainer);
