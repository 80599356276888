import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.mode.feat===true?`column`:`row`};
    align-items: center;
    justify-content: center;
    height: 70px;
    text-align: center;
    color: ${props => props.mode.feat===true?`#777777`:`#ffffff`};
    font-style: italic;
    background-color: ${props => props.mode.feat===true?`transparent`:`#ff7d12`};
`;
const Links = styled.div`
    margin-top: 4px;
    font-size: 90%;
    a {
        margin: 0px 4px;
    }
`;

const Image = styled.img`
    width: 64px;
    margin-right: 8px;
`;

const Legal = styled.div`
    max-width: 800px;
`;

class Footer extends React.Component {
    render() {
		const {
            isLoggedIn,
            mode,
            legal,
        } = this.props;
        
        if (!isLoggedIn && mode.feat === false) {
            return null;
        }

        if(mode.options.uiHeader === false) {
            return null;
        }

        return (
            <Container mode={mode}>
                {mode.feat===true?(
                    <Legal>
                        {mode.texts.legal}
                    </Legal>
                ):(
                    <Image src={(require('./../../assets/img/logo-ripeurs-white.svg'))} alt="logo-ripeurs" />
                )}
                <Links>
                    <a href="https://www.lesripeurs.com/" target="_blank" rel="noopener noreferrer">Les Ripeurs</a>
                    —
                    <a href={legal.termsOfService.url} target="_blank" rel="noopener noreferrer">CGU</a>
                    –
                    <a href={legal.termsOfSales.url} target="_blank" rel="noopener noreferrer">CGV</a>
                    –
                    <a href={legal.legalMentions.url} target="_blank" rel="noopener noreferrer">Mentions légale</a><a href="/wtf.html" rel="noopener noreferrer" style={{marginLeft: '-4px', paddingRight:'8px'}}>s</a>
                </Links>
            </Container>
        )
    }
};

const mapStateToProps = state => ({
    isLoggedIn: state.userReducer.isLoggedIn,
	mode: state.mainReducer.mode,
	legal: state.initializationReducer.legal,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
