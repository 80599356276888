import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-top: 50px;
    padding-bottom: 40px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    @media (max-width: 999px) {
        & {
            padding: 10px;
        }
    }
`;

const Icon = styled.img`
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 20px;
    @media (max-width: 999px) {
        & {
            padding-right: 8px;
        }
    }
`;

const InfoTitle = styled.span`
    font-family: 'Futura Bold';
    font-size: 16px;
    padding-bottom: 5px;
    color: #8C8D8C;
`;

const InfoSubTitle = styled.span`
    font-family: 'Futura Italic';
    font-size: 14px;
    color: #ffa459;
`;

const InfoLabel = styled.span`
    font-family: 'Futura';
    font-size: 14px;
    color: #AAAAAA;
`;

const CustomField = styled(Field)`
    border: 1px solid #E0E2E4;
    border-radius: 5px;
    width: 45px;
    height: 45px;
    font-family: 'Futura Bold';
    font-size: 20px;
    text-align: center;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;

const CustomButton = styled.button`
    background: none;
    border: none;
    outline: none !important;
    height: 50px;
    cursor: pointer;
`;

const PlusButton = styled.img`
    padding-top: 5px;
`;

const ErrorMessageBigBag = styled(ErrorMessage)`
    font-family: "Futura Italic";
    color: #cc0000;
    margin-top: -8px;
    font-size: 14px;
`;

const BigBagWasteSelector = ({
    bigBagValue,
    bigBagRubbleValue,
    setFieldValue,
    decrementQuantity,
    incrementQuantity,
    mode,
}) => {
    return (
        <div>
            <Title mode={mode}>Vos déchets</Title>

            <Container>
                <Icon
                    src={`https://assets.ripeurs.net/common/images/waste/rubble.png`}
                    color="#eeb054"
                />
                <InfoContainer>
                    <InfoTitle>Big Bags gravats/terre</InfoTitle>
                    <InfoSubTitle>Prix plus avantageux à partir de 8 Big Bags</InfoSubTitle>
                    <InfoLabel>
                        Béton, gravier, tuile, céramique, brique, ciment, terre.
                    </InfoLabel>
                </InfoContainer>

                <CustomButton
                    type="button"
                    onClick={() => {
                        setFieldValue("bigBagNumber", 0);
                        setFieldValue("bigBagRubbleNumber", decrementQuantity(bigBagRubbleValue));
                    }}
                >
                    <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                </CustomButton>

                <CustomField mode={mode} type="number" min="0" max="999" name="bigBagRubbleNumber" value={bigBagRubbleValue} />

                <CustomButton
                    type="button"
                    onClick={() => {
                        setFieldValue("bigBagNumber", 0);
                        setFieldValue("bigBagRubbleNumber", incrementQuantity(bigBagRubbleValue));
                    }}
                >
                    <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                </CustomButton>
            </Container>
            <ErrorMessageBigBag name="bigBagRubbleNumber" component="div" />

            <Container>
                <Icon
                    src={`https://assets.ripeurs.net/common/images/waste/mixed.png`}
                    color="#8c8d8c"
                />
                <InfoContainer>
                    <InfoTitle>Big Bags mélangés</InfoTitle>
                    <InfoLabel>
                        Plâtre, bois, cartons, tout-venant, ...
                    </InfoLabel>
                </InfoContainer>

                <CustomButton
                    type="button"
                    onClick={() => {
                        setFieldValue("bigBagRubbleNumber", 0);
                        setFieldValue("bigBagNumber", decrementQuantity(bigBagValue));
                    }}
                >
                    <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                </CustomButton>

                <CustomField mode={mode} type="number" min="0" max="999" name="bigBagNumber" value={bigBagValue} />

                <CustomButton
                    type="button"
                    onClick={() => {
                        setFieldValue("bigBagRubbleNumber", 0);
                        setFieldValue("bigBagNumber", incrementQuantity(bigBagValue));
                    }}
                >
                    <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                </CustomButton>
            </Container>
            <ErrorMessageBigBag name="bigBagNumber" component="div" />
        </div>
    )
}

export default BigBagWasteSelector;