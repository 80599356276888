/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Content from './../components/common/Content';
import Spinner from './../components/common/Spinner';
import FlowButtonsContainer from './FlowButtonsContainer';
import BigBagWasteSelector from './../components/order/BigBagWasteSelector';
import AddressSelectorContainer from './AddressSelectorContainer';
import TimeSlotSelectorContainer from './TimeSlotSelectorContainer';
import AdditionalData from '../components/order/AdditionalData';
import { validateFlow } from '../actions';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Container = styled(Content)``;

const CustomForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const AccessContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 40px;
    padding-bottom: 60px;
    justify-content: space-around;
`;

const AccessSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    @media (min-width: 1000px) {
        &:nth-child(1) {
            margin-right: 8px;
        }
    }
`;

const Subtitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-top: 45px;
    line-height: 110%;
`;

const CustomError = styled.div`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 50px;
    font-size: 14px;
    text-align: center;
`;

const SubmitButton = styled.button`
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    cursor: pointer;
    border-radius: 5px;
    width: 265px;
    height: 52px;
    align-self: center;
    font-family: 'Futura';
    font-size: 18px;
    color: white;
    margin-top: 30px;
    margin-bottom: 40px;
`;

const bigBagSchema = Yup.object().shape({
    bigBagNumber:
        Yup.number()
            .test(
                'bigBagNumber-bigBagRubbleNumber',
                `Choisissez qu'un type de Big Bag.`,
                function(value) {
                    if((value < 1 && this.parent.bigBagRubbleNumber < 1)
                    || (value >= 1 && this.parent.bigBagRubbleNumber >= 1)) {
                        return false;
                    }
                    return true;
                }
            )
            .max(999, 'Pas plus de 999 BigBags'),
    bigBagRubbleNumber:
        Yup.number()
            .test(
                'bigBagRubbleNumber-bigBagNumber',
                `Choisissez qu'un type de Big Bag.`,
                function(value) {
                    if((value < 1 && this.parent.bigBagNumber < 1)
                    || (value >= 1 && this.parent.bigBagNumber >= 1)) {
                        return false;
                    }
                    return true;
                }
            )
            .max(999, 'Pas plus de 999 bigbags.'),
    address:
        Yup.string(),
    postalCode:
        Yup.string(),
    timeSlot:
        Yup.string(),
    contact:
        Yup.string(),
    comment:
        Yup.string(),
    reference:
        Yup.string(),
});

class BigBagFlowContainer extends React.Component {
    decrementQuantity = (value) => {
        if (value > 0) {
            return value - 1;
        } else {
            return 0;
        }
    }

    incrementQuantity = (value) => {
        if (value) {
            return value + 1;
        } else {
            value = 0;
            return value + 1;
        }
    }

    isInitialValid = (props) => {
        if (!props.validationSchema) {
            return true;
        }
        return props.validationSchema.isValidSync(props.initialValues);
    }

    render() {
        const {
            flowtype,
            flowValidationLoading,
            validateFlow,
            orderDetails,
            userBadSubmit,
            badSubmitErrors,
            mode,
        } = this.props;

        if (flowtype === "noflow" || undefined) {
            return <Redirect to="/home" />
        }

        return (
            <Container>
                <FlowButtonsContainer />

                <Formik
                    enableReinitialize={true}
                    isInitialValid={this.isInitialValid}
                    initialValues={{
                        bigBagNumber: orderDetails.bigBagNumber || 0,
                        bigBagRubbleNumber: orderDetails.bigBagRubbleNumber || 0,
                        address: orderDetails.address || '',
                        postalCode: orderDetails.postalCode || '',
                        timeSlot: orderDetails.timeSlot || '',
                        contact: orderDetails.contact || '',
                        comment: orderDetails.comment || '',
                        reference: orderDetails.reference || '',
                    }}
                    initialStatus={{
                        addressUnvalid: undefined
                    }}
                    validationSchema={bigBagSchema}
                    onSubmit={(values, actions) => {
                        const { history } = this.props;
                        validateFlow({ ...values, flowtype });
                        history.push('/summary');
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        values, status, isSubmitting, isValid, handleSubmit, setFieldValue, setStatus
                    }) => {
                        return (
                            <CustomForm onSubmit={handleSubmit}>

                                <BigBagWasteSelector
                                    mode={mode}
                                    bigBagValue={values.bigBagNumber}
                                    bigBagRubbleValue={values.bigBagRubbleNumber}
                                    setFieldValue={setFieldValue}
                                    decrementQuantity={this.decrementQuantity}
                                    incrementQuantity={this.incrementQuantity}
                                />

                                <Subtitle mode={mode}>Les modalités d'accès à votre chantier</Subtitle>

                                <AccessContainer>
                                    <AccessSubContainer>
                                        <AddressSelectorContainer
                                            address={values.address}
                                            postalCode={values.postalCode}
                                            setFieldValue={setFieldValue}
                                            setStatus={setStatus}
                                            status={status.addressUnvalid}
                                            userBadSubmit={(userBadSubmit && (badSubmitErrors.indexOf("address") > -1 || badSubmitErrors.indexOf("postalCode") > -1)) && (!values.address || !values.postalCode)}
                                        />
                                    </AccessSubContainer>
                                    <AccessSubContainer>
                                        <AdditionalData />
                                    </AccessSubContainer>
                                </AccessContainer>

                                <TimeSlotSelectorContainer
                                    timeSlot={values.timeSlot}
                                    setFieldValue={setFieldValue}
                                    userBadSubmit={userBadSubmit && badSubmitErrors.indexOf("timeSlot") > -1 && !values.timeSlot}
                                />

                                {!isValid && (
                                    <CustomError>Vous devez saisir au moins un type de déchet à évacuer</CustomError>
                                )}

                                {flowValidationLoading ? (
                                    <Spinner mode={mode} />
                                ) : (
                                        <SubmitButton type="submit" mode={mode}>
                                            Calculer le devis
                                        </SubmitButton>
                                    )}
                            </CustomForm>
                        )
                    }}
                </Formik>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    flowtype: state.orderReducer.flow,
    orderDetails: state.orderReducer.bigbagOrderDetails,
    flowValidationLoading: state.actionStateReducer.flowValidationLoading,
    userBadSubmit: state.orderReducer.userBadSubmit,
    badSubmitErrors: state.orderReducer.badSubmitErrors,
	mode: state.mainReducer.mode,
});

const mapDispatchToProps = {
    validateFlow
};

export default connect(mapStateToProps, mapDispatchToProps)(BigBagFlowContainer);
