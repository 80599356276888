import {
	LOGOUT,
	LOGIN_SUCCESS,
	GET_CARDS_AND_IBANS_SUCCESS
} from '../constants/action-types';

const initialState = {
	isLoggedIn: false,
	user: null,
	token: null,
};

function userReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			(window.dataLayer||[]).push({
				event: 'rip_user_login',
				method: 'email',
				userId: action.payload.user.id,
			});
			return {
				...state,
				isLoggedIn: true,
				user: action.payload.user,
				token: action.payload.token
			};
		case LOGOUT:
			(window.dataLayer||[]).push({
				event: 'rip_user_logout',
			});
			return {
				...state,
				isLoggedIn: false,
				user: undefined,
				token: undefined,
			};
		case GET_CARDS_AND_IBANS_SUCCESS:
			return {
				...state,
				cards: action.payload.cards,
				ibans: action.payload.ibans
			}
		default:
			return state;
	}
}

export default userReducer;
