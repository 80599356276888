import {
    GET_INSTALLATION_SUCCESS,
} from '../constants/action-types';

const initialState = {
    pickUpConditions: {}
}

function initializationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_INSTALLATION_SUCCESS:
            return {
                ...state,
                email: action.payload.email,
                number: action.payload.number,
                openHours: action.payload.openHours,
                pickUpConditions: action.payload.pickupConditions,
                enseignes: action.payload.enseignes,
                referral: action.payload.referral,
                registrationClose: action.payload.registrationClose,
                tcLink: action.payload.tcLink,
                activeDumpsters: action.payload.activeDumpsters,
                activeCraneTrucks: action.payload.activeCraneTrucks,
                message: action.payload.message||null,
                legal: action.payload.legal,
            };
        default:
            return state;
    }
}

export default initializationReducer;