import React from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'formik';

const Container = styled.div`
    margin-bottom: 30px;
    padding: 5px;
    border: ${props => props.userbadsubmit === "true" ? "2px solid #cc0000" : "none"};
    border-radius: 4px;
`;

const Title = styled.div`
    font-family: "Futura Bold";
    font-size: 20px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-bottom: 30px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
`;

const CustomField = styled(Field)`
    display: none;
    margin-left: 20px;
    margin-right: 15px;
`;

const CustomCheckbox = styled.div`
    align-self: center;
    width: 17px;
    height: 17px;
    border-radius: 4px;
    border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#FFFFFF"};
	margin-right: 10px;
    cursor: pointer;
`;

const Check = styled.img`
	height: 15px;
`;

const PickUpConditions = ({
    values,
    userBadSubmit,
    mode,
}) => {
    return (
        <Container userbadsubmit={userBadSubmit.toString()}>
            <Title mode={mode}>Conditions d'évacuation</Title>
            <FieldArray
                name="conditions"
                render={(arrayHelpers) => (
                    <div>
                        {values.conditions && values.conditions.map((condition, index) => (
                            <CheckboxContainer key={index}>
                                <CustomField id={`conditions[${index}].name`} type="checkbox" name={`conditions[${index}].name`} />
                                <CustomCheckbox mode={mode} checked={condition.checked} onClick={() => arrayHelpers.replace(index, { ...condition, checked: !condition.checked })}>
                                    <Check src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                                </CustomCheckbox>
                                <label htmlFor={`condition[${index}].name`} onClick={() => arrayHelpers.replace(index, { ...condition, checked: !condition.checked })}>{condition.label}</label>
                            </CheckboxContainer>
                        ))}
                    </div>
                )}
            />
        </Container>
    )
}

export default PickUpConditions;