import {
	ERROR,
	WARNING,
	SUCCESS,
	DISMISS_ERROR,
	AUTO_DISMISS
} from '../constants/action-types';

const initialState = {
	list: [],
};

const errorsReducer = (state = initialState, action) => {
	const { list } = state;
	let newList = [...list];
	switch (action.type) {
		case ERROR:
			console.log(action);
			if(action.payload === undefined) {
				action.payload = {
					message: `Erreur interne, vérifiez votre connexion internet (Wifi, 4G, ...).`,
					messageSecondary: `Si le problème persiste, contactez le support téléphonique.`,
				};
			}
			let message = action.payload.message||action.payload.data.message;
			let messageSecondary = action.payload.messageSecondary||'';
			if(action.payload.status === 500) {
				message = 'Erreur interne du serveur, contactez le support téléphonique.';
				messageSecondary = action.payload.data.message;
				setTimeout(() => {
					try {
						let data  = `DATE: ${JSON.stringify(new Date())}\n`;
							data += `URL: "${action.payload.config.method.toUpperCase()} ${action.payload.config.url}"\n`;
							data += `HEADERS:\n${JSON.stringify(action.payload.config.headers)}\n`;
							data += `PARAMS:\n${JSON.stringify(action.payload.config.params)}\n`;
							data += `DATA:\n${action.payload.config.data}`;
							var newImage = new Image();
							newImage.src = `https://hooks.zapier.com/hooks/catch/2426676/oh6r8eg/?data=${encodeURIComponent(data)}`;
					} catch(e) {}
				}, 100);
			}
			if(action.payload.config !== undefined
			&& action.payload.config.params._rid !== undefined) {
				messageSecondary += ` [RID: ${action.payload.config.params._rid}]`;
			}
			newList.unshift({
				type: ERROR,
				message: message,
				messageSecondary: messageSecondary,
			});
			return { ...state, list: newList };
		case WARNING:
			newList.unshift({
				type: WARNING,
				message: action.payload.message,
			});
			return { ...state, list: newList };
		case SUCCESS:
			newList.unshift({
				type: SUCCESS,
				message: action.payload.message,
			});
			return { ...state, list: newList };
		case DISMISS_ERROR:
			newList.splice(0, 1);
			return { ...state, list: newList };
		case AUTO_DISMISS:
			newList.pop();
			return { ...state, list: newList };
		default:
			return state;
	}
};

export default errorsReducer;
