import * as api from '../services/api';
import lsp from '../services/localStoragePower';
import { store } from '../store';

export const signUp = async (email, password) => {
    try {
        const result = await api.signUp(email, password);
        if (result) {
            lsp.setItem('token', result.token);
        }
        return result;
    } catch (e) {
        throw e;
    }
};

export const login = async (identifier, password) => {
    try {
        const result = await api.login(identifier, password);
        if (result) {
            lsp.setItem('token', result.token);
        }
        return result;
    } catch (e) {
        throw e;
    }
};

export const isLoggedIn = () => (store.getState().userReducer.isLoggedIn);

export const logout = () => {
    lsp.clear();
};
