import React from 'react';
import { Dialog } from '@reach/dialog'
import styled from 'styled-components';

const CustomDialog = styled(Dialog)`
    &[data-reach-dialog-content] {
        position: relative;
        width: fit-content;
        min-width: 600px;
        max-width: 700px;
        min-height: 450px;
        margin: 5vh auto;
        @media (max-width: 999px) {
            min-width: 0;
            max-width: 100vh;
        }
        display: flex;
        flex-direction: column;
    }
`;

const Title = styled.div`
    font-size: 24px;
    text-align: center;
    margin-bottom: 60px;
`;

const ErrorsContainer = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    margin-bottom: 20px;
    line-height: 24px;
`;

const ErrorLabel = styled.div`
    line-height: 24px;
    margin-left: 10px;
    margin-bottom: 5px;
`;

const ValidateButton = styled.button`
    background-color: #FF7D12;
    border: none;
    cursor: pointer;
    color: white;
    width: 100%;
    height: 50px;
    font-size: 18px;
    text-transform: uppercase;
    &:focus {
        outline: none;
    }
    margin-top: auto;
`;

class ErrorsPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            callback: null,
            errors: []
        }
    }

    show = (callback) => () => {
        // event.preventDefault();

        // event = {
        //     ...event,
        //     target: { ...event.target, value: event.target.value }
        // }
        
        window.scrollTo(0, 0);
        window.parent.postMessage({
            type: 'APP_PAGE_CHANGE',
            data: {},
        }, '*');

        let errors = []

        for (let [key, value] of Object.entries(this.props.errors)) {
            if (key === "conditions") {
                const item = value.find(v => v && v.checked);
                if(item) {
                    errors.push(item.checked)
                }
            } else {
                errors.push(value)
            }
        }

        this.setState({
            open: true,
            errors: errors,
            callback: () => callback(this.props.errors)
        })
    }

    hide = () => this.setState({ open: false })

    confirm = () => {
        this.state.callback();
        this.hide();
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children(this.show)}

                {this.state.open && (
                    <CustomDialog>
                        <Title>Commande à compléter !</Title>

                        <ErrorsContainer>
                            <Subtitle>
                                Veuillez compléter les informations suivantes pour confirmer votre commande:
                            </Subtitle>
                            {this.state.errors.map((error, index) => (
                                <ErrorLabel key={index}>• {error}</ErrorLabel>
                            ))}

                        </ErrorsContainer>

                        <ValidateButton onClick={this.confirm}>OK</ValidateButton>

                    </CustomDialog>
                )}
            </React.Fragment>
        )
    }
};

export default ErrorsPopup;