import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
			window.parent.postMessage({
				type: 'APP_PAGE_CHANGE',
				data: {},
			}, '*');
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);