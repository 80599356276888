import {
    RESET_STATE,
    SET_CURRENT_FLOW,
    VALIDATE_FLOW,
    GET_PRICE,
    GET_INSTALLATION,
    VALIDATE_VOUCHER,
    GET_CARDS_AND_IBANS,
    USER_BAD_SUBMIT,
    USER_GOOD_SUBMIT,
    GET_ORDERS,
    GET_ORDERS_SUCCESS,
} from './../constants/action-types';

export const resetState = () => ({
    type: RESET_STATE
});

export const setCurrentFlow = (flow) => ({
    type: SET_CURRENT_FLOW,
    payload: {
        flow,
    }
});

export const validateFlow = (orderData) => ({
    type: VALIDATE_FLOW,
    payload: {
        orderData,
    }
})

export const getPrice = (orderData) => ({
    type: GET_PRICE,
    payload: {
        orderData,
    }
})

export const getInstallation = () => ({
    type: GET_INSTALLATION,
})

export const validateVoucher = (voucher, userId) => ({
    type: VALIDATE_VOUCHER,
    payload: {
        voucher,
        userId
    }
})

export const userBadSubmit = (errors) => ({
    type: USER_BAD_SUBMIT,
    payload: errors
})

export const userGoodSubmit = () => ({
    type: USER_GOOD_SUBMIT
})

export const getCardsAndIbans = () => ({
    type: GET_CARDS_AND_IBANS,
})

export const getOrders = () => ({
    type: GET_ORDERS,
})
export const getOrdersSuccess = () => ({
    type: GET_ORDERS_SUCCESS,
})