import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-bottom: 40px;
    padding-top: 60px;
`;

const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 20px;
    @media (max-width: 999px) {
        & {
            justify-content: center;
        }
        & > label {
            margin: 0px 8px 8px 8px;
        }
    }
`;

const CustomRadio = styled.div`
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#8C8D8C"};
    background-color: ${props => props.disabled && "#E0E2E4"};
    cursor: ${props => props.disabled && "not-allowed"};

    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
    line-height: 75px;
`;

const CustomField = styled(Field)`
    display: none;
`;

const DumpsterWasteSizeSelector = ({
    wasteType,
    wasteSize,
    activeDumpsters,
    mode,
}) => {
    return (
        <div>
            <Title mode={mode}>Taille de bennes (en m3)</Title>
            <RadioContainer>
                <label htmlFor="8">
                    <CustomRadio mode={mode} checked={wasteSize === "8"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 8)}>
                        8
                    </CustomRadio>
                </label>
                <CustomField id="8" type="radio" name="wasteSize" value={8} checked={wasteSize === "8"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 8)} />

                <label htmlFor="10">
                    <CustomRadio mode={mode} checked={wasteSize === "10"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 10)}>
                        10
                    </CustomRadio>
                </label>
                <CustomField id="10" type="radio" name="wasteSize" value={10} checked={wasteSize === "10"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 10)} />

                {/* <label htmlFor="12">
                    <CustomRadio mode={mode} checked={wasteSize === "12"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 12)}>
                        12
                    </CustomRadio>
                </label>
                <CustomField id="12" type="radio" name="wasteSize" value={12} checked={wasteSize === "12"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 12)} /> */}

                <label htmlFor="15">
                    <CustomRadio mode={mode} checked={wasteSize === "15"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 15)}>
                        15
                    </CustomRadio>
                </label>
                <CustomField id="15" type="radio" name="wasteSize" value={15} checked={wasteSize === "15"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 15)} />

                <label htmlFor="20">
                    <CustomRadio mode={mode} checked={wasteSize === "20"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 20)}>
                        20
                    </CustomRadio>
                </label>
                <CustomField id="20" type="radio" name="wasteSize" value={20} checked={wasteSize === "20"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 20)} />

                <label htmlFor="30">
                    <CustomRadio mode={mode} checked={wasteSize === "30"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 30)}>
                        30
                    </CustomRadio>
                </label>
                <CustomField id="30" type="radio" name="wasteSize" value={30} checked={wasteSize === "30"} disabled={wasteType && !activeDumpsters[wasteType].availableSizes.some(element => element === 30)} />
            </RadioContainer>
        </div>
    )
}

export default DumpsterWasteSizeSelector;