/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Content from './../components/common/Content';
import FlowButtonsContainer from './FlowButtonsContainer';
import DumpsterWasteTypeSelector from '../components/order/DumpsterWasteTypeSelector';
import DumpsterWasteSizeSelector from '../components/order/DumpsterWasteSizeSelector';
import AddressSelectorContainer from './AddressSelectorContainer';
import TimeSlotSelectorContainer from './TimeSlotSelectorContainer';
import AccessSelector from '../components/order/AccessSelector';
import AdditionalData from '../components/order/AdditionalData';
import { validateFlow } from '../actions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DumpsterDropTypeSelector from '../components/order/DumpsterDropTypeSelector';
import Spinner from '../components/common/Spinner';

const dumpsterSchema = Yup.object().shape({
    dropType:
        Yup.string()
            .required(),
    wasteType:
        Yup.string()
            .required(),
    wasteSize:
        Yup.number()
            .moreThan(0, "test")
            .required(),
    address:
        Yup.string(),
    postalCode:
        Yup.string(),
    timeSlot:
        Yup.string(),
    access:
        Yup.string(),
    contact:
        Yup.string(),
    comment:
        Yup.string(),
    reference:
        Yup.string(),
});

const Container = styled(Content)``;

const CustomForm = styled.form`
    display:flex;
    flex-direction: column;
`;

const DumpsterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 50px;
    & > div {
        flex: 1;
        min-width: 300px;
    }
    @media (min-width: 1000px) {
        & > div:first-child {
            margin-right: 8px;
        }
        & > div:last-child {
            margin-left: 8px;
        }
    }
`;

const AccessContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 60px;
    justify-content: space-around;
`;

const AccessSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    @media (min-width: 1000px) {
        &:nth-child(1) {
            margin-right: 8px;
        }
        &:nth-child(2) {
            margin-left: 8px;
        }
    }
`;

const Subtitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-top: 45px;
`;

const CustomError = styled.div`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 50px;
    font-size: 14px;
    text-align: center;
`;

const CustomWarning = styled.div`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 10px;
    font-size: 20px;
    text-align: center;
`;

const SubmitButton = styled.button`
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    cursor: pointer;
    border-radius: 5px;
    width: 265px;
    height: 52px;
    align-self: center;
    font-family: 'Futura';
    font-size: 18px;
    color: white;
    margin-top: 30px;
    margin-bottom: 40px;
`;

class DumpsterFlowContainer extends React.Component {
    isInitialValid = (props) => {
        if (!props.validationSchema) {
            return true;
        }
        return props.validationSchema.isValidSync(props.initialValues);
    }

    getWasteBorderColor = (type) => {
        switch (type) {
            case "rubble":
                return "#EEB054";
            case "mixed":
                return "#8C8D8C";
            case "wood":
                return "#7294EA";
            case "furniture":
                return "#59BDBC";
            case "vegetal":
                return "#7CCF6B";
            case "iron":
                return "#FA6684";
            default:
                return "#ff7d12";
        }

    }

    render() {
        const {
            flowtype,
            activeDumpsters,
            flowValidationLoading,
            validateFlow,
            orderDetails,
            userBadSubmit,
            badSubmitErrors,
            mode,
        } = this.props;

        if (flowtype === "noflow" || undefined) {
            return <Redirect to="/home" />
        }
        return (
            <Container>
                <FlowButtonsContainer />

                <Formik
                    enableReinitialize={true}
                    isInitialValid={this.isInitialValid}
                    initialValues={{
                        dropType: orderDetails.dropType || 'depot',
                        wasteType: orderDetails.wasteType || '',
                        wasteSize: orderDetails.wasteSize || '',
                        address: orderDetails.address || '',
                        postalCode: orderDetails.postalCode || '',
                        timeSlot: orderDetails.timeSlot || '',
                        access: orderDetails.access || '',
                        contact: orderDetails.contact || '',
                        comment: orderDetails.comment || '',
                        reference: orderDetails.reference || '',
                    }}
                    initialStatus={{
                        addressUnvalid: undefined
                    }}
                    validationSchema={dumpsterSchema}
                    onSubmit={(values, actions) => {
                        const { history } = this.props;
                        validateFlow({ ...values, flowtype, previousOrderId: orderDetails.previousOrderId });
                        history.push('/summary');
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        values, status, isSubmitting, isValid, handleSubmit, setFieldValue, setStatus
                    }) => {
                        return (
                            <CustomForm onSubmit={handleSubmit}>

                                <DumpsterContainer>
                                    <DumpsterDropTypeSelector
                                        mode={mode}
                                        dropType={values.dropType}
                                        setFieldValue={setFieldValue}
                                    />

                                    <DumpsterWasteTypeSelector
                                        mode={mode}
                                        setFieldValue={setFieldValue}
                                        activeDumpsters={activeDumpsters}
                                        dumpsterWasteType={values.wasteType}
                                        getColor={this.getWasteBorderColor}
                                    />
                                </DumpsterContainer>

                                <DumpsterWasteSizeSelector
                                    mode={mode}
                                    wasteType={values.wasteType}
                                    wasteSize={values.wasteSize}
                                    activeDumpsters={activeDumpsters}
                                />
                                {values.wasteType === "mixed" && (parseInt(values.wasteSize) >= 15) && (
                                    <CustomWarning>Attention, pas de gravats lourds et de carreaux de plâtre dans les bennes de 15m3 et 30m3</CustomWarning>
                                )}
                                {values.wasteType === "furniture" && (
                                    <CustomWarning>Créneau horaires disponibles sous 72h</CustomWarning>
                                )}


                                <Subtitle mode={mode}>Les modalités d'accès à votre chantier</Subtitle>

                                <AccessContainer>
                                    <AccessSubContainer>
                                        <AddressSelectorContainer
                                            address={values.address}
                                            postalCode={values.postalCode}
                                            setFieldValue={setFieldValue}
                                            setStatus={setStatus}
                                            status={status.addressUnvalid}
                                            userBadSubmit={(userBadSubmit && (badSubmitErrors.indexOf("address") > -1 || badSubmitErrors.indexOf("postalCode") > -1)) && (!values.address || !values.postalCode)}
                                        />
                                        <AccessSelector
                                            mode={mode}
                                            flowtype="dumpster"
                                            access={values.access}
                                            setFieldValue={setFieldValue}
                                            userBadSubmit={userBadSubmit && badSubmitErrors.indexOf("access") > -1 && !values.access}
                                        />
                                    </AccessSubContainer>

                                    <AccessSubContainer>
                                        <AdditionalData commentPH="Type de benne (à porte, à chaîne, grutable), date de retrait, accessibilité, ..." />
                                    </AccessSubContainer>
                                </AccessContainer>

                                <TimeSlotSelectorContainer
                                    title={'Date du créneau '+(values.dropType==='echange'?`d'échange`:(values.dropType==='passante'?`d'arrivée`:`de dépôt`))}
                                    timeSlot={values.timeSlot}
                                    wasteType={values.wasteType}
                                    setFieldValue={setFieldValue}
                                    userBadSubmit={userBadSubmit && badSubmitErrors.indexOf("timeSlot") > -1 && !values.timeSlot}
                                />

                                {!isValid && (
                                    <CustomError>Vous devez saisir le type de benne, le type de déchet et une taille de benne pour accéder au devis</CustomError>
                                )}

                                {flowValidationLoading ? (
                                    <Spinner mode={mode} />
                                ) : (
                                        <SubmitButton type="submit" mode={mode}>
                                            Valider
                                        </SubmitButton>
                                    )}
                            </CustomForm>
                        )
                    }}
                </Formik>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    flowtype: state.orderReducer.flow,
    activeDumpsters: state.initializationReducer.activeDumpsters,
    orderDetails: state.orderReducer.dumpsterOrderDetails,
    flowValidationLoading: state.actionStateReducer.flowValidationLoading,
    userBadSubmit: state.orderReducer.userBadSubmit,
    badSubmitErrors: state.orderReducer.badSubmitErrors,
	mode: state.mainReducer.mode,
});

const mapDispatchToProps = {
    validateFlow
};

export default connect(mapStateToProps, mapDispatchToProps)(DumpsterFlowContainer);
