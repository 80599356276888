// GLOBAL ACTIONS
export const APP_LOCK_ADD = "APP_LOCK_ADD";
export const APP_LOCK_SUB = "APP_LOCK_SUB";
export const GET_INSTALLATION = "GET_INSTALLATION";
export const GET_INSTALLATION_SUCCESS = "GET_INSTALLATION_SUCCESS";
export const SET_MODE = "SET_MODE";

// ERROR ACTIONS
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';
export const DISMISS_ERROR = 'DISMISS_ERROR';
export const AUTO_DISMISS = 'AUTO_DISMISS';

// ACTION STATE ACTIONS
export const SET_ACTION_STATE = 'SET_ACTION_STATE';
export const CLEAR_ACTION_STATE = 'CLEAR_ACTION_STATE';

// USER ACTIONS
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const LOGIN = 'LOGIN';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS_STOP = 'PASSWORD_RESET_SUCCESS_STOP';
export const LOGOUT = 'LOGOUT';

// ORDER ACTIONS
export const RESET_STATE = "RESET_STATE";
export const RESET_STATE_LIGHT = "RESET_STATE_LIGHT";
export const SET_CURRENT_FLOW = "SET_CURRENT_FLOW";
export const VALIDATE_FLOW = "VALIDATE_FLOW";
export const GET_PRICE = "GET_PRICE";
export const GET_PRICE_SUCCESS = "GET_PRICE_SUCCESS";
export const VALIDATE_VOUCHER = "VALIDATE_VOUCHER";
export const VALIDATE_VOUCHER_SUCCESS = "VALIDATE_VOUCHER_SUCCESS";
export const VALIDATE_VOUCHER_SUCCESS_AMOUNT = "VALIDATE_VOUCHER_SUCCESS_AMOUNT";
export const VALIDATE_VOUCHER_SUCCESS_PERCENT = "VALIDATE_VOUCHER_SUCCESS_PERCENT";
export const VALIDATE_VOUCHER_FAILURE = "VALIDATE_VOUCHER_FAILURE";
export const GET_CARDS_AND_IBANS = "GET_CARDS_AND_IBANS";
export const GET_CARDS_AND_IBANS_SUCCESS = "GET_CARDS_AND_IBANS_SUCCESS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";

// CHECKOUT ACTIONS
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_CARD = "CREATE_CARD";
export const CREATE_IBAN = "CREATE_IBAN";
export const USER_BAD_SUBMIT = "USER_BAD_SUBMIT";
export const USER_GOOD_SUBMIT = "USER_GOOD_SUBMIT";
export const ORDER_SUCCESS = "ORDER_SUCCESS"