import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-top: 50px;
    padding-bottom: 40px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    @media (max-width: 999px) {
        & {
            padding: 10px;
        }
    }
`;
const Icon = styled.img`
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 20px;
    @media (max-width: 999px) {
        & {
            padding-right: 8px;
        }
    }
`;

const InfoTitle = styled.span`
    font-family: 'Futura Bold';
    font-size: 16px;
    padding-bottom: 5px;
    color: #8C8D8C;
`;

const InfoSubTitle = styled.span`
    font-family: 'Futura Italic';
    font-size: 14px;
    color: #ffa459;
`;

const InfoLabel = styled.span`
    font-family: 'Futura';
    font-size: 14px;
    color: #AAAAAA;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #E0E2E4;
    border-radius: 5px;
    height: 60px;
`;

const CustomField = styled(Field)`
    border: none;
    width: 65px;
    font-family: 'Futura Bold';
    font-size: 20px;
    text-align: center;
    color: ${props => props.value > 0 ? props.mode.feat===true?props.mode.colors.main:'#ff7d12' : "#000000"};
    @media (max-width: 999px) {
        & {
            width: 50px;
        }
    }
`;

const FieldUnit = styled.span`
    font-size: 11px;
    color: #AAAAAA;
    text-align: center;
`;

const CustomButton = styled.button`
    background: none;
    border: none;
    outline: none !important;
    height: 50px;
    cursor: pointer;
`;

const PlusButton = styled.img`
    padding-top: 5px;
`;

const ErrorMessageCraneTruck = styled(ErrorMessage)`
    font-family: "Futura Italic";
    color: #cc0000;
    margin-top: -8px;
    font-size: 14px;
`;

const CraneTruckWasteSelector = ({
    values: {
        mixM3, rubbleM3, dirtM3
    },
    setFieldValue,
    decrementQuantity,
    incrementQuantity,
    activeCraneTrucks,
    mode
}) => {
    return (
        <div>
            <Title mode={mode}>Vos déchets</Title>

            {activeCraneTrucks.mixed.active === true && (
                <>
                    <Container>
                        <Icon
                            src={`https://assets.ripeurs.net/common/images/waste/mixed.png`}
                            color="#8C8D8C"
                        />
                        <InfoContainer>
                            <InfoTitle>Mélangés</InfoTitle>
                            <InfoSubTitle>Pas de gravats/terre</InfoSubTitle>
                            <InfoLabel>
                                BA13, placoplâtre, tout-venant, ...
                            </InfoLabel>
                        </InfoContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("mixM3", decrementQuantity(mixM3));
                            }}
                        >
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode} type="number" min="0" max="100" step="0.5" name="mixM3" value={mixM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("mixM3", incrementQuantity(mixM3));
                            }}
                        >
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </Container>
                    <ErrorMessageCraneTruck name="mixM3" component="div" />
                </>
            )}
            {activeCraneTrucks.rubble.active === true && (
                <>
                    <Container>
                        <Icon
                            src={`https://assets.ripeurs.net/common/images/waste/rubble.png`}
                            color="#EEB054"
                        />
                        <InfoContainer>
                            <InfoTitle>Gravats</InfoTitle>
                            <InfoLabel>
                                Béton, gravier, tuile, céramique, brique, ciment
                            </InfoLabel>
                        </InfoContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("rubbleM3", decrementQuantity(rubbleM3));
                            }}
                        >
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode} type="number" min="0" max="100" step="0.5" name="rubbleM3" value={rubbleM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("rubbleM3", incrementQuantity(rubbleM3));
                            }}
                        >
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </Container>
                    <ErrorMessageCraneTruck name="rubbleM3" component="div" />
                </>
            )}
            
            {activeCraneTrucks.dirt.active === true && (
                <>
                    <Container>
                        <Icon
                            src={`https://assets.ripeurs.net/common/images/waste/dirt.png`}
                            color="#795548"
                        />
                        <InfoContainer>
                            <InfoTitle>Terre</InfoTitle>
                            <InfoLabel>
                                Terre inerte non polluée
                            </InfoLabel>
                        </InfoContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("dirtM3", decrementQuantity(dirtM3));
                            }}
                        >
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode} type="number" min="0" max="100" step="0.5" name="dirtM3" value={dirtM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton
                            type="button"
                            onClick={() => {
                                setFieldValue("dirtM3", incrementQuantity(dirtM3));
                            }}
                        >
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </Container>
                    <ErrorMessageCraneTruck name="dirtM3" component="div" />
                </>
            )}
        </div>
    )
}

export default CraneTruckWasteSelector;