import Axios from 'axios';
import lsp from '../services/localStoragePower';
import { apiConfig } from '../config';
import { loadUtms } from '../@helpers/utms';

export const api = Axios.create({
	baseURL: apiConfig.apiUrl,
});

export const securedApi = Axios.create({
	baseURL: apiConfig.apiUrl,
});

let apiInterceptorRequestVersion = (config) => {
	let os = 'web';
	let mode = window.RIPEURS_MODE||null;
	if(mode === 'pdb') {
		os = 'web-pdb';
	}
	let rid = Math.round((Math.random()*1000000000))
				.toString('36')
				.toUpperCase()
				.replace(/[01OIL]/ig, '8');
	config.params = {
		...(config.params || {}),
		_os: os+'_0',
		_version: '3.10.0',
		_rid: rid,
	};
	return config;
};
api.interceptors.request.use(apiInterceptorRequestVersion);
securedApi.interceptors.request.use(apiInterceptorRequestVersion);

securedApi.interceptors.request.use(
	async (config) => {
		// Do something before request is sent
		const newConfig = config;
		newConfig.headers.Authorization = await lsp.getItem('token');
		return config;
	},
	error => Promise.reject(error),
);

securedApi.interceptors.response.use(
	response => response,
	(error) => {
		if(error.response
		&& error.response.status === 401) {
			lsp.clear();
			window.location.href = '/';
		}
		return Promise.reject(error);
	},
);

export const login = async ({ email, password }) => {
	try {
		const result = await api.post('/auth/login', { email, password });

        if (result.data.token !== null) {
			return result;
        }

		let error;
		if (result.data.error === 'user_not_exist') {
			error = `L'adresse email utilisée n'existe pas.`;
		} else if (result.data.error === 'user_not_access_level') {
			error = `Vous n'avez pas les droits pour vous connecter.`;
		} else if (result.data.error === 'user_invalid_password') {
			error = `Votre mot de passe est invalide, merci de recommencer.`;
		} else {
			error = `Une erreur est survenue.`;
		}
		throw {
			response: {
				data: {
					message: error,
				},
			},
		};
	} catch (e) {
		throw e;
	}
};
export const passwordReset = async ({ email }) => {
	try {
		const result = await api.get('/auth/resetToken', { params: { email } });

        if (result.status === 200) {
			return { reset: true };
        }
	} catch (e) {}
	return { reset: false };
};

export const signUp = async ({
	firstName,
	lastName,
	phone,
	password,
	email,
	clientType,
	nameCompany,
	isSubscribed,
	administrativeId,
	addressFull,
	isPartnerClient,
	referral,
	referralStore,
	commercialReference,
}) => {
	referralStore = referral === 'plateforme' && referralStore === '' ? 'Site Internet' : referralStore;
	try {
		const utms = await loadUtms();
		const result = await api.post('/auth/signup', {
			firstName,
			lastName,
			phone,
			password,
			email,
			clientType,
			nameCompany: (clientType==='individual'?null:nameCompany),
			isSubscribed,
			administrativeId: (clientType==='individual'?null:administrativeId||null),
			addressFull: addressFull||null,
			isPartnerClient,
			referral,
			referralStore,
			meta: commercialReference ? {
				commercialReference : commercialReference
			}: null,
			utmCampaign: utms.utm_campaign || null,
			utmSource: utms.utm_source || null,
			utmMedium: utms.utm_medium || null,
			utmTerm: utms.utm_term || null,
			utmContent: utms.utm_content || null,
		});
		return result;
	} catch (e) {
		throw e;
	}
};

export const signUpAutocompleteCompaniesRegister = async (search) => {
	return api.get(
		'/auth/signup/autocomplete/companies-register',
		{
			params: {
				search: search,
		},
	});
};

export const getUserMe = async () => securedApi.get('/me');

export const isAddressServed = async (postalCode) => {
	const response = await securedApi.get(`/orders/served?postcode=${postalCode}`);
	return response.data;
}

export const addressAutocomplete = async (search) => securedApi.get('/orders/address-autocomplete', { params: { search: search, restriction: 'none' } });
export const orderAddressAutocomplete = async (search) => securedApi.get('/orders/address-autocomplete', { params: { search: search, restriction: 'FR-IDF' } });
export const orderAddressId = async (id) => securedApi.get('/orders/address-id', { params: { id: id } });

export const getOrderPickups = async flowtype => securedApi.get(`/order/pickups?_=${Date.now()}&flowtype=${flowtype}`)

export const getPrice = async orderData => {
	if(orderData.flowtype === 'craneTruck') {
		return securedApi.post('/order/price/crane-truck', orderData);
	}
	return securedApi.post('/order/price', orderData);
}

export const getInstallation = async () => api.get('/installation');

/* @TODO TEMPORAIRE */
export const validateVoucher = async (voucher, userId) => fetch('https://api.voucherify.io/v1/vouchers/' + voucher + '/validate', {
	method: 'POST',
	headers: {
		'X-App-Id': 'ec2d2d42-927b-48b3-857c-207b51a8608c',
		'X-App-Token': 'd4956c5d-3acb-4adb-991b-3db751de43f5',
		'Content-Type': 'application/json',
	},
	body: JSON.stringify({
		customer: {
			source_id: userId,
		},
	}),
}).then(response => response.json(), error => console.log(`Error ${error}`));
/* @TODO TEMPORAIRE */
export const redemptionVoucher = async (voucher, userId) => fetch('https://api.voucherify.io/v1/vouchers/' + voucher + '/redemption', {
	method: 'POST',
	headers: {
		'X-App-Id': 'ec2d2d42-927b-48b3-857c-207b51a8608c',
		'X-App-Token': 'd4956c5d-3acb-4adb-991b-3db751de43f5',
		'Content-Type': 'application/json',
	},
	body: JSON.stringify({
		customer: {
			source_id: userId,
		},
	}),
}).then(response => response.json(), error => console.log(`Error ${error}`));

export const getCards = async () => securedApi.get('/card');

export const getIbans = async () => securedApi.get('/ibans');

export const mapOrder = (orderData, paymentId, paymentMethod, voucherDetails, isAdmin) => {
	let discount = {
		type: null,
		amount: null,
	};
	if(voucherDetails !== undefined
	&& voucherDetails.discount !== undefined) {
		discount = {
			type: voucherDetails.discount.type,
			amount: voucherDetails.discount.percent_off||voucherDetails.discount.amount_off,
		};
	}

	let formatedData = {
		flowtype: orderData.flowtype,

		price: orderData.price,
		discount: discount,
		cardId: (paymentMethod === 'card' ? paymentId : null),
		ibanId: (paymentMethod === 'iban' ? paymentId : null),

		pickupDate: orderData.timeSlot,
		location: orderData.address,
		postalCode: orderData.postalCode,
		floor: (orderData.access === "upstairs" ? orderData.floor : 0),
		ground: (orderData.access === "groundFloor" ? true : false),

		comment: orderData.comment,
		reference: orderData.reference,
		contactNumber: orderData.contact,

		// For orders while "connected as"
		isManual: isAdmin === true,
	}

	switch(orderData.flowtype) {
		case 'truck':
			formatedData = {
				...formatedData,
				rubblesBags: orderData.rubbleBag || 0,
				mixBags: orderData.mixBag || 0,
				plasterBags: orderData.plasterBag || 0,
				ironBags: orderData.ironBag || 0,
				woodBags: orderData.woodBag || 0,
				vegetalBags: orderData.vegetalBag || 0,
				bulkyItems: orderData.bulky || 0,
				mixM3: orderData.mixM3 || 0,
				plasterM3: orderData.plasterM3 || 0,
				woodM3: orderData.woodM3 || 0,
				cartonM3: orderData.cardboardM3 || 0,
				ironM3: orderData.ironM3 || 0,
				vegetalM3: orderData.vegetalM3 || 0,
				slateM3: orderData.slateM3 || 0,
			};
			break;
		case 'bigbag':
			formatedData = {
				...formatedData,
				bigBagNumber: orderData.bigBagNumber || 0,
				bigBagRubbleNumber: orderData.bigBagRubbleNumber || 0,
			};
			break;
		case 'dumpster':
			formatedData = {
				...formatedData,
				dumpster: {
					floor: {
						publicRoadway: orderData.access === "publicRoadway",
						privateProperty: orderData.access === "privateProperty"
					},
					dumpsterType: "chain",
					dumpsterDrop: orderData.dropType,
					wasteType: orderData.wasteType,
					dumpsterSize: orderData.wasteSize,
				},
				previousOrderId: orderData.dropType === "echange" ? orderData.previousOrderId : null,
			};
			break;
		case 'craneTruck':
			formatedData = {
				...formatedData,
				craneTruck: {
					mixed: orderData.mixM3,
					rubble: orderData.rubbleM3,
					dirt: orderData.dirtM3,
				},
			};
			break;
		default:
			break;
	}
	return formatedData;
}

export const createOrder = async (orderData, paymentId, paymentMethod, voucherDetails, isAdmin) => {
	const formatedData = mapOrder(orderData, paymentId, paymentMethod, voucherDetails, isAdmin);
	console.log(`########################################################### formatedData`);
	console.log(formatedData);
	return securedApi.post('/order', formatedData);
}

export const createCard = async (cardData) => securedApi.post('/card', cardData);
export const cardV2Setup = async () => {
	let result = await securedApi.get('/card-v2/setup');
	return result.data;
};
export const cardV2TokenToPm = async (cardId, setupIntentId) => {
	let result = await securedApi.put('/card-v2/token-to-pm', {
		cardId: cardId,
		setupIntentId: setupIntentId,
	});
	return result.data;
};

export const createIban = async (ibanData) => securedApi.post('/iban', ibanData);

export const getOrders = async () => securedApi.get('/orders');

export const patchOrderWithdrawalDumpster = async ({
	orderId,
	date,
	comment,
}) => {
	return securedApi.patch(`/order/${orderId}/withdrawal-dumpster`, {
		date: (new Date(date)).toJSON(),
		comment: comment,
	});
};

export const hooks = {
	async nsOnAction(data) {
		return await fetch('https://hook.integromat.com/6zcy3vdglm53dfiqen78z9addgwridmo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		}).then((res) => res.json());
	},
};
