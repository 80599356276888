import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 14px;
    color: #1B2733;
    padding-bottom: 15px;
    @media (min-width: 1000px) {
        & {
            padding-top: 35px;
        }
    }
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 4px;
    border: ${props => props.userbadsubmit === "true" ? "2px solid #cc0000" : "none"};
`;

const FieldsColumnContainer = styled.div`
    flex: 1;
    &:nth-child(1) {
        margin-right: 4px;
    }
    &:nth-child(2) {
        margin-left: 4px;
    }
`;

const FieldContainer = styled.div`
    border: 1px solid ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#EEEEEE"};
    border-radius: 5px;
    padding: 16px 4px;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
    color: ${props => props.disabled ? "#aaaaaa" : (props => props.checked ? props.mode.feat===true?props.mode.colors.main: "#FF7D12":"inherit")};
    cursor: ${props => props.checked ? "default" : "pointer"};
    background-color: #ffffff;
    &:nth-child(1) {
        margin-right: 8px;
    }
`;

const CustomField = styled(Field)`
    display: none;
`;

const CustomLabel = styled.label`
    cursor: ${props => props.checked ? "default" : "pointer"};
`;

const FloorSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const CustomInputField = styled(Field)`
    align-self: center;
    border: 1px solid #E0E2E4;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    font-family: 'Futura Bold';
    font-size: 20px;
    text-align: center;
    color: ${props => props.value === 0 ? '#808080' : props.mode.feat===true?props.mode.colors.main:'#FF7D12'};
`;

const CustomButton = styled.button`
    background: none;
    border: none;
    outline: none !important;
    height: 50px;
    cursor: pointer;
`;

const PlusButton = styled.img`
    padding-top: 5px;
`;

const CustomError = styled(ErrorMessage)`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 5px;
    font-size: 14px;
    text-align: center;
`;

const AccessSelector = ({
    mode,
    flowtype,
    access,
    floor,
    setFieldValue,
    decrementQuantity,
    incrementQuantity,
    userBadSubmit
}) => {
    return (
        <div>
            <Title>Accès</Title>
            {flowtype === "truck" ? (
                <div>
                    <FieldsContainer userbadsubmit={userBadSubmit.toString()}>
                        <FieldsColumnContainer>
                            <FieldContainer
                                mode={mode}
                                checked={"sidewalk" === access}
                                onClick={() => setFieldValue("access", "sidewalk")}
                            >
                                <CustomField
                                    id="sidewalk"
                                    type="radio"
                                    name="access"
                                    value="sidewalk"
                                    checked={"sidewalk" === access}
                                />
                                <CustomLabel checked={"sidewalk" === access} htmlFor="sidewalk">Trottoir</CustomLabel>
                            </FieldContainer>
                            <FieldContainer
                                mode={mode}
                                checked={"upstairs" === access}
                                onClick={() => setFieldValue("access", "upstairs")}
                            >
                                <CustomField
                                    id="upstairs"
                                    type="radio"
                                    name="access"
                                    value="upstairs"
                                    checked={"upstairs" === access}
                                />
                                <CustomLabel checked={"upstairs" === access} htmlFor="upstairs">À l'étage</CustomLabel>
                            </FieldContainer>
                        </FieldsColumnContainer>
                        <FieldsColumnContainer>
                            <FieldContainer
                                mode={mode}
                                checked={"groundFloor" === access}
                                onClick={() => setFieldValue("access", "groundFloor")}
                            >
                                <CustomField
                                    id="groundFloor"
                                    type="radio"
                                    name="access"
                                    value="groundFloor"
                                    checked={"groundFloor" === access}
                                />
                                <CustomLabel checked={"groundFloor" === access} htmlFor="groundFloor">Cour de l'immeuble</CustomLabel>
                            </FieldContainer>

                            {access === 'upstairs' && (
                                <FloorSelectorContainer>
                                    <CustomButton type="button" onClick={() => setFieldValue("floor", decrementQuantity(floor))}>
                                        <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                                    </CustomButton>
                                    <CustomInputField mode={mode} type="number" min="1" max="10" name="floor" value={floor} />
                                    <CustomButton type="button" onClick={() => setFieldValue("floor", incrementQuantity(floor))}>
                                        <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                                    </CustomButton>
                                </FloorSelectorContainer>
                            )}
                        </FieldsColumnContainer>
                    </FieldsContainer>
                    <CustomError name="floor" component="div" />
                </div>
            ) : flowtype === "dumpster" && (
                <FieldsContainer userbadsubmit={userBadSubmit.toString()}>
                    <FieldContainer mode={mode} checked={"publicRoadway" === access} onClick={() => setFieldValue("access", "publicRoadway")}>
                        <CustomField
                            id="publicRoadway"
                            type="radio"
                            name="access"
                            value="publicRoadway"
                            checked={"publicRoadway" === access}
                        />
                        <CustomLabel checked={"publicRoadway" === access} htmlFor="publicRoadway">Voie publique</CustomLabel>
                    </FieldContainer>
                    <FieldContainer mode={mode} checked={"privateProperty" === access} onClick={() => setFieldValue("access", "privateProperty")}>
                        <CustomField
                            id="privateProperty"
                            type="radio"
                            name="access"
                            value="privateProperty"
                            checked={"privateProperty" === access}
                        />
                        <CustomLabel checked={"privateProperty" === access} htmlFor="privateProperty">Terrain privé</CustomLabel>
                    </FieldContainer>
                </FieldsContainer>
            )}
            <CustomError name="access" component="div" />
        </div>
    )
}

export default AccessSelector;