import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signUp } from '../actions';
import {
	signUpAutocompleteCompaniesRegister,
	addressAutocomplete,
} from '../services/api';
import Spinner from '../components/common/Spinner';
import Autocomplete from '../components/common/Autocomplete';

const Container = styled.div`
    min-height: ${props => props.mode.feat === true &&  props.mode.options.compact === true ? "1px" : "100vh"};
	display: flex;
	justify-content: space-between;
    @media (max-width: 999px) {
		& {
			flex-direction: column;
		}
	}
`;

const ContainerForm = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: start;
	align-items: center;
`;

const HelpContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	max-width: 30%;
    ${props => props.mode.feat===true&&props.mode.colors.homeBg?`
        background-color: ${props.mode.colors.homeBg};
    `:`
		background-image: url("${(require("./../assets/img/onboarding-bg.svg"))}");
    `}
	background-size: cover;
    @media (max-width: 999px) {
		& {
			flex: 1;
			max-width: 100%;
			min-height: 260px;
		}
	}
`;

const HelpTextContainer = styled.div`
	padding: 8px;
	text-align: center;
`;

const HelpTitle = styled.div`
	font-size: 24px;
	font-family: 'Futura Bold';
	color: white;
	line-height: 110%;
	padding-bottom: 42px;
`;

const HelpNumber = styled.div`
	display: block;
	text-decoration: none;
	font-size: 18px;
	color: white;
	padding-bottom: 8px;
`;

const HelpEmail = styled.div`
	display: block;
	text-decoration: none;
	font-size: 18px;
	color: white;
`;

const Logos = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
	align-self: center;

    img {
		align-self: center;
		margin: ${props => props.mode.feat===false?"32px":"16px"} 8px;
		width: 128px;
    }
`;

const CustomForm = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 712px;
	padding: 0px 16px 60px 16px;
	@media (max-width: 999px) {
		& {
			max-width: 600px;
		}
	}
`;

const Title = styled.div`
	font-family: 'Futura Bold';
	font-size: 24px;
	padding-bottom: 45px;
	line-height: 100%;
`;

const Subtitle = styled.div`
	font-family: 'Futura';
	font-size: 14px;
	padding-bottom: 4px;
`;

const FieldsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    @media (min-width: 1000px) {
        & {
			padding-bottom: 16px;
        }
    }
`;

const FieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-right: 16px;
	padding-bottom: 16px;
    @media (max-width: 999px) {
        & {
			padding-right: 0px;
        }
    }
`;
const FieldContainerSub = styled.div`
	margin-bottom: 8px;
	:last-child {
		margin-bottom: 0px;
	}
`;

const CustomField = styled(Field)`
	width: 310px;
	border: 1px solid #EEEEEE;
	border-radius: 5px;
	width: 100%;
	min-width: 300px;
	height: 40px;
	padding-left: 16px;
`;

const LargeField = styled(CustomField)`
	width: 100%;
	max-width: 637px;
`;

const SelectField = styled(Field)`
	width: 100%;
	min-width: 300px;
	height: 40px;
	border: 1px solid #EEEEEE;
	border-radius: 5px;
	background-color: white;
	cursor: pointer;
`;

const CheckboxArea = styled.div`
	margin-top: 35px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
`;
const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0.6;
	padding-bottom: 16px;

	label { font-size: 15px; }
`;

const CheckboxField = styled(Field)`
	display: none;
	margin-bottom: 15px;
`;

const CustomCheckbox = styled.div`
    align-self: center;
    width: 17px;
    height: 17px;
    border-radius: 4px;
    border: 1px solid ${props => props.mode.feat===true ? props.mode.colors.main : '#ff7d12'};
	background-color: ${props => props.checked ? props.mode.feat===true ? props.mode.colors.main : '#ff7d12' : "#FFFFFF"};
	margin-right: 10px;
`;
const CustomRadio = styled(CustomCheckbox)`
    border-radius: 50%;
`;

const Check = styled.img`
	height: 15px;
`;

const CustomLabel = styled.label`
	font-family: 'Futura';
	font-size: 14px;
	padding-bottom: 4px;
`;

const AutocompleteRelative = styled.div`
	position: relative;
`;

const ReferralContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

const ReferralSubContainer = styled.div`
	flex: 1;
	padding-right: 16px;
    @media (max-width: 999px) {
        & {
			padding-right: 0px;
        }
    }
`;

const CustomError = styled(ErrorMessage)`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 5px;
    font-size: 14px;
`;

const SubmitButton = styled.button`
	font-family: 'Futura';
	color: white;
	font-size: 16px;
	line-height: 16px;
	width: 100%;
	max-width: 680px;
	height: 45px;
	background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	cursor: pointer;
	border: none;
	border-radius: 5px;
	margin: 40px 0 20px 0;
`;

const LoginButtonContainer = styled.div`
	text-align: center;
	padding-right: 18px;
`;

const LoginButton = styled(Link)`
	color: #546476;
`;

class SignUpContainer extends React.Component {
    constructor(props) {
		super(props);
		const _this = this;
		
        this.state = {
			...this.state,
            autocompleteCompanyResults: null,
            autocompleteAddressResults: null,
		};
		
		this.autocompleteDelay = null;

		this.signUpSchema = Yup.object().shape({
			firstName: Yup.string()
				.required('Champ obligatoire'),
			lastName: Yup.string()
				.required('Champ obligatoire'),
			email: Yup.string()
				.email('Adresse email non valide')
				.required('Champ obligatoire'),
			phone: Yup.string()
				.matches(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/, 'Ce numéro de téléphone n\'est pas valide')
				.required('Champ obligatoire'),
			clientType: Yup.string(),
			nameCompany: Yup.string()
				.test(
					'clientType-nameCompany',
					'Champ obligatoire',
					function(value) {
						if(this.parent.clientType === 'company'
						&& (value||'').trim() === '') {
							return false;
						}
						return true;
					}
				),
			addressFull: Yup.string()
				.required('Champ obligatoire'),
			referral: Yup.string()
				.required('Champ obligatoire'),
			referralStore: Yup.string()
				.test(
					'referralStore-referral',
					'Champ obligatoire',
					function(value) {
						if((_this.isEnseigne(this.parent.referral)||false) && (value||'') === '') {
							return false;
						}
						return true;
					}
				),
			password: Yup.string()
				.required('Champ obligatoire'),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('password'), "test"], "Les deux mots de passe ne sont pas identiques")
				.required('Champ obligatoire'),
			cgu: Yup.bool()
				.oneOf([true], "Merci d'accepter les Conditions Générales de Vente"),
		});
    }

	isEnseigne = (referral) => {
		const {
			plateformeStores
		} = this.props;

		for (let i = 0; i < Object.keys(plateformeStores).length; i++) {
			if (plateformeStores[Object.keys(plateformeStores)[i]].name === referral) {
				return true;
			}
		}
	}

	getShopsList = (referral) => {
		const {
			plateformeStores
		} = this.props;

		for (let i = 0; i < Object.keys(plateformeStores).length; i++) {
			if (plateformeStores[Object.keys(plateformeStores)[i]].name === referral) {
				return ['', ...plateformeStores[Object.keys(plateformeStores)[i]].shops];
			}
		}
		return [];
	}

	autocompleteCompany(event) {
		let search = (''+event.target.value).trim();
		if(search.length >= 2) {
			this.autocompleteCompanyBreak();
			this.autocompleteDelay = setTimeout(async () => {
				let res = await signUpAutocompleteCompaniesRegister(search);
				if(res.status === 200) {
					this.setState({
						autocompleteCompanyResults: res.data.results,
					});
				}
			}, 500);
		} else {
			this.autocompleteCompanyClose();
		}
	}
	autocompleteCompanyBreak() {
		clearTimeout(this.autocompleteDelay);
	}
	autocompleteCompanyClose() {
		this.autocompleteCompanyBreak();
		this.setState({ autocompleteCompanyResults: null });
	}

	autocompleteAddress(event) {
		let search = (''+event.target.value).trim();
		if(search.length >= 2) {
			this.autocompleteAddressClose();
			this.autocompleteDelay = setTimeout(async () => {
				let res = await addressAutocomplete(search);
				if(res.status === 200) {
					this.setState({
						autocompleteAddressResults: res.data.predictions,
					});
				}
			}, 500);
		} else {
			this.autocompleteAddressClose();
		}
	}
	autocompleteAddressBreak() {
		clearTimeout(this.autocompleteDelay);
	}
	autocompleteAddressClose() {
		this.autocompleteAddressBreak();
		this.setState({ autocompleteAddressResults: null });
	}

	render() {
		const {
			signUpLoading,
			isLoggedIn,
			signUp,
			referralValues,
			mode,
			legal,
		} = this.props;

		if (isLoggedIn) {
			return <Redirect to="/home" />;
		}

		const initialValues = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			confirmPassword: '',
			phone: '',
			clientType: 'company',
			nameCompany: '',
			administrativeId: null,
			addressFull: '',
			cgu: false,
			isSubscribed: false,
			referral: '',
			disableReferal: false,
			referralStore: '',
			commercialReference: '',
		};
		if(mode.options.signupReferral) {
			initialValues.referral = mode.options.signupReferral;
			if(initialValues.referral === 'Rexel') {
				initialValues.disableReferal = true;
			}
		}

		return (
			<Container mode={mode}>
				<ContainerForm>
                    {mode.feat === true ? (
                        <Logos mode={mode}>
                            <img src={mode.logo.main} alt={`Les Ripeurs logo, en partenariat avec ${mode.name}`} />
                        </Logos>
                    ) : (
                        <Logos mode={mode}>
							<img src={(require("./../assets/img/logo-ripeurs-orange.svg"))} alt="logo-ripeurs" />
                        </Logos>
                    )}
					<Formik
						initialValues={initialValues}
						validationSchema={this.signUpSchema}
						onSubmit={(values, actions) => {
							if(this.isEnseigne(values.referral) === true
							&& this.getShopsList(values.referral).includes(values.referralStore) === false) {
								values.referralStore = this.getShopsList(values.referral)[0]||'';
							}
							signUp({ ...values, isSubscribed: !values.isSubscribed });
							actions.setSubmitting(false);
						}}
					>
						{({
							values, isSubmitting, isValid, handleSubmit, setFieldValue
						}) => {
							let clientTypeSwitch = (type) => {
								setFieldValue('clientType', type);
								setFieldValue('nameCompany', '');
								setFieldValue('administrativeId', null);
								setFieldValue('addressFull', '');
								this.autocompleteCompanyClose();
								this.autocompleteAddressClose();
							};

							return (
								<CustomForm onSubmit={handleSubmit}>
									{mode.feat === true ? (
										<Title>Création de votre compte chez notre partenaire</Title>
									) : (
										<Title>Création de votre compte</Title>
									)}

									<FieldsContainer>
										<FieldContainer>
											<CustomLabel htmlFor="firstName">Prénom</CustomLabel>
											<CustomField type="text" name="firstName" />
											<CustomError name="firstName" component="div" />
										</FieldContainer>

										<FieldContainer>
											<CustomLabel htmlFor="lastName">Nom</CustomLabel>
											<CustomField type="text" name="lastName" />
											<CustomError name="lastName" component="div" />
										</FieldContainer>
									</FieldsContainer>

									<FieldsContainer>
										<FieldContainer>
											<CustomLabel htmlFor="email">Adresse e-mail</CustomLabel>
											<CustomField type="email" name="email" />
											<CustomError name="email" component="div" />
										</FieldContainer>

										<FieldContainer>
											<CustomLabel htmlFor="phone">Numéro de téléphone</CustomLabel>
											<CustomField type="string" name="phone" />
											<CustomError name="phone" component="div" />
										</FieldContainer>
									</FieldsContainer>

									<FieldsContainer>
										<RadioContainer>
											<CustomLabel>Je suis :</CustomLabel>
											<CheckboxContainer>
												<CheckboxField
													name="clientType"
													type="radio"
													value="company"
													checked={values.clientType==='company'}
													id='clientTypeCompany'
													onClick={() => clientTypeSwitch('company')}
												/>
												<CustomRadio
													mode={mode}
													checked={values.clientType==='company'}
													onClick={() => clientTypeSwitch('company')}
												>
													<Check src={(require("./../assets/img/check-icon.svg"))} alt="check" />
												</CustomRadio>
												<label htmlFor="clientTypeCompany">Une entreprise</label>
											</CheckboxContainer>
											<CheckboxContainer>
												<CheckboxField
													name="clientType"
													type="radio"
													value="individual"
													checked={values.clientType==='individual'}
													id='clientTypeIndividual'
													onClick={() => clientTypeSwitch('individual')}
												/>
												<CustomRadio 
													mode={mode}
													checked={values.clientType==='individual'}
													onClick={() => clientTypeSwitch('individual')}
												>
													<Check src={(require("./../assets/img/check-icon.svg"))} alt="check" />
												</CustomRadio>
												<label htmlFor="clientTypeIndividual">Un particulier</label>
											</CheckboxContainer>
										</RadioContainer>

										<FieldContainer>
											{values.clientType==='company'?(
												<FieldContainerSub>
													<Subtitle>Nom de l'entreprise</Subtitle>
													<LargeField
														name="nameCompany"
														type="text"
														onChange={(event) => {
															setFieldValue('nameCompany', event.target.value);
															// setFieldValue('administrativeId', null);
															// this.autocompleteCompany(event);
														}}
													/>
													{/* <AutocompleteRelative>
														{this.state.autocompleteCompanyResults!==null?(
															<Autocomplete
																results={this.state.autocompleteCompanyResults}
																resultText={'{{name}} - {{location}}'}
																resultEmptyText={'Aucun résultat'}
																resultEndText={'Je ne trouve pas mon entreprise'}
																resultSelect={(result) => {
																	if(result !== null) {
																		setFieldValue('nameCompany', result.name);
																		setFieldValue('administrativeId', result.administrativeNumber);
																	} else {
																		setFieldValue('administrativeId', null);
																	}
																	this.autocompleteCompanyClose();
																}}
															/>
														):null}
													</AutocompleteRelative> */}
													<CustomError name="nameCompany" component="div" />
												</FieldContainerSub>
											):null}
											<FieldContainerSub>
												<Subtitle>Adresse de facturation</Subtitle>
												<LargeField
													type="text"
													name="addressFull"
													onChange={(event) => {
														setFieldValue('addressFull', event.target.value);
														this.autocompleteAddress(event);
													}}
												/>
												<AutocompleteRelative>
													{this.state.autocompleteAddressResults!==null?(
														<Autocomplete
															mode={mode}
															results={this.state.autocompleteAddressResults}
															resultText={'{{description}}'}
															resultEmptyText={'Aucun résultat'}
															resultSelect={(result) => {
																setFieldValue('addressFull', result.description);
																this.autocompleteAddressClose();
															}}
														/>
													):null}
												</AutocompleteRelative>
												<CustomError name="addressFull" component="div" />
											</FieldContainerSub>
										</FieldContainer>
									</FieldsContainer>

									<FieldsContainer>
										<FieldContainer>
											<CustomLabel htmlFor="password">Choisissez votre mot de passe</CustomLabel>
											<CustomField type="password" name="password" />
											<CustomError name="password" component="div" />
										</FieldContainer>

										<FieldContainer>
											<CustomLabel htmlFor="confirmPassword">Confirmez votre mot de passe</CustomLabel>
											<CustomField type="password" name="confirmPassword" />
											<CustomError name="confirmPassword" component="div" />
										</FieldContainer>
									</FieldsContainer>

									<ReferralContainer>
										<ReferralSubContainer>
											<Subtitle>Par quel moyen nous avez-vous connu ?</Subtitle>
											<SelectField component="select" name="referral" disabled={initialValues.disableReferal}>
												{referralValues.map((referral, index) => 
												<option key={index} value={referral}>
													{referral === "Google" ? "Moteur de recherche (Google, ...)" : referral}
												</option>)}
											</SelectField>
											<CustomError name="referral" component="div" />
										</ReferralSubContainer>

										{this.isEnseigne(values.referral) ? (
											<ReferralSubContainer>
												<Subtitle htmlFor="referralStore">Dans quel magasin ?</Subtitle>
												<SelectField component="select" name="referralStore">
													{this.getShopsList(values.referral).map((shop, index) => (<option key={index} defaultValue>{shop}</option>))}
												</SelectField>
												<CustomError name="referralStore" component="div" />
											</ReferralSubContainer>
										) : values.referralStore = ''}

										{initialValues.referral === 'Rexel' ? (
											<ReferralSubContainer>
												<CustomLabel htmlFor="commercialReference">Immatriculation commercial</CustomLabel>
												<CustomField type="text" name="commercialReference" />
												<CustomError name="commercialReference" component="div" />
											</ReferralSubContainer>
										) : values.commercialReference = ''}
									</ReferralContainer>

									<CheckboxArea>
										<CheckboxContainer>
											<CheckboxField id='cgu' name="cgu" type="checkbox" checked={values.cgu} />
											<CustomCheckbox mode={mode} checked={values.cgu} onClick={() => setFieldValue("cgu", !values.cgu)}>
												<Check src={(require("./../assets/img/check-icon.svg"))} alt="check" />
											</CustomCheckbox>
											<label htmlFor="cgu">
												J'ai lu et j'accepte les
												<a href={legal.termsOfSales.url} target="_blank" rel="noopener noreferrer"> conditions générales de vente</a>
											</label>
										</CheckboxContainer>

										<CheckboxContainer>
											<CheckboxField id='isSubscribed' name="isSubscribed" type="checkbox" checked={values.isSubscribed} />
											<CustomCheckbox mode={mode} checked={values.isSubscribed} onClick={() => setFieldValue("isSubscribed", !values.isSubscribed)}>
												<Check src={(require("./../assets/img/check-icon.svg"))} alt="check" />
											</CustomCheckbox>
											<label htmlFor="isSubscribed">Je ne souhaite pas recevoir de proposition commerciale des partenaires des Ripeurs</label>
										</CheckboxContainer>
									</CheckboxArea>

									{
										signUpLoading ? (
											<Spinner mode={mode}/>
										) : (
												<SubmitButton type="submit" mode={mode}>
													Créer mon compte
												</SubmitButton>
											)
									}
									<LoginButtonContainer>
										<LoginButton to="/login">J'ai déjà un compte</LoginButton>
									</LoginButtonContainer>
								</CustomForm>
							)
						}}
					</Formik>
				</ContainerForm>
				<HelpContainer mode={mode}>
					<HelpTextContainer>
						<HelpTitle>
							Vous avez une question ?
						</HelpTitle>
						<HelpNumber href="tel:+33186767046">
							01.86.76.70.46
						</HelpNumber>
						<HelpEmail href="mailto:contact@lesripeurs.com">
							contact@lesripeurs.com
						</HelpEmail>
					</HelpTextContainer>
				</HelpContainer>

			</Container>

		);
	}
}
const mapStateToProps = state => ({
	signUpLoading: state.actionStateReducer.signUpLoading,
	isLoggedIn: state.userReducer.isLoggedIn,
	referralValues: ['', ...state.initializationReducer.referral],
	plateformeStores: state.initializationReducer.enseignes,
	mode: state.mainReducer.mode,
	legal: state.initializationReducer.legal,
});

const mapDispatchToProps = {
	signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
