import moment from 'moment';

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Content from '../components/common/Content';
import { getOrders, requestAlert, setCurrentFlow, validateFlow } from '../actions';
import { api, patchOrderWithdrawalDumpster } from '../services/api'


const Container = styled(Content)`
	max-width: 700px;
    min-height: ${props => props.mode.feat === true ? '1px' : 'calc(100vh - 92px - 70px)'};
	margin: 0px auto;
	user-select: text;
`;
const BackToHome = styled(Link)`
	display: block;
	margin-bottom: 16px;
	color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;
const Title = styled.div`
	margin-bottom: 32px;
	line-height: 110%;
	font-family: 'Futura Bold';
	font-size: 24px;
`;

const Orders = styled.div`
`;
const OrdersLoader = styled.div`
	text-align: center;
`;
const Order = styled.div`
	display: flex;
	width: 100%;
	padding: 16px 8px;
	text-decoration: none;
	color: #404040;
	font-size: 15px;
	border-bottom: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	cursor: default;

	:last-child {
		border-bottom: 0px none;
	}

    @media (max-width: 999px) {
        & {
            flex-direction: column;
        }
    }
`;

const OrderLeft = styled.div`
	display: flex;
	min-width: 140px;
	margin-right: 16px;
	text-align: center;

    @media (max-width: 999px) {
        & {
			margin-right: 0px;
			margin-bottom: 8px;
			text-align: left;
        }
    }
`;
const OrderIcon = styled.div`
	width: 40px;
	height: 40px;
	margin-right: 8px;
	vertical-align: middle;
	border-radius: 50%;
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;
const OrderIconImg = styled.div`
	width: 36px;
	height: 36px;
	margin: 2px;
    background-color: #ffffff;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: contain;
`;
const OrderCreatedAt = styled.div`
	margin-bottom: 4px;
	color: #666666;
	font-size: 13px;
`;
const OrderType = styled.div`
	margin-bottom: 2px;
`;

const OrderRight = styled.div`
	flex: 1;
`;
const OrderInfoMain = styled.div`
	margin-bottom: 4px;
`;
const OrderInfoSecondary = styled.div`
	padding-top: 4px;
	border-top: 1px solid #dddddd;
	margin-bottom: 4px;
`;
const OrderInfoActions = styled.div`
	padding-top: 4px;
	border-top: 1px solid #dddddd;
`;

const OrderDumpsterWithdrawal = styled.div`
	display: inline-block;
	margin-top: 4px;
	padding: 6px 10px;
	border-radius: 5px;
	border: 1px solid #33b704;
`;
const OrderDumpsterWithdrawalForm = styled.div`
	margin-top: 8px;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;
	background-color: #eeeeee;

	input,
	textarea {
		margin: 4px 0px 8px 0px;
		padding: 4px;
	}
`;

const Button = styled.div`
	display: inline-block;
	margin-top: 4px;
	padding: 6px 10px;
	text-align: center;
	color: #ffffff;
	border-radius: 5px;
	background-color: ${props => props.disabled?'#999999':(props.mode.feat===true?props.mode.colors.main:'#ff7d12')};
	cursor: ${props => props.disabled?'default':'pointer'};
`;
const Bold = styled.span`
	font-weight: bold;
`;
const Underline = styled.span`
	text-decoration: underline;
`;

const TextIcon = styled.span`
	vertical-align: middle;
	padding-bottom: 4px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FlexChild1 = styled.div`
	flex: 1;
`;

const FlexChild2 = styled.div`
	flex: 3;
`;

const ActionBtn = styled(Button)`
	margin: 2px 5px;
	margin-bottom: 0px;
`;

const TextOrderId = styled.span`
	white-space: nowrap;
`;


class OrdersContainer extends React.Component {
    constructor(props) {
		super(props);

        this.state = {
			dumpsterWithdrawal: {
				orderId: null,
				date: '',
				comment: '',
				loading: false,
			},
			serverDate: moment(),
		};

		this.intervalServerDate = null;

		this.imgs = {
			truck: require('./../assets/img/truck-icon.svg'),
			bigbag: require('./../assets/img/bigbag-icon.svg'),
			dumpster: require('./../assets/img/dumpster-icon.svg'),
		};

		this.translate = {
			flowtype: {
				'truck': 'Utilitaire',
				'bigbag': 'BigBag',
				'dumpster': 'Benne',
			},
			status: {
				'REQUESTED': 'En cours de traitement',
				'CONFIRMED': 'Confirmé',
				'INVOICED': 'Facturé',
				'CANCELED': 'Annulé',
			},
			dumpsterDrop: {
				'depot': 'Dépôt de benne',
				'echange': 'Échange de benne',
				'passante': 'Benne passante',
			},
			dumpsterWasteType: {
				'rubble': 'Gravats',
				'mixed': 'Mélangés',
				'wood': 'Bois',
				'furniture': 'Mobilier',
				'vegetal': 'Végétaux',
				'iron': 'Ferraille',
			},
		};
    }
	
    componentDidMount() {
		this.props.getOrders();
		this.updateServerDate();
		this.intervalServerDate = setInterval(() => this.updateServerDate(), 12 * 3600 * 1000);
    }

	componentWillUnmount() {
		clearInterval(this.intervalServerDate);
	}
	
	updateServerDate() {
		api.get('/api/status?withdrawal=true').then(res => {
			this.setState({ serverDate: moment(res.data.server.date) });
		});
	}

	async dumpsterWithdrawalSend() {
		if(this.state.dumpsterWithdrawal.loading === true) {
			return;
		}

		let dateLimit = {
			min: moment().add(12, 'hour').startOf('day').add(1, 'day').toDate(), // Bloquer journée demain à partir de 12h00
			max: moment().startOf('day').add(15, 'day').toDate(),
		};
		let date = moment(this.state.dumpsterWithdrawal.date).startOf('day').toDate();

		if(isNaN(date) === true) {
			alert('Choisissez une date de retrait, avant de valider.');
			return;
		} else if(date < dateLimit.min) {
			alert(`Le retrait définitif est disponible que pour le lendemain, au minimum.`);
			return;
		} else if(date > dateLimit.max) {
			console.log(date, dateLimit.max);
			alert(`Le retrait définitif est disponible que sur 15 jours maximum.`);
			return;
		}

		this.setState({
			dumpsterWithdrawal: {
				...this.state.dumpsterWithdrawal,
				loading: true,
			},
		});

		let result = await patchOrderWithdrawalDumpster({
			orderId: this.state.dumpsterWithdrawal.orderId,
			date: this.state.dumpsterWithdrawal.date,
			comment: this.state.dumpsterWithdrawal.comment,
		});

		setTimeout(() =>{
			if(result.data.code === 'PATCHED') {
				this.setState({
					dumpsterWithdrawal: {
						...this.state.dumpsterWithdrawal,
						date: '',
						comment: '',
						loading: false,
					}
				});
				this.props.getOrders();
				this.props.requestAlert(
					'SUCCESS',
					`Demande de retrait confirmée, vous allez recevoir un SMS vous indiquant le créneau effectif du retrait de votre benne très prochainement.`,
				);
			} else {
				this.setState({
					dumpsterWithdrawal: {
						...this.state.dumpsterWithdrawal,
						loading: false,
					}
				});
				this.props.requestAlert(
					'ERROR',
					`Une erreur s'est produite, merci de réessayer dans quelques instants. Si le problème persiste, contactez le support téléphonique.`,
				);
			}
		}, 300);
	}

	exchangeDumpster(order) {
		const postalCode = order.location.match(/([0-9]{5})/);
		if(this.props.mode.key === "native") {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				type: 'DUMPSTER_EXCHANGE',
				data: {
					dropType: 'echange',
					wasteType: order.dumpster.wasteType,
					wasteSize: order.dumpster.dumpsterSize,
					address: postalCode ? order.location : '',
					postalCode: postalCode ? postalCode[1] : '',
					floor: order.dumpster.floor,
					contact: order.contactNumber,
					comment: order.comment,
					reference: order.reference,
					previousOrderId: order.id,
				}
			}));
		} else {
			this.setState({
				dumpsterExchange: {
					...this.state.dumpsterExchange,
					orderId: order.id,
				},
			});

			this.props.setCurrentFlow("dumpster");
			this.props.validateFlow({
				dropType: 'echange',
				wasteType: order.dumpster.wasteType,
				wasteSize: `${order.dumpster.dumpsterSize}`,
				address: postalCode ? order.location: '',
				postalCode: postalCode ? postalCode[1] : '',
				access: order.dumpster.floor.privateProperty ? 'privateProperty' : 'publicRoadway',
				contact: order.contactNumber,
				comment: order.comment,
				reference: order.reference,
				previousOrderId: order.id,
			});

			this.props.history.push('/dumpster');
		}
		
	}

    render() {
		const {
			orders,
			mode,
		} = this.props;

        return (
            <Container mode={mode}>
				{mode.key!=='native'?(
					<BackToHome to='/home' mode={mode}>
						Retour à l'accueil
					</BackToHome>
				):null}
				<Title>
					Mes commandes bennes
				</Title>
				<Orders mode={mode}>
					{orders===null||orders===undefined?(
						<OrdersLoader>
							Chargement...
						</OrdersLoader>
					):(
						orders.length>0?orders.map((order) => (
							<Order key={order.id} mode={mode}>
								<OrderLeft>
									<OrderIcon mode={mode}>
										<OrderIconImg icon={this.imgs[order.flowtype]} />
									</OrderIcon>
									<div>
										<OrderCreatedAt>
											{moment(order.createdAt).format('DD/MM/YYYY')}
										</OrderCreatedAt>
										<OrderType>
											{this.translate.flowtype[order.flowtype]}
										</OrderType>
										{order.id}
									</div>
								</OrderLeft>
								<OrderRight>
									<OrderInfoMain>
										<div>
											<Bold>Statut:</Bold> {this.translate.status[order.status]||'Inconnu'}
										</div>
										{order.reference?(
											<div>
												<Bold>Référence chantier:</Bold> {order.reference}
											</div>
										):null}
										<Bold>Adresse:</Bold> {order.location.replace(', France', '')}
									</OrderInfoMain>
									{order.flowtype==='dumpster'?(
										<>
											<OrderInfoSecondary>
												<div>
													<Bold>Type:</Bold> {this.translate.dumpsterDrop[order.dumpster.dumpsterDrop]}
												</div>
												<div>
													<Bold>Déchet:</Bold> {this.translate.dumpsterWasteType[order.dumpster.wasteType]}
												</div>
												<div>
													<Bold>Taille:</Bold> {order.dumpster.dumpsterSize}m3
												</div>
											</OrderInfoSecondary>
											<OrderInfoActions>
												<div>
													{order.dumpster.exchangedWith ? 
													<OrderDumpsterWithdrawal>
														Échange demandé, numéro de commande : <TextOrderId>{order.dumpster.exchangedWith.orderId}</TextOrderId>
													</OrderDumpsterWithdrawal> 
													: order.dumpster.withdrawal ? 
													<OrderDumpsterWithdrawal>
														Retrait définitif demandé à partir du {moment(order.dumpster.withdrawal.date).format('DD/MM/YYYY')}
													</OrderDumpsterWithdrawal> : 
													<FlexContainer>
														<FlexChild1>
															<Bold>Action:&nbsp;</Bold>
														</FlexChild1>
														<FlexChild2>
															<ActionBtn
																onClick={() => {
																	if(order.status==='CONFIRMED') {
																		this.exchangeDumpster(order);
																	}
																}}
																disabled={order.status!=='CONFIRMED'}
																mode={mode}
															>
																<i style={{fontSize:18, verticalAlign: "middle"}} className="material-icons">repeat</i>
																<TextIcon>&nbsp;Échange de bennes</TextIcon>
															</ActionBtn>
															{this.state.dumpsterWithdrawal.orderId!==order.id?(
																(
																	(
																		order.dumpster.dumpsterDrop === 'depot'
																		|| order.dumpster.dumpsterDrop === 'echange'
																	) && (
																		order.status === 'REQUESTED'
																		|| order.status === 'CONFIRMED'
																	)
																)?(
																	<ActionBtn
																		onClick={() => {
																			if(order.status==='CONFIRMED') {
																				this.setState({
																					dumpsterWithdrawal: {
																						...this.state.dumpsterWithdrawal,
																						orderId: order.id,
																					},
																				});
																			}
																		}}
																		disabled={order.status!=='CONFIRMED'}
																		mode={mode}
																	>
																		<i style={{fontSize:18, verticalAlign: "middle"}} className="material-icons">reply</i>
																		<TextIcon>&nbsp;Retrait définitif</TextIcon>
																	</ActionBtn>
																):null
															):(
																<OrderDumpsterWithdrawalForm>
																	Date à <Underline>partir</Underline> de laquelle nous pouvons effectuer le <Underline>retrait définitif</Underline> :
																	<br />
																	<input
																		type='date'
																		onChange={(event) => {
																			let date = new Date(event.target.value);
																			if(date.getDay() === 6 || date.getDay() === 0) {
																				alert(`Seuls les créneaux en semaine sont disponibles, merci.`);
																				return;
																			}
																			if(date.toJSON().substr(0, 10) === '2021-06-11'
																			|| date.toJSON().substr(0, 10) === '2021-07-14'
																			|| date.toJSON().substr(0, 10) === '2021-09-17'
																			|| date.toJSON().substr(0, 10) === '2021-09-20') {
																				alert(`Le créneau n'est pas disponible, merci d'en sélectionner un autre.`);
																				return;
																			}
																			this.setState({
																				dumpsterWithdrawal: {
																					...this.state.dumpsterWithdrawal,
																					date: event.target.value,
																				},
																			});
																		}}
																		value={this.state.dumpsterWithdrawal.date}
																		min={this.state.serverDate.clone().startOf('day').add(2, 'day').format('YYYY-MM-DD')}
																		max={this.state.serverDate.clone().add(15, 'day').format('YYYY-MM-DD')}
																		disabled={this.state.dumpsterWithdrawal.loading===true}
																	/>
																	<br />
																	Contraintes chantier, information complémentaire :
																	<br />
																	<textarea
																		onChange={(event) => this.setState({
																			dumpsterWithdrawal: {
																				...this.state.dumpsterWithdrawal,
																				comment: event.target.value,
																			},
																		})}
																		value={this.state.dumpsterWithdrawal.comment}
																		style={{ width: '100%' }}
																		rows='2'
																		maxLength='1000'
																		disabled={this.state.dumpsterWithdrawal.loading===true}
																		placeholder='Exemple: "personne sur le chantier après 15h"'
																	/>
																	<br />
																	<Button
																		onClick={() => this.dumpsterWithdrawalSend()}
																		disabled={this.state.dumpsterWithdrawal.loading===true}
																		mode={mode}
																	>
																		{
																			this.state.dumpsterWithdrawal.loading===false
																			?'Valider le retrait définitif'
																			:'Chargement...'
																		}
																	</Button>
																</OrderDumpsterWithdrawalForm>
															)}
														</FlexChild2>
													</FlexContainer>
													}
												</div>
											</OrderInfoActions>
										</>
									):null}
								</OrderRight>
							</Order>
						)):(
							<span>Aucune commande</span>
						)
					)}
				</Orders>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
		orders: state.orderReducer.orders,
		mode: state.mainReducer.mode,
		dumpsterOrderDetails: state.orderReducer.dumpsterOrderDetails,
	};
};

const mapDispatchToProps = {
    getOrders,
	requestAlert,
	setCurrentFlow,
	validateFlow,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);