import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import lsp from '../../services/localStoragePower';
import { loadUtms } from '../../@helpers/utms';


const Header = styled.div`
    padding: 16px;
    text-align: center;
    color: ${props => props.colorText||'#000000'};
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #ff7d12;
    background-color: ${props => props.colorBackground||'#ff7d12'};
`;
const HeaderText = styled.div`
    margin: 0px auto;
    max-width: 1100px;
    cursor: pointer;
`;

const Popup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
`;
const PopupBlock = styled.div`
    position: relative;
    max-width: 600px;
    margin: 16px;
    padding: 16px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.14), 0px 6px 6px rgba(0,0,0,0.19);
`;
const PopupClose = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 16px;
    font-size: 26px;
    cursor: pointer;
`;
const PopupTitle = styled.div`
    margin-right: 32px;
    font-size: 19px;
`;
const PopupContent = styled.div`
    margin: 12px 0px;
    text-align: center;
`;
const PopupContentScroll = styled.div`
    padding: 4px 0px;
    max-height: 300px;
    overflow: auto;
`;
const PopupButtonContainer = styled.div`
    margin-top: 8px;
    text-align: center;
`;
const PopupButtonBlock = styled.a`
    display: inline-block;
    padding: 8px 16px;
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background-color: #ff7d12;
`;


const PopupTitle2 = styled.div`
    text-align: center;
    font-size: 28px;
    margin-bottom: 32px;
    margin-right: 32px;
    line-height: 28px;
`;
const PopupBlock2 = styled.div`
    position: relative;
    max-width: 800px;
    max-height: 90vh;
    margin: 16px;
    padding: 16px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.14), 0px 6px 6px rgba(0,0,0,0.19);
    overflow: auto;
`;
const PopupButtonContainer2 = styled.div`
    margin-top: 24px;
    text-align: center;
`;
const PopupButtonBlock2 = styled.a`
    display: inline-block;
    padding: 16px 24px;
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background-color: #ff7d12;
    margin: 8px;
    cursor: pointer;
`;
const PopupButtonBlockBack = styled.a`
    display: inline-block;
    padding: 12px 16px;
    text-decoration: none;
    color: #ff7d12;
    font-size: 18px;
    border: 1px solid #ff7d12;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 8px;
    cursor: pointer;
`;
const Header2 = styled.div`
    padding: 16px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #ff7d12;
    background-color: #ff7d12;
`;
const Screen = styled.img`
    width: 100%;
    min-width: 200px;
    max-width: 320px;
    margin: 4px;
    border: 1px solid #dddddd;
    border-radius: 4px;
`;

class HeaderMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messagePopupEnabled: null,
            showNewAppPopup: false,
            newAppUrl: 'https://lesripeurs.app/',
        };

        if(this.props.mode.key === null
        && this.props.mode.feat === false
        && window.location.href.indexOf('_ga=') === -1) {
            lsp.getItem('app-v4_last-close').then((lastClose) => {
                lastClose = lastClose||0;
                if(lastClose < Date.now()-(12/*h*/*3600*1000)) {
                    this.showNewAppPopup(true);
                }
            });
        }
        loadUtms().then((utms) => {
            let url = `https://lesripeurs.app/?${window.location.search.substr(1)}&_token=${this.props.token}`;
            if(url.indexOf('utm_source=') === -1) {
                for(let key in utms) {
                    url += `&${key}=${encodeURIComponent(utms[key])}`;
                }
            }
            this.setState({ newAppUrl: url });
        });

        if(window.location.href.indexOf('_force=') === -1 && 
            this.props.mode.key === null && 
            this.props.mode.feat === false) {
            const route = window.location.href.includes('signup') ? 'register' : '';
            loadUtms().then((utms) => {
                let url = `https://lesripeurs.app/${route}?${window.location.search.substr(1)}&_token=${this.props.token}`;
                if(url.indexOf('utm_source=') === -1) {
                    for(let key in utms) {
                        url += `&${key}=${encodeURIComponent(utms[key])}`;
                    }
                }
                window.location.href = url;
            });
        }
    }

    messagePopupEnabled(enabled) {
        this.setState({
            messagePopupEnabled: enabled,
        });
    }

    showNewAppPopup(enabled) {
        this.setState({
            showNewAppPopup: enabled,
        });
    }

    componentDidUpdate() {
        if(this.props.mode.feat === false
        && this.props.mode.key === null
        && this.props.message !== undefined
        && this.state.messagePopupEnabled === null) {
            this.setState({
                messagePopupEnabled: (
                    this.props.message!==null&&this.props.message.openAuto!==undefined
                    ?this.props.message.openAuto
                    :false
                ),
            });
            return null;
        }
    }

    render() {
		const {
            message,
            mode,
        } = this.props;

        return (
            <div>
                {(
                    mode.options.uiMessage !== false
                    && message !== undefined
                    && message !== null
                    && this.state.messagePopupEnabled === true
                )?(
                    <Popup>
                        <PopupBlock>
                            <PopupClose onClick={() => { this.messagePopupEnabled(false); }}>
                                ✗
                            </PopupClose>
                            <PopupTitle>
                                {message.title||''}
                            </PopupTitle>
                            <PopupContent>
                                <PopupContentScroll>
                                    {(message.text||'').split('\n').map((line, i) => (
                                        <span key={i}>
                                            {line}
                                            <br/>
                                        </span>
                                    ))}
                                </PopupContentScroll>
                            </PopupContent>
                            {(message.buttons||[]).map((button, i) => (
                                <PopupButtonContainer key={i}>
                                    <PopupButtonBlock href={button.link} target="_blank">
                                        {button.title}
                                    </PopupButtonBlock>
                                </PopupButtonContainer>
                            ))}
                        </PopupBlock>
                    </Popup>
                ):null}
                {(
                    mode.feat === false
                    && mode.key === null
                    && message !== undefined
                    && message !== null
                )?(
                    <Header
                        colorText={message.colorText}
                        colorBackground={message.colorBackground}
                    >
                        <HeaderText onClick={() => { this.messagePopupEnabled(true); }}>
                            {message.title}
                        </HeaderText>
                    </Header>
                ):null}

                {this.state.showNewAppPopup &&
                <Popup>
                    <PopupBlock2>
                        <PopupClose
                            onClick={() => {
                                this.showNewAppPopup(false);
                                lsp.setItem('app-v4_last-close', Date.now());
                                (window.dataLayer||[]).push({ 'event': 'rip_app_v4_open_popup' });
                            }}
                        >
                            ✗
                        </PopupClose>
                        <PopupTitle2>
                            Notre nouvelle application est disponible !
                        </PopupTitle2>
                        <PopupContent>
                            <Screen src={(require("./../../assets/img/app-v4-a.jpg"))} alt="new-app-a" />
                            <Screen src={(require("./../../assets/img/app-v4-b.jpg"))} alt="new-app-b" />
                            <Screen src={(require("./../../assets/img/app-v4-c.jpg"))} alt="new-app-c" />
                            <Screen src={(require("./../../assets/img/app-v4-d.jpg"))} alt="new-app-d" />
                        <PopupContentScroll>
                            </PopupContentScroll>
                        </PopupContent>
                            <PopupButtonContainer2>
                                <PopupButtonBlockBack
                                    onClick={() => {
                                        this.showNewAppPopup(false);
                                        lsp.setItem('app-v4_last-close', Date.now());
                                        (window.dataLayer||[]).push({ 'event': 'rip_app_v4_go_back' });
                                    }}
                                >
                                    Restez sur l'ancienne version
                                </PopupButtonBlockBack>
                                <PopupButtonBlock2
                                    onClick={() => { (window.dataLayer||[]).push({ 'event': 'rip_app_v4_go_new' }); }}
                                    href={this.state.newAppUrl}
                                    target="_blank"
                                >
                                    Montrez-moi la nouvelle !
                                </PopupButtonBlock2>
                            </PopupButtonContainer2>
                    </PopupBlock2>
                </Popup>
                }
                {this.props.mode.key === null && this.props.mode.feat === false &&
                <Header2>
                    <HeaderText onClick={() => {
                        this.showNewAppPopup(true);
                        (window.dataLayer||[]).push({ 'event': 'rip_app_v4_open_popup' });
                    }}>
                        Notre nouvelle application est disponible ! Cliquez-ici
                    </HeaderText>
                </Header2>
                }
            </div>
        );
    }
};

const mapDispatchToProps = {};
const mapStateToProps = state => ({
	message: state.initializationReducer.message,
    mode: state.mainReducer.mode,
    token: state.userReducer.token,

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMessage);