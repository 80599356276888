import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { hooks } from '../services/api';
import Content from '../components/common/Content';


const Container = styled(Content)`
	max-width: 700px;
    min-height: ${props => props.mode.feat === true ? '1px' : 'calc(100vh - 92px - 70px)'};
	margin: 0px auto;
	user-select: text;
`;
const BackToHome = styled(Link)`
	display: block;
	margin-bottom: 16px;
	color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;
const Title = styled.div`
	margin-bottom: 32px;
	line-height: 110%;
	font-family: 'Futura Bold';
	font-size: 24px;
`;

const Types = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;
const Type = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 8px;
	width: 140px;
	height: 140px;
	border: 4px solid #ff7d12;
	border-radius: 50%;
`;
const Icon = styled.div`
	width: 80px;
	height: 56px;
	margin-bottom: 4px;
    background-image: url('${props => props.icon}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

const Name = styled.div`
	height: 34px;
	max-width: 100px;
	text-align: center
`;
const Text = styled.div`
	margin-top: 32px;
	text-align: center;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 32px;
`;
const Action = styled.a`
	display: inline-block;
	width: 300px;
	margin: 0px auto 8px auto;
	padding: 16px 16px;
	text-align: center;
	text-decoration: none;
	color: #ffffff;
	font-size: 18px;
	border-radius: 5px;
	background-color: #ff7d12;
	> img {
		max-width: 20px;
		max-height: 20px;
		margin-right: 8px;
		vertical-align: top;
	}
	> span {
		display: block;
		margin-top: 2px;
		font-style: italic;
		font-size: 14px;
	}
`;


class NewServicesContainer extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			type: 'dangerous',
		};

		this.imgs = {
			phone: require('./../assets/img/phone.svg'),
			mail: require('./../assets/img/mail.svg'),

			gearRental: require('./../assets/img/gear-rental.png'),
			craneTruck: require('./../assets/img/truck-crane.png'),
			asbestos: require('./../assets/img/asbestos.png'),
			dangerousWaste: require('./../assets/img/dangerous-waste.png'),
			dumpsterPW: require('./../assets/img/dumpster-pw.png'),
		};
		this.actionClick = false;
    }

	componentDidMount() {
		if(window.location.hash === '#construction-equipment') {
			this.setState({
				type: 'construction-equipment',
			});
		}
	}

	onAction(action) {
		if(this.actionClick === true) {
			return;
		}
		this.actionClick = true;
		let data = {
			action: action,
			type: this.state.type,
			user: this.props.user||null,
		};
		hooks.nsOnAction(data);
	}

    render() {
		const { mode } = this.props;
		const { type } = this.state;

        return (
            <Container mode={mode}>
				{mode.key!=='native'?(
					<BackToHome to='/home' mode={mode}>
						Retour à l'accueil
					</BackToHome>
				):null}
				{type==='dangerous'?(
					<div>
						<Title>
							Déchets Dangereux
						</Title>
						<Types>
							<Type>
								<Icon icon={this.imgs.asbestos} />
								<Name>Amiantes</Name>
							</Type>
							<Type>
								<Icon icon={this.imgs.dangerousWaste} />
								<Name>Déchets dangereux</Name>
							</Type>
						</Types>
						<Text>
							Evacuer tous vos déchets dangereux en 72h (amiante, plomb, peintures ...).
						</Text>
						<Actions>
							<Action href="tel:+33186767046" onClick={() => this.onAction('phone')}>
								<img src={this.imgs.phone} />
								Nous appeler
								<span>01.86.76.70.46</span>
							</Action>
							<Action href="mailto:dd@lesripeurs.com" onClick={() => this.onAction('mail')}>
								<img src={this.imgs.mail} />
								Nous contacter par mail
								<span>dd@lesripeurs.com (réponse en 2h)</span>
							</Action>
						</Actions>
					</div>
				):(
					<div>
						<Title>
							Matériels du BTP
						</Title>
						<Types>
							<Type>
								<Icon icon={this.imgs.dumpsterPW} />
								<Name>Bennes TP</Name>
							</Type>
							<Type>
								<Icon icon={this.imgs.gearRental} />
								<Name>Location d'engins</Name>
							</Type>
						</Types>
						<Text>
							Camions et locations d'engins de chantier mis à votre disposition avec ou sans chauffeur.
						</Text>
						<Actions>
							<Action href="tel:+33186767046" onClick={() => this.onAction('phone')}>
								<img src={this.imgs.phone} />
								Nous appeler
								<span>01.86.76.70.46</span>
							</Action>
							<Action href="mailto:btp@lesripeurs.com" onClick={() => this.onAction('mail')}>
								<img src={this.imgs.mail} />
								Nous contacter par mail
								<span>btp@lesripeurs.com (réponse en 2h)</span>
							</Action>
						</Actions>
					</div>
				)}
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
		mode: state.mainReducer.mode,
		user: state.userReducer.user,
	};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NewServicesContainer);