import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Spinner from '../common/Spinner';

const Container = styled.div`
    padding-bottom: 50px;
`;

const VoucherButton = styled.button`
    display: flex;
    flex-direction: row;
    font-family: "Futura";
    font-size: 18px;
    color: #8c8d8c;
    background: none;
    border: none;
    cursor: pointer;
`;

const VoucherImg = styled.img`
    margin-top: 4px;
    height: 18px;
    padding-left: 10px;
`;
const VoucherLabel = styled.div`
`;

const Title = styled.div`
    font-size: 18px;
    color: #8c8d8c;
    margin-bottom: 15px;
    padding: 4px 6px 0px 6px;
`;

const CustomField = styled(Field)`
    width: 310px;
    height: 40px;
    font-size: 15px;
    padding: 0 15px;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
`;

const Button = styled.button`
    font-family: "Futura";
    font-size: 15px;
    height: 40px;
    color: white;
    background-color: ${props => props.disabled ? "#999999" :  props.mode.feat===true?props.mode.colors.main : "#ff7d12"};
    border-radius: 5px;
    margin-left: 10px;
    padding: 0px 12px;
    cursor: ${props => props.disabled ? "default" : "pointer"};
`;

const Valid = styled.div`
    font-family: "Futura Italic";   
	margin-top: 10px;
    font-size: 14px;
    color: #00b116;
`;

const Error = styled.div`
    font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 10px;
    font-size: 14px;
`;

const voucherSchema = Yup.object().shape({
    voucher:
        Yup.string()
})

const Voucher = ({
    handleVoucherValidation,
    voucherDetails,
    voucherLoading,
    hasAppliedVoucher,
    isDisplayed,
    displayVoucher,
    mode,
}) => {
    return (
        <Container>
            {isDisplayed ? (
                <Formik
                    initialValues={{
                        voucher: ''
                    }}
                    validationSchema={voucherSchema}
                    onSubmit={(values, actions) => {
                        handleVoucherValidation(values.voucher);
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                        isValid, isSubmitting, handleSubmit
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Title>Code promo</Title>

                                {voucherLoading ? (
                                    <Spinner mode={mode}/>
                                ) : (
                                        <div>
                                            <CustomField
                                                type="input"
                                                name="voucher"
                                                disabled={hasAppliedVoucher}
                                            />
                                            <Button mode={mode} type="submit" disabled={!isValid || isSubmitting || hasAppliedVoucher}>
                                                Valider
                                            </Button>
                                        </div>
                                    )}
                                {voucherDetails && (
                                    <div>
                                        {voucherDetails.valid ? (
                                            <Valid>Code promo valide !</Valid>
                                        ) : (
                                                <Error>{voucherDetails}</Error>
                                            )}
                                    </div>
                                )}
                            </form>
                        )
                    }}
                </Formik>
            ) : (
                    <VoucherButton onClick={displayVoucher}>
                        <VoucherLabel>Code promo</VoucherLabel>
                        <VoucherImg src={(require("./../../assets/img/plus.svg"))} alt="plus" />
                    </VoucherButton>
                )}
        </Container>
    )
}

export default Voucher;