import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentFlow } from '../actions';
import styled from 'styled-components'

const Container = styled.div`
    border-bottom: 1px solid #AAAAAA;
    padding: 10px 0px 24px 0px;
`;

const ModeMessage = styled.div`
    margin: -8px 0px 16px 0px;
    text-align: center;
    span {
        display: inline-block;
        padding: 6px 12px;
        border-radius: 5px;
        color: white;
        text-decoration: underline;
        background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    }
`;

const CustomLinks = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
    max-width: 650px;
    margin: 0px auto;
`;

const CustomLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    flex: 1;
    color: ${props => props.active === "true" ? "#FF7D12" : "#AAAAAA"};
    text-decoration: none;
    @media (max-width: 999px) {
        & img {
            width: 70%;
            max-width: 100px;
        }
        & div {
            height: 40px;
        }
    }
`;

const Title = styled.div`
    height: 20px;
    padding-top: 10px;
`;

const BackLink = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
`;
const BackLinkCustom = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    text-decoration: none;
    background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    border-radius: 5px;
    height: 40px;
    width: 130px;
    align-self: flex-end;
`;

class FlowButtonsContainer extends React.Component {

    setFlow = flow => () => {
        this.props.setCurrentFlow(flow);
    };

    render() {
        const {
            truckOrderDetails,
            bigbagOrderDetails,
            dumpsterOrderDetails,
            craneTruckOrderDetails,
            flowtype,
            showBackLink,
            mode,
            activeCraneTrucks,
        } = this.props;
        return (
            <Container>
                {mode.feat === true && (
                    <ModeMessage mode={mode}>
                        <span>Devis proposé par notre partenaire Les Ripeurs</span>
                    </ModeMessage>
                )}
                <CustomLinks>
                    <CustomLink
                        to={truckOrderDetails.price ? '/summary' : '/truck'}
                        active={flowtype === "truck" ? "true" : "false"}
                        onClick={this.setFlow('truck')}
                    >
                        <img src={(require(`./../assets/img/truck-${flowtype === "truck" ? "active" : "inactive"}.svg`))} alt="" />
                        <Title>Sacs à gravats et vrac</Title>
                    </CustomLink>

                    <CustomLink
                        to={bigbagOrderDetails.price ? "/summary" : "/bigbag"}
                        active={flowtype === "bigbag" ? "true" : "false"}
                        onClick={this.setFlow('bigbag')}
                    >
                        <img src={(require(`./../assets/img/bigbag-${flowtype === "bigbag" ? "active" : "inactive"}.svg`))} alt="" />
                        <Title>Big bags</Title>
                    </CustomLink>

                    <CustomLink
                        to={dumpsterOrderDetails.price ? "/summary" : "/dumpster"}
                        active={flowtype === "dumpster" ? "true" : "false"}
                        onClick={this.setFlow('dumpster')}
                    >
                        <img src={(require(`./../assets/img/dumpster-${flowtype === "dumpster" ? "active" : "inactive"}.svg`))} alt="" />
                        <Title>Bennes</Title>
                    </CustomLink>
                    
                    {Object.entries(activeCraneTrucks).some((item) => item[1].active === true) && (
                        <CustomLink
                            to={craneTruckOrderDetails && craneTruckOrderDetails.price ? "/summary" : "/crane-truck"}
                            active={flowtype === "craneTruck" ? "true" : "false"}
                            onClick={this.setFlow('craneTruck')}
                        >
                            <img src={(require(`./../assets/img/cranetruck-${flowtype === "craneTruck" ? "active" : "inactive"}.svg`))} alt="" />
                            <Title>Camion-grues</Title>
                        </CustomLink>
                    )}
                </CustomLinks>
                {showBackLink === true && (
                    <BackLink>
                        {flowtype === 'truck' && (
                            <BackLinkCustom to="/truck" mode={mode}>Retour</BackLinkCustom>
                        )}
                        {flowtype === 'bigbag' && (
                            <BackLinkCustom to="/bigbag" mode={mode}>Retour</BackLinkCustom>
                        )}
                        {flowtype === 'dumpster' && (
                            <BackLinkCustom to="/dumpster" mode={mode}>Retour</BackLinkCustom>
                        )}
                        {flowtype === 'craneTruck' && (
                            <BackLinkCustom to="/crane-truck" mode={mode}>Retour</BackLinkCustom>
                        )}
                    </BackLink>
                )}
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    flowtype: state.orderReducer.flow,
    truckOrderDetails: state.orderReducer.truckOrderDetails,
    bigbagOrderDetails: state.orderReducer.bigbagOrderDetails,
    dumpsterOrderDetails: state.orderReducer.dumpsterOrderDetails,
    craneTruckOrderDetails: state.orderReducer.craneTruckOrderDetails,
    mode: state.mainReducer.mode,
    activeCraneTrucks: state.initializationReducer.activeCraneTrucks,
});

const mapDispatchToProps = {
    setCurrentFlow
}

export default connect(mapStateToProps, mapDispatchToProps)(FlowButtonsContainer);