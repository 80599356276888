import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

const Container = styled.div`
    margin-top: 40px;
`;

const Title = styled.div`
    font-family: "Futura Bold";
    font-size: 20px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding-bottom: 25px;
`;

const Subtitle = styled.div`
    font-size: 16px;
    color: #1B2733;
    padding-left: 20px;
    padding-bottom: 30px;
`;

const MethodContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#DBDEE7"};
    border-radius: 5px;
    padding: 15px 15px;
    margin-bottom: 10px;
    font-style: italic;
    font-size: 16px;
    color: #8C8D8C;
    max-width: 650px;
    cursor: ${props => props.checked||props.isChecked===false ? 'default' : 'pointer'};
    background-color: #ffffff;
`;

const CustomField = styled(Field)`
    display: none;
`;

const CustomCheckbox = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#DBDEE7"};
    background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#FFFFFF"};
    visibility: ${props => props.visible===false ? "hidden" : "visible"};
`;

const Image = styled.img`
    padding-top: 1px;
`;

const InfoContainer = styled.label`
    display: flex;
    align-items: center;
    font-style: initial;
`;

const InfoType = styled.label`
    width: 64px;
    padding-left: 16px;
`;

const InfoText = styled.label`
    padding-left: 16px;
    & > div:last-child {
        font-style: italic;
        font-size: 14px;
    }
`;

const InfoTextStatus = styled.span`
    display: inline-block;
    margin-left: 12px;
    padding: 1px 6px;
    border-radius: 5px;
    color: #ffffff;
    background-color: ${props => props.color};
`;

const PaymentSelector = ({
    cards,
    ibans,
    paymentMethod,
    setFieldValue,
    mode,
}) => {
    return (
        <Container>
            <Title mode={mode}>Moyen de paiement</Title>
            <Subtitle>La prestation vous sera facturée 24H après l'évacuation des déchets sur le chantier.</Subtitle>
            {cards && cards.map((card, index) => (
                <MethodContainer
                    mode={mode}
                    key={card.id}
                    checked={paymentMethod === card.id}
                    isChecked={card.status !== 'EXPIRED'}
                    onClick={() => { if(card.status !== 'EXPIRED') { setFieldValue("paymentMethod", card.id); } }}
                >
                    <CustomField id={`cardMethod_${index}`} type="radio" name="paymentMethod" value={card.id} />
                    <CustomCheckbox mode={mode} checked={paymentMethod === card.id} visible={card.status !== 'EXPIRED'}>
                        <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                    </CustomCheckbox>
                    <InfoContainer htmlFor={`cardMethod_${index}`}>
                        <InfoType>
                            CARTE
                        </InfoType>
                        <InfoText>
                            <div>**** **** **** {card.last4}</div>
                            <div>
                                {card.expiry}
                                {
                                    (card.status === 'VALID_SOON_EXPIRATION')?(
                                        <InfoTextStatus color="#ffbc00">Expire bientôt</InfoTextStatus>
                                    ):(
                                        (card.status === 'VALID_IMMINENT_EXPIRATION')?(
                                            <InfoTextStatus color="#ff8d00">Expiration imminente !</InfoTextStatus>
                                        ):(
                                            (card.status === 'EXPIRED')?(
                                                <InfoTextStatus color="#ff2f00">Expiré</InfoTextStatus>
                                            ):(null)
                                        )
                                    )
                                }
                            </div>
                        </InfoText>
                    </InfoContainer>
                </MethodContainer>
            ))}
            {ibans && ibans.map((iban, index) => (
                <MethodContainer mode={mode} key={iban.id} checked={paymentMethod === iban.id.toString()} onClick={() => setFieldValue("paymentMethod", iban.id.toString())}>
                    <CustomField id={`ibanMethod_${index}`} type="radio" name="paymentMethod" value={iban.id} />
                    <CustomCheckbox mode={mode} checked={paymentMethod === iban.id.toString()}>
                        <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                    </CustomCheckbox>
                    <InfoContainer htmlFor={`ibanMethod_${index}`}>
                        <InfoType>
                            IBAN
                        </InfoType>
                        <InfoText>
                            <div>{iban.countryCode}***************{iban.ibanLast}</div>
                            <div>{iban.bankName}</div>
                        </InfoText> 
                    </InfoContainer>
                </MethodContainer>
            ))}
        </Container>
    )
}

export default PaymentSelector;