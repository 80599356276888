import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 4px 4px;
    padding: 10px 16px 12px 4px;
    border-radius: 5px;
    background-color: #ffffff;
    @media (max-width: 999px) {
        & {
            padding: 8px 8px 16px 8px;
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    height: 50px;
    width: 50px;
    margin-bottom: 8px;
`;

const Image = styled.img`
    height: 50px;
    width: 50px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid ${props => props.color};
`;

const WasteAmount = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${props => props.color};
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: -7px;
    right: -11px;
`;

const WasteLabel = styled.div`
    color: ${props => props.color};
`;

const TruckWasteIcon = ({
    quantity,
    type,
    color
}) => {
    return (
        <Container>
            <ImageContainer>
                <Image src={`https://assets.ripeurs.net/common/images/waste/${type}.png`} color={color} />
                <WasteAmount color={color}>{quantity}</WasteAmount>
            </ImageContainer>
            {type === 'rubble' && (<WasteLabel color={color}>Gravats</WasteLabel>)}
            {type === 'slate' && (<WasteLabel color={color}>Tuiles/Ardoises</WasteLabel>)}
            {type === 'mix' && (<WasteLabel color={color}>Mélangés</WasteLabel>)}
            {type === 'wood' && (<WasteLabel color={color}>Bois</WasteLabel>)}
            {type === 'iron' && (<WasteLabel color={color}>Ferraille</WasteLabel>)}
            {type === 'cardboard' && (<WasteLabel color={color}>Emballages</WasteLabel>)}
            {type === 'vegetal' && (<WasteLabel color={color}>Végétaux</WasteLabel>)}
            {type === 'bulky' && (<WasteLabel color={color}>Encombrants</WasteLabel>)}
            {type === 'dirt' && (<WasteLabel color={color}>Terre</WasteLabel>)}
            {type === 'plaster' && (<WasteLabel color={color}>Plâtre</WasteLabel>)}
        </Container>
    )
}

export default TruckWasteIcon;