import { takeLatest, call, put } from 'redux-saga/effects';
import { getPrice, validateVoucher, getCards, getIbans, getOrders } from '../services/api';

export const orderSaga = {
    getPrice: takeLatest('GET_PRICE', getPriceProcess),
    validateVoucher: takeLatest('VALIDATE_VOUCHER', validateVoucherProcess),
    getCardsAndIbans: takeLatest('GET_CARDS_AND_IBANS', getCardsAndIbansProcess),
    getOrders: takeLatest('GET_ORDERS', getOrdersProcess),
};

function* getPriceProcess(action) {
    try {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'flowValidationLoading', value: true } });

        const result = yield call(getPrice, action.payload.orderData);

        yield put({ type: 'GET_PRICE_SUCCESS', payload: { 
            price: Math.round(result.data.price*100)/100, 
            priceDetails: result.data.priceDetails 
        }});
    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response.data });
    } finally {
        yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'flowValidationLoading', value: false } });
    }
}

function* validateVoucherProcess(action) {
    try {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'voucherLoading', value: true } });

        const result = yield call(validateVoucher, action.payload.voucher, action.payload.userId);

        if (result.valid) {
            yield put({ type: 'VALIDATE_VOUCHER_SUCCESS', payload: result });
            switch (result.discount.type) {
                case 'AMOUNT':
                    yield put({ type: 'VALIDATE_VOUCHER_SUCCESS_AMOUNT', payload: result.discount.amount_off/100 });
                    break;
                case 'PERCENT':
                    yield put({ type: 'VALIDATE_VOUCHER_SUCCESS_PERCENT', payload: result.discount.percent_off });
                    break
                default:
                    break;
            }
        } else {
            yield put({ type: 'VALIDATE_VOUCHER_FAILURE', payload: "Code promo invalide" });
        }
    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response });
    } finally {
        yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'voucherLoading', value: false } });
    }
}

export function* getCardsAndIbansProcess() {
    try {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'cardsAndIbansLoading', value: true } });

        const cards = yield call(getCards);

        let orderedCards = cards.data.sort((a, b) => {
            a = new Date(a.createdAt);
            b = new Date(b.createdAt);
            return a > b ? -1 : a < b ? 1 : 0;
        })

        const ibans = yield call(getIbans);

        let orderedIbans = ibans.data.ibans.sort((a, b) => {
            a = new Date(a.createdAt);
            b = new Date(b.createdAt);
            return a > b ? -1 : a < b ? 1 : 0;
        })

        yield put({ type: 'GET_CARDS_AND_IBANS_SUCCESS', payload: { cards: orderedCards, ibans: orderedIbans } })
    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response });
    } finally {
        yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'cardsAndIbansLoading', value: false } })
    }
}

function* getOrdersProcess(action) {
    try {
        yield put({ type: 'GET_ORDERS_LOADING' });
        const result = yield call(getOrders);
        yield put({ type: 'GET_ORDERS_SUCCESS', payload: { orders: result.data.orders, _: Math.random() } });
    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response.data });
    }
}
