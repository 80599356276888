import { takeLatest, call, put } from 'redux-saga/effects';
import { getInstallation } from '../services/api';

export const initializationSaga = {
    getInstallation: takeLatest('GET_INSTALLATION', getInstallationProcess),
};

function* getInstallationProcess() {
    try {
        const result = yield call(getInstallation);

        yield put({ type: 'GET_INSTALLATION_SUCCESS', payload: result.data.config });
        yield put({ type: 'APP_LOCK_SUB' });
    } catch (error) {
        yield put({ type: 'APP_LOCK_SUB' });
        yield put({ type: 'ERROR', payload: error.response });
    }
}
