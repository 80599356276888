import React, { useState } from 'react';
import styled from 'styled-components';
import { useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import IBAN from "iban";
import ConfirmPopup from './../common/ConfirmPopup';
import "@reach/dialog/styles.css"

const MethodContainer = styled.div`
    border: 1px solid ${props => props.isOpened ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#DBDEE7"};
    border-radius: 5px;
    padding: ${props => props.isOpened ? "0 15px 15px 0" : "0"};
    margin-bottom: 10px;
    font-size: 16px;
    color: #8C8D8C;
    max-width: 650px;
    background-color: #ffffff;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 17px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    cursor: pointer;
    border-radius: 5px;
`;

const InfoLabel = styled.div`
    padding-left: 25px;
`;

const ModeMessage = styled.div`
    margin: 0px 0px 0px 15px;
    text-align: center;
    span {
        display: inline-block;
        padding: 6px 12px;
        border-radius: 5px;
        color: white;
        text-decoration: underline;
        background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    }
`;

const AddCardContainer = styled.div`
    position: relative;
    padding-left: 30px;
`;

const AddIbanContainer = styled.div`
    position: relative;
    padding-left: 30px;
    padding-bottom: 50px;
`;

const AddSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const FieldContainer = styled.div`
    flex: 1;
    min-width: 260px;
    padding-top: 32px;
    padding-right: 16px;
    @media (max-width: 999px) {
        & {
            padding-top: 16px;
        }
    }
`;

const Title = styled.div`
    font-family: "Futura Bold";
    font-size: 13px;
    color: #1B2733;
    padding-bottom: 10px;
`;

const CustomCardInput = styled(CardNumberElement)`
    width: 100%;
    height: 40px;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
`;
const CustomExpiryInput = styled(CardExpiryElement)`
    width: 80px;
    height: 40px;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
`;
const CustomCvcInput = styled(CardCvcElement)`
    width: 70px;
    height: 40px;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
`;
const CustomError = styled.div`
    margin-top: 2px;
    color: #ff1a1a;
    font-style: italic;
`;

const CustomIbanInput = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 14px;
`;

const Disclaimer = styled.div`
    margin-top: 12px;
    font-family: "Futura Light Italic";
    font-size: 14px;
`;

const Button = styled.div`
    padding: 12px 16px;
    background-color: ${props => props.disabled ? "#8C8D8C" : props.mode.feat===true?props.mode.colors.main:"#FF7D12"};
    border-radius: 5px;
    font-family: 'Futura';
    font-size: 18px;
    color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

const GclLink = styled.a`
    color: #8C8D8C;
`;

function AddPaymentMethod(props) {
    const { mode } = props;

    let [state, setState] = useState({
        stripeCompletes: {
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
        },
        stripeErrors: {
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
        },
    });
    
    const elements = useElements();

    let cardStripeElementOnChange = (event) => {
        setState({
            ...state,
            stripeCompletes: {
                ...state.stripeCompletes,
                [event.elementType]: event.complete,
            },
            stripeErrors: {
                ...state.stripeErrors,
                [event.elementType]: (event.error?event.error.message:null),
            },
        });
    }
    let cardIsValid = () => {
        if(state.stripeCompletes.cardNumber === false
        || state.stripeCompletes.cardExpiry === false
        || state.stripeCompletes.cardCvc === false
        || props.creationLoading === true) {
            return false;
        }
        return true;
    }
    let cardSubmit = () => {
        if(cardIsValid() === false) {
            return;
        }
        props.handleValidation(elements.getElement(CardNumberElement));
    }

    return (
        <MethodContainer mode={mode} isOpened={props.isOpened}>
            <div onClick={props.triggerDisplay}>
                <InfoContainer mode={mode}>
                    <img src={(require("./../../assets/img/orange-plus.svg"))} alt="check" />
                    <InfoLabel>
                        Ajouter {props.method === "card" ? (<span>une carte</span>) : (<span>un IBAN</span>)}
                    </InfoLabel>
                </InfoContainer>
            </div>
            {props.isOpened && (
                <div>
                    {props.mode.feat === true && (
                        <ModeMessage mode={mode}>
                            <span>Votre commande et sa facturation sont gérées directement par notre partenaire Les Ripeurs</span>
                        </ModeMessage>
                    )}
                    {props.method === "card" ? (
                        <AddCardContainer>
                            <AddSubContainer>
                                <FieldContainer>
                                    <Title>N° de carte</Title>
                                    <CustomCardInput
                                        onChange={(event) => cardStripeElementOnChange(event)}
                                        options={{ disabled: props.creationLoading }}
                                    ></CustomCardInput>
                                    <CustomError>{state.stripeErrors.cardNumber}</CustomError>
                                </FieldContainer>
                                <FieldContainer>
                                    <Title>Expiration</Title>
                                    <CustomExpiryInput
                                        onChange={(event) => cardStripeElementOnChange(event)}
                                        options={{ disabled: props.creationLoading }}
                                    ></CustomExpiryInput>
                                    <CustomError>{state.stripeErrors.cardExpiry}</CustomError>
                                </FieldContainer>
                            </AddSubContainer>
                            <AddSubContainer>
                                <FieldContainer>
                                    <Title>CVC/CCV</Title>
                                    <CustomCvcInput
                                        onChange={(event) => cardStripeElementOnChange(event)}
                                        options={{ disabled: props.creationLoading }}
                                    ></CustomCvcInput>
                                    <CustomError>{state.stripeErrors.cardCvc}</CustomError>
                                </FieldContainer>
                            </AddSubContainer>
                            <Button
                                mode={mode}
                                onClick={cardSubmit}
                                disabled={cardIsValid()===false}
                            >
                                {props.creationLoading===false?'Enregistrer':'Chargement...'}
                            </Button>
                        </AddCardContainer>
                    ) : (
                            <ConfirmPopup company={props.iban.owner} number={props.iban.number} address={props.iban.address}>
                                {confirm => (
                                    <AddIbanContainer>
                                        <AddSubContainer>
                                            <FieldContainer>
                                                <Title>Nom et Prénom</Title>
                                                <CustomIbanInput type="text" name="name" value={props.iban.name} onChange={props.handleIbanChange} />
                                            </FieldContainer>
                                            <FieldContainer>
                                                <Title>Société / Titulaire du compte bancaire</Title>
                                                <CustomIbanInput type="text" name="owner" value={props.iban.owner} onChange={props.handleIbanChange} />
                                            </FieldContainer>
                                        </AddSubContainer>
                                        <AddSubContainer>
                                            <FieldContainer>
                                                <Title>IBAN</Title>
                                                <CustomIbanInput type="text" name="number" value={props.iban.number} onChange={props.handleIbanChange} />
                                            </FieldContainer>
                                            <FieldContainer>
                                                <Title>Adresse du compte bancaire</Title>
                                                <CustomIbanInput type="text" name="address" value={props.iban.address} onChange={props.handleIbanChange} />
                                            </FieldContainer>
                                        </AddSubContainer>
                                        <Disclaimer>
                                            Paiements gérés GoCardless. Consultez la <GclLink href="https://gocardless.com/legal/privacy/" target="_blank" rel="noopener noreferrer">politique de confidentialité de GoCardless</GclLink>
                                        </Disclaimer>
                                        <Button
                                            mode={mode}
                                            onClick={
                                                (
                                                    props.iban.name !== ""
                                                    && props.iban.owner !== ""
                                                    && props.iban.number !== ""
                                                    && IBAN.isValid(props.iban.number)
                                                    && props.iban.address !== ""
                                                )?confirm(props.handleValidation):undefined
                                            }
                                            disabled={
                                                props.iban.name === ""
                                                || props.iban.owner === ""
                                                || props.iban.number === ""
                                                || !IBAN.isValid(props.iban.number)
                                                || props.iban.address === ""
                                            }
                                        >
                                            {props.creationLoading===false?'Enregistrer':'Chargement...'}
                                        </Button>
                                    </AddIbanContainer>
                                )}
                            </ConfirmPopup>
                        )}
                </div>
            )
            }

        </MethodContainer >
    );
}

export default AddPaymentMethod;