import { all } from "redux-saga/effects";
import { userSaga } from "./userSaga";
import { orderSaga } from "./orderSaga";
import { checkoutSaga } from "./checkoutSaga";
import { initializationSaga } from "./initializationSaga";

export default function* rootSaga() {
	yield all({
		...userSaga,
		...orderSaga,
		...checkoutSaga,
		...initializationSaga
	});
}
