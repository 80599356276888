import { takeLatest, call, put } from 'redux-saga/effects';
import { createOrder, createCard, createIban, redemptionVoucher } from "../services/api";
import { getCardsAndIbansProcess } from './orderSaga'

export const checkoutSaga = {
    createOrder: takeLatest('CREATE_ORDER', createOrderProcess),
    createCard: takeLatest('CREATE_CARD', createCardProcess),
    createIban: takeLatest('CREATE_IBAN', createIbanProcess)
}

function* createOrderProcess(action) {
    try {
        yield put({ type: "SET_ACTION_STATE", payload: { variable: 'orderCreationLoading', value: true } });

        const result = yield call(createOrder, action.payload.orderData, action.payload.selectedMethod, action.payload.selectedMethodType, action.payload.voucherDetails, action.payload.isAdmin);

        if(action.payload.voucherDetails
        && action.payload.voucherDetails.code) {
            redemptionVoucher(action.payload.voucherDetails.code, action.payload.userId);
        }

        if (result.data.error) {
            if (result.data.code === "pickup_too_short" || result.data.code === "pickup_too_old") {
                yield put({ type: 'ERROR', payload: { message: "Le créneau horaire que vous avez sélectionné n'est malheureusement plus disponible. Merci de sélectionner un nouvel horaire et recommencer." } })
            } else if (result.data.code === undefined) {
                yield put({ type: 'ERROR', payload: { message: "Nous n'avons pas pu valider le moyen de paiement, vérifiez vos informations." } })
            } else {
                yield put({ type: 'ERROR', payload: { message: "Une erreur s'est produite avec le créneau horaire sélectionné. Merci de sélectionner un nouvel horaire et recommencer." } })
            }
        } else {
			(window.dataLayer||[]).push({
				event: 'rip_order_create',
                key: result.data.key||'?-'+Math.random(),
                price: result.data.price,
                flow: result.data.flowtype,
            });
            yield put({ type: 'ORDER_SUCCESS', payload: { orderId: result.data.id } })
        }

    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response });
    } finally {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'orderCreationLoading', value: false } })
    }
}

function* createCardProcess(action) {
    try {
        yield ({ type: "SET_ACTION_STATE", payload: { variable: 'cardCreationLoading', value: true } })

        yield call(createCard, action.payload.cardData);
        (window.dataLayer||[]).push({
            event: 'rip_payment_create',
            type: 'card',
        });
        yield call(getCardsAndIbansProcess);

    } catch (error) {
        console.log('error stripe card (by server)');
        console.log(error);
        yield put({
            type: 'ERROR',
            payload: {
                message: `Une erreur s'est produite lors de l'ajout de votre carte bancaire. Merci de vérifier la valider de votre carte.`,
                messageSecondary: `Si le problème persiste, appelez le service client.`,
            },
        });
    } finally {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'cardCreationLoading', value: false } })
    }
}

function* createIbanProcess(action) {
    try {
        yield ({ type: "SET_ACTION_STATE", payload: { variable: 'ibanCreationLoading', value: true } })

        yield call(createIban, action.payload.ibanData);
        (window.dataLayer||[]).push({
            event: 'rip_payment_create',
            type: 'iban',
        });
        yield call(getCardsAndIbansProcess);

    } catch (error) {
        yield put({ type: 'ERROR', payload: { message: "Ce compte en banque est déjà enregistré" } });
    } finally {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'ibanCreationLoading', value: false } })
    }
}