import React from 'react';
import './App.css';
import {
    withRouter, Redirect, Route, Switch
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isLoggedIn } from './@helpers/login';
import moment from 'moment';
import 'moment/locale/fr';
import ShowcaseContainer from "./containers/ShowcaseContainer";
import HomeContainer from './containers/HomeContainer';
import LoginContainer from './containers/LoginContainer';
import PasswordResetContainer from './containers/PasswordResetContainer';
import SignUpContainer from './containers/SignUpContainer';
import ErrorsDisplayContainer from './containers/ErrorsDisplayContainer';
import { store, persistor } from './store'
import TruckFlowContainer from './containers/TruckFlowContainer';
import BigBagFlowContainer from './containers/BigBagFlowContainer';
import DumpsterFlowContainer from './containers/DumpsterFlowContainer';
import CraneTruckFlowContainer from './containers/CraneTruckFlowContainer';
import SummaryContainer from './containers/SummaryContainer';
import HeaderMessage from './components/common/HeaderMessage';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import EndContainer from './containers/EndContainer';
import OrdersContainer from './containers/OrdersContainer';
import NewServicesContainer from './containers/NewServicesContainer';
import Page404Container from './containers/Page404Container';
import { storeUtms } from './@helpers/utms';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
moment.locale('fr');


const PrivateRoute = ({ component: ComponentToRender, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isLoggedIn()) {
                return <Redirect to="/" />;
            }
            return <ComponentToRender {...props} />;
        }}
    />
);

class App extends React.Component {
    componentDidMount() {
        storeUtms(this.props.location.search);  
    }
    render() {
        let state = store.getState();

        if(state.mainReducer.appLock > 0) {
            setTimeout(() => { this.setState({}); }, 100);
            return (
                <div id="rip-app-loading">
                    <h3>Chargement...</h3>
                    <i>Si le chargement dure trop longtemps, <a href="/">actualisez la page</a>.</i>
                </div>
            );
        }

        return (
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ErrorsDisplayContainer />
                        <HeaderMessage></HeaderMessage>
                        <Header></Header>
                        <Switch>
                            <Route exact path="/" component={ShowcaseContainer} />
                            <Route path="/login" component={LoginContainer} />
                            <Route path="/password-reset" component={PasswordResetContainer} />
                            <Route path="/signup" component={SignUpContainer} />
                            <PrivateRoute path="/truck" component={TruckFlowContainer} />
                            <PrivateRoute path="/bigbag" component={BigBagFlowContainer} />
                            <PrivateRoute path="/dumpster" component={DumpsterFlowContainer} />
                            <PrivateRoute path="/crane-truck" component={CraneTruckFlowContainer} />
                            <PrivateRoute path="/summary" component={SummaryContainer} />
                            <PrivateRoute path="/home" component={HomeContainer} />
                            <PrivateRoute path="/end" component={EndContainer} />
                            <PrivateRoute path="/orders" component={OrdersContainer} />
                            <PrivateRoute path="/new-services" component={NewServicesContainer} />
                            <Route path="/*" component={Page404Container} />
                        </Switch>
                        <Footer></Footer>
                    </PersistGate>
                </Provider>
            </Elements>
        )
    }
}

export default withRouter(App);
