import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

const Container = styled.div`
    margin-bottom: 30px;
    padding: 5px;
    border: ${props => props.userbadsubmit === "true" ? "2px solid #cc0000" : "none"};
    border-radius: 4px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
`;

const CustomField = styled(Field)`
    display: none;
    margin-left: 20px;
    margin-right: 15px;
`;

const CustomCheckbox = styled.div`
    align-self: center;
    width: 17px;
    height: 17px;
    border-radius: 4px;
    border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:'#ff7d12' : "#FFFFFF"};
	margin-right: 10px;
    cursor: pointer;
`;

const Check = styled.img`
	height: 15px;
`;

const AcceptCGV = ({
    cgv,
    setFieldValue,
    legal,
    userBadSubmit,
    mode
}) => {
    return (
        <Container userbadsubmit={userBadSubmit.toString()}>
            <CheckboxContainer>
                <CustomField id={`cgv`} type="checkbox" name={`cgv`} />
                <CustomCheckbox mode={mode} checked={cgv} onClick={() => setFieldValue('cgv', !cgv)}>
                    <Check src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                </CustomCheckbox>
                <label htmlFor={`cgv`} onClick={() => setFieldValue('cgv', !cgv)}>
                    Je reconnais avoir pris connaissance et j’accepte les&nbsp;
                    <a href={legal.termsOfSales.url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>conditions générales de vente (CGV)</a>
                    &nbsp;et les&nbsp;
                    <a href={legal.termsOfService.url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>conditions générales d'utilisation (CGU)</a>
                </label>
            </CheckboxContainer>
        </Container>
    )
}

export default AcceptCGV;