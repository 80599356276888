import React from 'react';
import {
    isAddressServed,
    orderAddressAutocomplete,
    orderAddressId,
} from '../services/api';
import { Field } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 14px;
    color: #1B2733;
    padding-bottom: 15px;
`;

const CustomField = styled(Field)`
    font-size: 15px;
    height: 40px;
    border: ${props => props.userbadsubmit === "true" ? "2px solid #cc0000" : "1px solid #EEEEEE"};
    border-radius: 5px;
    padding-left: 15px;
    min-width: 100%;
    margin-bottom: 10px;
`;

const ResultContainer = styled.div`
    position: relative;
    z-index: 100;
`;

const Results = styled.div`
    position: absolute;
    z-index: 1;
    background-color: white;
    padding: 10px;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    width: 100%;
`;

const Result = styled.div`
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
    &:last-child {
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: none;
    }
`;

const Error = styled.div`
    font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 5px;
    font-size: 14px;
    text-align: center;
`;

class AddressSelectorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedPlaces: [],
            showResults: false,
            noResults: false,
        };
        this.searchDelayed = null;
    }

    searchPlaces = (searchValue) => {
        this.setState({
            noResults: false,
        });
        searchValue = searchValue.trim();
        if(searchValue.length < 3) {
            return;
        }
        clearTimeout(this.searchDelayed);
        this.searchDelayed = setTimeout(async () => {
            let result = await orderAddressAutocomplete(searchValue);
            
            if (result.data.length >= 1) {
                this.setState({
                    searchedPlaces: result.data,
                    noResults: false,
                });
            } else {
                this.setState({
                    searchedPlaces: [],
                    noResults: true,
                });
            }
        }, 500);
    }

    isValidPostalCode = async (postalCode) => {
        const { isServed } = await isAddressServed(postalCode);
        return isServed;
    };

    handleTextChange = (e) => {
        this.props.setFieldValue("postalCode", "");
        this.setState({ showResults: true });
        this.props.setStatus({ addressUnvalid: undefined });
        this.props.setFieldValue("address", e.target.value);
        this.searchPlaces(e.target.value);
    };

    onPressLocation = (item) => async () => {
        let result = await orderAddressId(item.place_id);
        let postalCode = false;
        for(let address_component of result.data.result.address_components) {
            if(address_component.types[0] === 'postal_code') {
                postalCode = address_component.long_name;
            }
        }

        if (postalCode && await this.isValidPostalCode(postalCode)) {
            this.props.setFieldValue("address", result.data.result.formatted_address);
            this.props.setFieldValue("postalCode", postalCode);
            this.setState({ showResults: false });
        } else {
            this.props.setFieldValue("address", "");
            this.props.setFieldValue("postalCode", "");
            this.props.setStatus({ addressUnvalid: `Adresse non prise en charge (seulement l'Île-de-France)` });
            this.setState({ showResults: false });
        }
    }

    renderPlaces(places) {
        return (
            <ResultContainer>
                <Results>
                    {
                        places.map(place => (
                            <Result key={place.place_id} onClick={this.onPressLocation(place)}>
                                {place.description}
                            </Result>
                        ))
                    }
                </Results>
            </ResultContainer>
        );
    }

    render() {
        const { searchedPlaces, showResults, noResults } = this.state;
        const { address, status, userBadSubmit } = this.props;
        return (
            <div>
                <Title>Adresse du chantier</Title>
                <CustomField
                    type="string"
                    name="address"
                    autoComplete="off"
                    value={address}
                    onChange={this.handleTextChange}
                    userbadsubmit={userBadSubmit.toString()}
                />
                {searchedPlaces && searchedPlaces.length > 0 && showResults && this.renderPlaces(searchedPlaces)}
                {status && (
                    <div>{status}</div>
                )}
                {noResults && (
                    <Error>Aucun résultats</Error>
                )}
            </div>
        )
    }
}

export default AddressSelectorContainer;