import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { getOrderPickups } from '../services/api';
import { Field, ErrorMessage } from 'formik';

const Container = styled.div`
    display: flex;
    overflow: auto;
    padding: 12px 8px;
    border: ${props => props.userbadsubmit === "true" ? "2px solid #cc0000" : "1px solid "+(props.mode.feat===true?"#d2d0d0":"#eeeeee")+""};
    border-radius: 5px;
    @media (max-width: 999px) {
        & {
            padding: 12px;
            border-radius: 0px;
            margin: 0px -16px;
            border-left: 0px none;
            border-right: 0px none;
        }
    }
`;

const Title = styled.div`
    padding-bottom: 8px;
    line-height: 110%;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    font-family: 'Futura Bold';
    font-size: 24px;
`;
const TitleSub = styled.div`
    padding-bottom: 24px;
    color: #aaaaaa;
    font-size: 16px;
`;

const ContainerEmpty = styled.div`
    width: 100%;
    margin: 16px;
    text-align: center;
    color: #aaaaaa;
    font-style: italic;
`;

const DayContainer = styled.div`
    min-width: 19%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:first-child {
        margin-left: auto;
    }
    &:last-child {
        margin-right: auto;
    }
    @media (max-width: 999px) {
        & {
            min-width: 155px;
        }
    }
`;

const DayLabel = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Futura Bold';
    font-size: 14px;
    color: #1B2733;
    padding-bottom: 12px;
    margin: 0px 10px;
    text-align: center;
    @media (max-width: 999px) {
        & {
            font-size: 12px;
            margin: 0px 6px;
        }
    }
`;

const Spacer = styled.div`
    flex: 1;
`;

const FieldContainer = styled.div`
    border: 1px solid ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#EEEEEE"};
    border-radius: 5px;
    padding: 20px 20px;
    margin: 0px 10px 10px;
    text-align: center;
    color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "inherit"};
    cursor: ${props => props.checked ? "default" : "pointer"};
    background-color: #ffffff;
    @media (max-width: 999px) {
        & {
            padding: 16px;
            margin: 0px 6px 6px;
        }
    }
`;

const CustomField = styled(Field)`
    display: none;
`;

const CustomLabel = styled.label`
    cursor: ${props => props.checked ? "default" : "pointer"};
`;

class TimeSlotSelectorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeSlots: null,
        };
    }

    componentDidMount() {
        this.fetchTimeSlots();
    }

    componentDidUpdate(prevProps) {
        if (this.props.wasteType !== prevProps.wasteType && this.props.wasteType === 'furniture') {
            this.setState({
                timeSlots: this.state.timeSlots.filter(time => {
                    return moment(time.date).isAfter(moment().add(3, 'days'), 'day');
                }),
            })
            this.props.setFieldValue('timeSlot', '');
        } else if (this.props.wasteType !== prevProps.wasteType && prevProps.wasteType === 'furniture') {
            this.fetchTimeSlots();
        }
    }

    async fetchTimeSlots() {
        try {
            const timeSlots = (await getOrderPickups(this.props.flowtype)).data;
            const cleanTimeSlots = timeSlots.pickups.filter(day => day.slots.length > 0 ? day : null)
            if (cleanTimeSlots && cleanTimeSlots.length > 0) {
                this.setState({
                    timeSlots: this.props.wasteType === 'furniture' ? cleanTimeSlots.filter(time => {
                        return moment(time.date).isAfter(moment().add(3, 'days'), 'day');
                    }) : cleanTimeSlots,
                })
            } else {
                console.log('no slots available');
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { timeSlots } = this.state;
        const { title, disablePickupAfter, userBadSubmit, mode } = this.props;
        return (
            <div>
                <Title mode={mode}>{title||`Date et heure du créneau d'évacuation`}</Title>
                <TitleSub>Sélectionnez l'un des choix ci-dessous.</TitleSub>
                <Container userbadsubmit={userBadSubmit.toString()} mode={mode}>
                    {
                        timeSlots && timeSlots.length > 0 && timeSlots.map(timeSlot => (
                            <DayContainer key={timeSlot.date}>
                                <DayLabel>
                                    <div>
                                        {moment.tz(timeSlot.date, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Paris').format('dddd').charAt(0).toUpperCase() + moment.tz(timeSlot.date, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Paris').format('dddd').slice(1)}
                                    </div>
                                    <div>
                                        {moment.tz(timeSlot.date, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Paris').format('LL')}
                                    </div>
                                </DayLabel>
                                <Spacer></Spacer>
                                {
                                    timeSlot && timeSlot.slots.map(slot => {
                                        let dateMinute = (new Date(slot.date)).getMinutes();
                                        let dateHour = (new Date(slot.date)).getHours() * 60 + dateMinute;
                                        return (
                                            <FieldContainer
                                                mode={mode}
                                                key={slot.date}
                                                checked={this.props.timeSlot === slot.date}
                                                onClick={() => {
                                                    this.props.setFieldValue("timeSlot", slot.date);
                                                }}
                                                style={{
                                                    visibility: (dateHour>=disablePickupAfter?'hidden':'visible'),
                                                }}
                                            >
                                                <CustomField
                                                    id={slot.date}
                                                    type="radio"
                                                    name="timeSlot"
                                                    value={slot.date}
                                                    checked={this.props.timeSlot === slot.date}
                                                />
                                                <CustomLabel
                                                    checked={this.props.timeSlot === slot.date}
                                                    htmlFor={slot.date}
                                                >
                                                    {slot.name}
                                                </CustomLabel>
                                            </FieldContainer>
                                        );
                                    })
                                }
                            </DayContainer>
                        ))
                    }
                    {!timeSlots||timeSlots.length===0?(
                        <ContainerEmpty>
                            Aucun créneau disponible
                        </ContainerEmpty>
                    ):null}
                </Container>

                <ErrorMessage name="timeSlot" component="div" />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    flowtype: state.orderReducer.flow,
	mode: state.mainReducer.mode,
})

export default connect(mapStateToProps)(TimeSlotSelectorContainer);