import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 14px;
    color: #1B2733;
    padding-bottom: 15px;
`;

const CustomField = styled(Field)`
    font-size: 15px;
    width: 100%;
    min-width: 300px;
    height: 40px;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    padding: 0 15px;
    margin-bottom: 15px;
    @media (min-width: 1000px) {
        & {
            margin-bottom: 45px;
        }
    }
`;

const CustomLargeField = styled(Field)` 
    resize: none;
    font-size: 15px;
    width: 100%;
    min-width: 300px;
    height: 130px;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-family: Arial;
`;

const AdditionalData = ({
    commentPH,
}) => {
    return (
        <div>
            <Title>Personne à contacter, téléphone (optionnel)</Title>
            <CustomField type="string" name="contact" placeholder="Téléphone et nom" maxLength="254" />
            <ErrorMessage name="contact" component="div" />

            <Title>Commentaires (optionnel)</Title>
            <CustomLargeField component="textarea" type="string" name="comment" placeholder={commentPH?commentPH:`Type d'encombrants, code d'accès, ...`} maxLength="999" />
            <ErrorMessage name="comment" component="div" />

            <Title>Référence chantier (optionnel)</Title>
            <CustomField type="string" name="reference" placeholder="n°A1B2C3" maxLength="30" />
            <ErrorMessage name="reference" component="div" />
        </div>
    )
}

export default AdditionalData;