import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 20px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    padding: 20px 0px;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
    width: 100%;
    height: 98px;
    cursor: ${props => props.checked ? 'default' : 'pointer'};
    background-color: #ffffff;
`;

const WasteImage = styled.img`
    align-self: center;
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const CustomLabel = styled.label`
    padding-right: 20px;
    margin-right: auto;
    cursor: ${props => props.checked ? 'default' : 'pointer'};
`;

const LabelTitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 16px;
    color: #8C8D8C;
    padding-bottom: 5px;
`;

const Info = styled.div`
    font-size: 14px;
    color: #AAAAAA;
`;

const CustomField = styled(Field)`
    display: none;
`;

const CustomCheckbox = styled.div`
    align-self: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    background-color: ${props => props.checked ? props.mode.feat===true?props.mode.colors.main:"#FF7D12" : "#FFFFFF"};
`;

const Image = styled.img`
    padding-top: 1px;
`;

const DumpsterWasteTypeSelector = ({
    setFieldValue,
    activeDumpsters,
    dumpsterWasteType,
    getColor,
    mode,
}) => {
    return (
        <div>
            <Title mode={mode}>Vos déchets</Title>
            {Object.keys(activeDumpsters).map(dumpster => {
                return (
                    <div key={dumpster}>
                        {activeDumpsters[dumpster].active && (
                            <FieldContainer
                                checked={dumpsterWasteType === dumpster}
                                onClick={() => {
                                    if(dumpsterWasteType !== dumpster) {
                                        setFieldValue("wasteType", dumpster);
                                        setFieldValue("wasteSize", '');
                                    }
                                }}
                            >
                                <WasteImage color={getColor(dumpster)} src={`https://assets.ripeurs.net/common/images/waste/${dumpster}.png`} alt="waste-logo" />
                                {dumpster === 'rubble' && (
                                    <CustomLabel checked={dumpsterWasteType === 'rubble'} htmlFor="rubble">
                                        <LabelTitle>Gravats</LabelTitle>
                                        <Info>Béton, gravier, tuile, céramique, brique, ciment, terre</Info>
                                    </CustomLabel>
                                )}
                                {dumpster === 'mixed' && (
                                    <CustomLabel checked={dumpsterWasteType === 'mixed'} htmlFor="mixed">
                                        <LabelTitle>Mélangés</LabelTitle>
                                        <Info>BA13, Placoplâtre, plâtre, tout-venant,…</Info>
                                    </CustomLabel>
                                )}
                                {dumpster === 'wood' && (
                                    <CustomLabel checked={dumpsterWasteType === 'wood'} htmlFor="wood">
                                        <LabelTitle>Bois</LabelTitle>
                                        <Info>Bois UNIQUEMENT sinon surfacturation</Info>
                                    </CustomLabel>
                                )}
                                {dumpster === 'furniture' && (
                                    <CustomLabel checked={dumpsterWasteType === 'furniture'} htmlFor="furniture">
                                        <LabelTitle>Mobilier</LabelTitle>
                                        <Info>Meubles, chaises, mobilier de bureau, etc</Info>
                                    </CustomLabel>
                                )}
                                {dumpster === 'vegetal' && (
                                    <CustomLabel checked={dumpsterWasteType === 'vegetal'} htmlFor="vegetal">
                                        <LabelTitle>Végétaux</LabelTitle>
                                        <Info>Feuilles, branches (pas de souches)</Info>
                                    </CustomLabel>
                                )}
                                {dumpster === 'iron' && (
                                    <CustomLabel checked={dumpsterWasteType === 'iron'} htmlFor="iron">
                                        <LabelTitle>Ferraille</LabelTitle>
                                        <Info>Ferraille UNIQUEMENT sinon déclassement en Mélangés</Info>
                                    </CustomLabel>
                                )}
                                <CustomField
                                    id={dumpster}
                                    key={dumpster}
                                    type="radio"
                                    name="wasteType"
                                    value={dumpster}
                                    checked={dumpsterWasteType === dumpster}
                                    onChange={() => {
                                        setFieldValue('wasteType', dumpster);
                                        setFieldValue('wasteSize', '');
                                    }}
                                />
                                <CustomCheckbox mode={mode} checked={dumpsterWasteType === dumpster}>
                                    <Image src={(require("./../../assets/img/check-icon.svg"))} alt="check" />
                                </CustomCheckbox>
                            </FieldContainer>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default DumpsterWasteTypeSelector;