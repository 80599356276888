import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Content from '../components/common/Content';


const Container = styled(Content)`
	max-width: 700px;
    min-height: ${props => props.mode.feat === true ? '1px' : 'calc(100vh - 92px - 70px)'};
	margin: 0px auto;
	user-select: text;
	text-align: center;
`;
const BackToHome = styled(Link)`
	display: block;
	margin-bottom: 16px;
	color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
`;
const Title = styled.div`
	margin-bottom: 32px;
	line-height: 110%;
	font-family: 'Futura Bold';
	font-size: 24px;
`;


class Page404Container extends React.Component {
    render() {
		const { mode } = this.props;

        return (
            <Container mode={mode}>
				{mode.key!=='native'?(
					<BackToHome to='/' mode={mode}>
						Retour à l'accueil
					</BackToHome>
				):null}
				<Title>
					Page inconnue
				</Title>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
		mode: state.mainReducer.mode,
	};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Page404Container);