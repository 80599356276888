import styled from 'styled-components';


const Content = styled.div`
    margin: 0px auto;
    max-width: 1200px;
    padding: 16px;
    @media (max-width: 999px) {
        & {
            max-width: 600px;
        }
    }
`;


export default Content;