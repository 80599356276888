import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const SubContainer = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 100%;
    @media (min-width: 1000px) {
        &:first-child {
            margin-right: 8px;
        }
        &:last-child {
            margin-left: 8px;
        }
    }
`;

const InfoContainer = styled.div`
`;

const Label = styled.div`
    font-family: "Futura Bold";
    margin-top: 16px;
    margin-bottom: 4px;
`;

const Comment = styled.div`
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`;

const TimeSlotContainer = styled.div`
    &::first-letter {
        text-transform: uppercase;
    }
`;

const craneTruckType = new Map([
    ["CRANETRUCK__MIXED_", "Mélangés"],
    ["CRANETRUCK__RUBBLE_", "Gravats"],
    ["CRANETRUCK__DIRT_", "Terre"]
]);

const AdditionnalDataSummary = ({
    flow,
    orderDetails
}) => {
    let wasteDetails = [];
    if(orderDetails.priceDetails) {
        const itemsOrder = Object.entries(orderDetails.priceDetails.itemsOrder);
        craneTruckType.forEach((key, type) => {
            let detailsPerWaste = `${key} : `;
            const items = itemsOrder.filter(item => item[0].startsWith(type))
            if(items.length > 0) {
                items.map((item, index) => {
                    if(index > 0)
                        detailsPerWaste += ', ';
                    detailsPerWaste += `${item[1].quantity} x ${item[0].split(type)[1]}m3`;
                })    
                wasteDetails.push(detailsPerWaste);
            }
        })
    }
    
    return (
        <Container>
            <SubContainer>
                {(flow === 'craneTruck') && (
                    <div>
                        <InfoContainer>
                            <Label>Details d'intervention:</Label>
                            {wasteDetails.map(detail => <div>{detail}</div>)}
                        </InfoContainer>
                    </div>
                )}
                <InfoContainer>
                    <Label>Adresse:</Label>{orderDetails.address}
                </InfoContainer>
                {(flow === 'truck' || flow === 'dumpster') && (
                    <div>
                        <InfoContainer>
                            <Label>Accès: </Label>
                            {orderDetails.access === 'sidewalk' && (<span>Trottoir</span>)}
                            {orderDetails.access === 'groundFloor' && (<span>Cour de l'immeuble</span>)}
                            {orderDetails.access === 'upstairs' && (
                                orderDetails.floor === 1 ? (
                                    <span> Au 1er étage</span>
                                ) : (
                                        <span> Au {orderDetails.floor}ème étage</span>
                                    )
                            )}
                            {orderDetails.access === 'publicRoadway' && (<span>Voie publique</span>)}
                            {orderDetails.access === 'privateProperty' && (<span>Terrain privé</span>)}
                        </InfoContainer>
                    </div>
                )}
                <InfoContainer>
                    <Label>Créneau horaire: </Label>
                    {orderDetails.timeSlot && (
                        <TimeSlotContainer>
                            {moment(orderDetails.timeSlot).format("dddd DD MMMM YYYY")} - de {moment(orderDetails.timeSlot).format("HH:mm")} à&nbsp;
                            {flow === "truck" ? (
                                <span>
                                    {moment(orderDetails.timeSlot).add(2, 'hours').format("HH:mm")}
                                </span>
                            ) : flow === "craneTruck" ? (
                                <span>
                                    {moment(orderDetails.timeSlot).add(8, 'hours').format("HH:mm")}
                                </span>
                            ) : (
                                    <span>
                                        {moment(orderDetails.timeSlot).format("HH") === "12" ? (
                                            <span>
                                                {moment(orderDetails.timeSlot).add(3, 'hours').format("HH:mm")}
                                            </span>
                                        ) : (
                                                <span>
                                                    {moment(orderDetails.timeSlot).add(4, 'hours').format("HH:mm")}
                                                </span>
                                            )}
                                    </span>
                                )}
                        </TimeSlotContainer>
                    )}
                </InfoContainer>
            </SubContainer>
            {(orderDetails.contact || orderDetails.comment || orderDetails.reference) && (
                <SubContainer>
                    {orderDetails.contact && (
                        <InfoContainer>
                            <Label>Personne à contacter: </Label>{orderDetails.contact}
                        </InfoContainer>
                    )}
                    {orderDetails.comment && (
                        <InfoContainer>
                            <Label>Commentaire: </Label><Comment>{orderDetails.comment}</Comment>
                        </InfoContainer>
                    )}
                    {orderDetails.reference && (
                        <InfoContainer>
                            <Label>Référence chantier: </Label>{orderDetails.reference}
                        </InfoContainer>
                    )}
                </SubContainer>
            )}
        </Container>
    )
}

export default AdditionnalDataSummary;