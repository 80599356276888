import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 10px;
`;

const WasteTypeContainer = styled.div`
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    font-family: 'Futura Bold';
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    color: #FF7D12;
`;

const WasteContainer = styled.div`
    width: 100%;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #ffffff;
    @media (max-width: 999px) {
        & {
            padding: 8px;
        }
        & > img {
            margin-right: 10px;
        }
        & > div:nth-child(2) {
            padding-right: 0px;
        }
        & > div:nth-child(4) input {
            width: 50px;
        }
    }
`;

const WasteSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const WasteInfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    @media (max-width: 999px) {
        & {
            padding-right: 8px;
        }
    }
`;

const WasteTitle = styled.span`
    color: ${props => props.color};
    padding-bottom: 5px;
    word-break: break-word;
`;

const WasteLabel = styled.span`
    font-family: 'Futura';
    font-size: 10px;
    color: #AAAAAA;
`;

const CustomButton = styled.button`
    background: none;
    cursor: pointer;
    border: none;
    outline: none !important;
    height: 50px;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #E0E2E4;
    border-radius: 5px;
    height: 60px;
`;

const CustomField = styled(Field)`
    border: none;
    width: 65px;
    font-family: 'Futura Bold';
    font-size: 20px;
    text-align: center;
    color: ${props => props.value > 0 ? props.mode.feat===true?props.mode.colors.main:'#ff7d12' : "#000000"};
    @media (max-width: 999px) {
        & {
            width: 50px;
        }
    }
`;

const FieldUnit = styled.span`
    font-size: 11px;
    color: #AAAAAA;
    text-align: center;
`;

const Image = styled.img`
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const PlusButton = styled.img`
    padding-top: 5px;
`;

const CustomError = styled(ErrorMessage)`
    font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 5px;
    font-size: 14px;
    text-align: center;
`;

const TruckWasteSelector = ({
    values: {
        rubbleBag, mixBag, plasterBag, ironBag, woodBag, vegetalBag,
        bulky, mixM3, plasterM3, woodM3, cardboardM3, ironM3, vegetalM3, slateM3
    },
    setFieldValue,
    decrementQuantity,
    incrementQuantity,
    mode,
}) => {
    return (
        <Container>
            <WasteTypeContainer>
                <Title>Déchets en sacs</Title>
                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/rubble.png`} color="#EEB054" />

                        <WasteInfoContainer>
                            <WasteTitle color="#EEB054">Gravats</WasteTitle>
                            <WasteLabel>Béton, gravier, tuile, céramique, brique, ciment</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("rubbleBag", decrementQuantity(rubbleBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="rubbleBag" value={rubbleBag} />
                            {rubbleBag === 0 || rubbleBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("rubbleBag", incrementQuantity(rubbleBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="rubbleBag" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/mixed.png`} color="#8C8D8C" />

                        <WasteInfoContainer>
                            <WasteTitle color="#8C8D8C">Mélangés</WasteTitle>
                            <WasteLabel>Tout-venant, ...</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("mixBag", decrementQuantity(mixBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="mixBag" value={mixBag} />
                            {mixBag === 0 || mixBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("mixBag", incrementQuantity(mixBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="mixBag" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/plaster.png`} color="#666666" />

                        <WasteInfoContainer>
                            <WasteTitle color="#666666">Plâtre</WasteTitle>
                            <WasteLabel>Carreau de plâtre, BA13...</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("plasterBag", decrementQuantity(plasterBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="plasterBag" value={plasterBag} />
                            {plasterBag === 0 || plasterBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("plasterBag", incrementQuantity(plasterBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="plasterBag" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/wood.png`} color="#7294EA" />

                        <WasteInfoContainer>
                            <WasteTitle color="#7294EA">Bois</WasteTitle>
                            <WasteLabel>Fagots de bois, parquet</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("woodBag", decrementQuantity(woodBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="woodBag" value={woodBag} />
                            {woodBag === 0 || woodBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("woodBag", incrementQuantity(woodBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="woodBag" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/iron.png`} color="#FA6684" />

                        <WasteInfoContainer>
                            <WasteTitle color="#FA6684">Ferraille</WasteTitle>
                            <WasteLabel>Ferraille, acier, fonte (-1€ / 20kg)</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("ironBag", decrementQuantity(ironBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="ironBag" value={ironBag} />
                            {ironBag === 0 || ironBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("ironBag", incrementQuantity(ironBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="ironBag" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/vegetal.png`} color="#7CCF6B" />

                        <WasteInfoContainer>
                            <WasteTitle color="#7CCF6B">Végétaux</WasteTitle>
                            <WasteLabel>Feuilles, branches, végétaux</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("vegetalBag", decrementQuantity(vegetalBag))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" name="vegetalBag" value={vegetalBag} />
                            {vegetalBag === 0 || vegetalBag === 1 ? (
                                <FieldUnit>sac</FieldUnit>
                            ) : (
                                    <FieldUnit>sacs</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("vegetalBag", incrementQuantity(vegetalBag))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="vegetalBag" component="div" />
                    </div>
                </WasteContainer>
            </WasteTypeContainer>

            <WasteTypeContainer>
                <Title>Déchets en vrac</Title>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/slate.png`} color="#DE471E" />

                        <WasteInfoContainer>
                            <WasteTitle color="#DE471E">Tuiles / Ardoises</WasteTitle>
                            <WasteLabel>Tuiles, ardoises, briques, parpaing</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("slateM3", decrementQuantity(slateM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="slateM3" value={slateM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("slateM3", incrementQuantity(slateM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="slateM3" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/mixed.png`} color="#8C8D8C" />

                        <WasteInfoContainer>
                            <WasteTitle color="#8C8D8C">Mélangés</WasteTitle>
                            <WasteLabel>Tout-venant, ...</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("mixM3", decrementQuantity(mixM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="mixM3" value={mixM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("mixM3", incrementQuantity(mixM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="mixM3" component="div" />
                    </div>
                </WasteContainer>
                
                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/plaster.png`} color="#666666" />

                        <WasteInfoContainer>
                            <WasteTitle color="#666666">Plâtre</WasteTitle>
                            <WasteLabel>Carreau de plâtre, BA13...</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("plasterM3", decrementQuantity(plasterM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="plasterM3" value={plasterM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("plasterM3", incrementQuantity(plasterM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="plasterM3" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/wood.png`} color="#7294EA" />

                        <WasteInfoContainer>
                            <WasteTitle color="#7294EA">Bois</WasteTitle>
                            <WasteLabel>Bois, mobilier, étagères, plinthe, portes</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("woodM3", decrementQuantity(woodM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="woodM3" value={woodM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("woodM3", incrementQuantity(woodM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="woodM3" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/cardboard.png`} color="#D1A184" />

                        <WasteInfoContainer>
                            <WasteTitle color="#D1A184">Emballages</WasteTitle>
                            <WasteLabel>Carton, plastique, polystyrène, laine de verre</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("cardboardM3", decrementQuantity(cardboardM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="cardboardM3" value={cardboardM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("cardboardM3", incrementQuantity(cardboardM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="cardboardM3" component="div" />
                    </div>
                </WasteContainer>

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/iron.png`} color="#FA6684" />

                        <WasteInfoContainer>
                            <WasteTitle color="#FA6684">Ferraille</WasteTitle>
                            <WasteLabel>Ferraille, acier, fonte</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("ironM3", decrementQuantity(ironM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>
                        
                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="ironM3" value={ironM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("ironM3", incrementQuantity(ironM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="ironM3" component="div" />
                    </div>
                </WasteContainer>

                {/* <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/vegetal.png`} color="#7CCF6B" />

                        <WasteInfoContainer>
                            <WasteTitle color="#7CCF6B">Végétaux</WasteTitle>
                            <WasteLabel>Feuilles, branches, végétaux</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("vegetalM3", decrementQuantity(vegetalM3))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="vegetalM3" value={vegetalM3} />
                            <FieldUnit>m3</FieldUnit>
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("vegetalM3", incrementQuantity(vegetalM3))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="vegetalM3" component="div" />
                    </div>
                </WasteContainer> */}

                <WasteContainer>
                    <WasteSubContainer>
                        <Image src={`https://assets.ripeurs.net/common/images/waste/bulky.png`} color="#59BDBC" />

                        <WasteInfoContainer>
                            <WasteTitle color="#59BDBC">Encombrants</WasteTitle>
                            <WasteLabel>WC, bac à douches, hotte, four, mobilier, bidet</WasteLabel>
                        </WasteInfoContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("bulky", decrementQuantity(bulky))}>
                            <img src={(require('./../../assets//img/minus.svg'))} alt="minus" />
                        </CustomButton>

                        <FieldContainer>
                            <CustomField mode={mode}  type="number" min="0" max="999" step="0.5" name="bulky" value={bulky} />
                            {bulky === 0 || bulky === 1 ? (
                                <FieldUnit>objet</FieldUnit>
                            ) : (
                                    <FieldUnit>objets</FieldUnit>
                                )}
                        </FieldContainer>

                        <CustomButton type="button" onClick={() => setFieldValue("bulky", incrementQuantity(bulky))}>
                            <PlusButton src={(require('./../../assets//img/plus.svg'))} alt="plus" />
                        </CustomButton>
                    </WasteSubContainer>

                    <div>
                        <CustomError name="bulky" component="div" />
                    </div>
                </WasteContainer>

            </WasteTypeContainer>
        </Container>
    )
}

export default TruckWasteSelector;