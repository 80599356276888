import React from 'react';
import styled from 'styled-components';
import TruckWasteIcon from './TruckWasteIcon';

const Container = styled.div`
    padding-top: 25px;
    padding-bottom: 45px;
`;

const TruckSummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const WasteTypeContainer = styled.div`
    flex: 1;
    min-width: 300px;
`;

const WasteTypeSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Title = styled.div`
    font-family: 'Futura Bold';
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 32px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    @media (max-width: 999px) {
        & {
            padding-top: 32px;
            padding-bottom: 16px;
        }
    }
`;

const BigBagSummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 20px;
    max-width: 500px;
    background-color: #ffffff;
`;

const InfoBigBagIcon = styled.img`
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const InfoBigBagContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 20px;
    @media (max-width: 999px) {
        & {
            padding-right: 8px;
        }
    }
`;

const InfoBigBagTitle = styled.span`
    font-family: 'Futura Bold';
    font-size: 16px;
    padding-bottom: 5px;
    color: #8C8D8C;
`;

const InfoBigBagSubTitle = styled.span`
    font-family: 'Futura Italic';
    font-size: 14px;
    color: #ffa459;
`;

const InfoBigBagLabel = styled.span`
    font-family: 'Futura';
    font-size: 14px;
    color: #AAAAAA;
`;

const BigBagValue = styled.div`
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    font-size: 22px;
    font-family: 'Futura Bold';
    padding-right: 8px;
    @media (max-width: 999px) {
        & {
            padding-right: 0px;
        }
    }
`;

const DumpsterSummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #ffffff;
`;

const DumpsterDropTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 15px;
    max-width: 400px;
    height: 85px;
    flex: 1;
`;

const InfoDumpsterContainer = styled.div`

`;

const InfoDumpsterTitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 16px;
    color: #8C8D8C;
    padding-bottom: 5px;
`;

const InfoDumpsterLabel = styled.div`
    font-size: 14px;
    color: #AAAAAA;
`;

const DumpsterWasteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 15px;
    max-width: 400px;
    height: 85px;
    flex: 1;
`;

const DumpsterInfoContainer = styled.div`
    margin-right: 15px;
`;

const DumpsterTitle = styled.div`
    font-family: 'Futura Bold';
    font-size: 16px;
    color: #8C8D8C;
    padding-bottom: 5px;
`;

const DumpsterInfo = styled.div`
    font-size: 14px;
    color: #AAAAAA;
`;

const DumpsterValue = styled.div`
    font-family: 'Futura Bold';
    font-size: 24px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    white-space: nowrap;
    margin-left: auto;
`;

const DumpsterWasteImage = styled.img`
    align-self: center;
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
`;

const WasteSummary = ({
    flow,
    orderDetails,
    getColor,
    mode,
}) => {
    return (
        <Container>
            {flow === 'truck' && (
                <TruckSummaryContainer>
                    {(
                        orderDetails.rubbleBag !== 0
                        || orderDetails.mixBag !== 0
                        || orderDetails.plasterBag !== 0
                        || orderDetails.ironBag !== 0
                        || orderDetails.woodBag !== 0
                        || orderDetails.vegetalBag !== 0
                    ) && (
                        <WasteTypeContainer>
                            <Title mode={mode}>Déchets en sacs</Title>
                            <WasteTypeSubContainer>
                                {orderDetails.rubbleBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.rubbleBag} type="rubble" color="#EEB054" />
                                )}
                                {orderDetails.mixBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.mixBag} type="mix" color="#8C8D8C" />
                                )}
                                {orderDetails.plasterBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.plasterBag} type="plaster" color="#666666" />
                                )}
                                {orderDetails.ironBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.ironBag} type="iron" color="#FA6684" />
                                )}
                                {orderDetails.woodBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.woodBag} type="wood" color="#7294EA" />
                                )}
                                {orderDetails.vegetalBag !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.vegetalBag} type="vegetal" color="#7CCF6B" />
                                )}
                            </WasteTypeSubContainer>
                        </WasteTypeContainer>
                    )}
                    {(
                        orderDetails.bulky !== 0
                        || orderDetails.mixM3 !== 0
                        || orderDetails.plasterM3 !== 0
                        || orderDetails.woodM3 !== 0
                        || orderDetails.cardboardM3 !== 0
                        || orderDetails.ironM3 !== 0
                        || orderDetails.vegetalM3 !== 0
                        || orderDetails.slateM3 !== 0
                    ) && (
                        <WasteTypeContainer>
                            <Title mode={mode}>Déchets en vrac</Title>
                            <WasteTypeSubContainer>
                                {orderDetails.bulky !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.bulky} type="bulky" color="#59BDBC" />
                                )}
                                {orderDetails.mixM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.mixM3} type="mix" color="#8C8D8C" />
                                )}
                                {orderDetails.plasterM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.plasterM3} type="plaster" color="#666666" />
                                )}
                                {orderDetails.woodM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.woodM3} type="wood" color="#7294EA" />
                                )}
                                {orderDetails.cardboardM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.cardboardM3} type="cardboard" color="#D1A184" />
                                )}
                                {orderDetails.ironM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.ironM3} type="iron" color="#FA6684" />
                                )}
                                {orderDetails.vegetalM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.vegetalM3} type="vegetal" color="#7CCF6B" />
                                )}
                                {orderDetails.slateM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.slateM3} type="slate" color="#DE471E" />
                                )}
                            </WasteTypeSubContainer>
                        </WasteTypeContainer>
                    )}
                </TruckSummaryContainer>
            )}
            {flow === 'bigbag' && (
                <div>
                    <Title mode={mode}>Votre demande</Title>
                    {orderDetails.bigBagRubbleNumber>0?(
                        <BigBagSummaryContainer>
                            <InfoBigBagIcon
                                src={`https://assets.ripeurs.net/common/images/waste/rubble.png`}
                                color="#eeb054"
                            />
                            <InfoBigBagContainer>
                                <InfoBigBagTitle>Nombre de Big Bags gravats/terre</InfoBigBagTitle>
                                <InfoBigBagSubTitle>Prix plus avantageux à partir de 8 Big Bags</InfoBigBagSubTitle>
                                <InfoBigBagLabel>
                                    Béton, gravier, tuile, céramique, brique, ciment, terre.
                                </InfoBigBagLabel>
                            </InfoBigBagContainer>
                            <BigBagValue mode={mode}>
                                {orderDetails.bigBagRubbleNumber}
                            </BigBagValue>
                        </BigBagSummaryContainer>
                    ):null}
                    {orderDetails.bigBagNumber>0?(
                        <BigBagSummaryContainer>
                            <InfoBigBagIcon
                                src={`https://assets.ripeurs.net/common/images/waste/mixed.png`}
                                color="#8c8d8c"
                            />
                            <InfoBigBagContainer>
                                <InfoBigBagTitle>Nombre de Big Bags mélangés</InfoBigBagTitle>
                                <InfoBigBagLabel>
                                    Plâtre, bois, cartons, tout-venant, ...
                                </InfoBigBagLabel>
                            </InfoBigBagContainer>
                            <BigBagValue mode={mode}>
                                {orderDetails.bigBagNumber}
                            </BigBagValue>
                        </BigBagSummaryContainer>
                    ):null}
                </div>
            )}
            {flow === 'dumpster' && (
                <div>
                    <Title mode={mode}>Votre demande</Title>
                    <DumpsterSummaryContainer>
                        <DumpsterDropTypeContainer>
                            {orderDetails.dropType === "depot" && (
                                <InfoDumpsterContainer>
                                    <InfoDumpsterTitle>Dépôt de benne</InfoDumpsterTitle>
                                    <InfoDumpsterLabel>Nous vous déposons une benne sur un terrain privé ou sur la voie publique si vous avez une autorisation préfectorale.</InfoDumpsterLabel>
                                </InfoDumpsterContainer>
                            )}
                            {orderDetails.dropType === "echange" && (
                                <InfoDumpsterContainer>
                                    <InfoDumpsterTitle>Échange de benne</InfoDumpsterTitle>
                                    <InfoDumpsterLabel>Nous venons récupérer votre benne pleine et nous vous déposons une benne vide.</InfoDumpsterLabel>
                                </InfoDumpsterContainer>
                            )}
                            {orderDetails.dropType === "passante" && (
                                <InfoDumpsterContainer>
                                    <InfoDumpsterTitle>Benne passante</InfoDumpsterTitle>
                                    <InfoDumpsterLabel>Nous vous déposons une benne pour une durée de 30min à 45min que vous chargez. Cela ne nécessite pas d'autorisation préfectorale.</InfoDumpsterLabel>
                                </InfoDumpsterContainer>
                            )}
                        </DumpsterDropTypeContainer>

                        <DumpsterWasteContainer>
                            <DumpsterWasteImage color={getColor(orderDetails.wasteType)} src={`https://assets.ripeurs.net/common/images/waste/${orderDetails.wasteType}.png`} alt="waste-logo" />
                            {orderDetails.wasteType === 'rubble' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Gravats</DumpsterTitle>
                                    <DumpsterInfo>Béton, gravier, tuile, céramique, brique, ciment, terre</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}
                            {orderDetails.wasteType === 'mixed' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Mélangés</DumpsterTitle>
                                    <DumpsterInfo>BA13, Placoplâtre, plâtre, tout-venant,…</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}
                            {orderDetails.wasteType === 'wood' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Bois</DumpsterTitle>
                                    <DumpsterInfo>Fagots de bois, sacs de bois</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}
                            {orderDetails.wasteType === 'furniture' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Mobilier</DumpsterTitle>
                                    <DumpsterInfo>Meubles, chaises, mobilier de bureau, etc</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}
                            {orderDetails.wasteType === 'vegetal' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Végétaux</DumpsterTitle>
                                    <DumpsterInfo>Feuilles, branches (pas de souches)</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}
                            {orderDetails.wasteType === 'iron' && (
                                <DumpsterInfoContainer>
                                    <DumpsterTitle>Ferraille</DumpsterTitle>
                                    <DumpsterInfo>Ferraille, fonte</DumpsterInfo>
                                </DumpsterInfoContainer>
                            )}

                            <DumpsterValue mode={mode}>{orderDetails.wasteSize} m3</DumpsterValue>
                        </DumpsterWasteContainer>

                    </DumpsterSummaryContainer>
                </div>
            )}
            {flow === 'craneTruck' && (
                <TruckSummaryContainer>
                    {(
                        orderDetails.mixM3 !== 0
                        || orderDetails.rubbleM3 !== 0
                        || orderDetails.dirtM3 !== 0
                    ) && (
                        <WasteTypeContainer>
                            <Title mode={mode}>Vos déchets à évacuer en m3</Title>
                            <WasteTypeSubContainer>
                                {orderDetails.mixM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.mixM3} type="mix" color="#8C8D8C" />
                                )}
                                {orderDetails.rubbleM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.rubbleM3} type="rubble" color="#EEB054" />
                                )}
                                {orderDetails.dirtM3 !== 0 && (
                                    <TruckWasteIcon quantity={orderDetails.dirtM3} type="dirt" color="#795548" />
                                )}
                            </WasteTypeSubContainer>
                        </WasteTypeContainer>
                    )}
                </TruckSummaryContainer>
            )}
        </Container>
    )
}

export default WasteSummary;