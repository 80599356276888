import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import img from './../assets/img/showcase-bg.png';

const Container = styled.div`
    height: ${props => props.mode.feat === true ? "780px" : "100vh"};
    padding: 16px;
    ${props => props.mode.feat===true&&props.mode.colors.homeBg?`
        background-color: ${props.mode.colors.homeBg};
    `:`
        background: url(${img}) no-repeat center center fixed;
    `}
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.mode.feat === true ? "start" : "center"};
    align-items: center;
    color: white;
    font-size: 24px;

    @media (max-width: 999px) {
        & {
            ${props => props.mode.feat === true ? "height: 600px;" : ""};
        }
    }
`;

const Contact = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    text-align: right;
    font-size: 18px;

    @media (max-width: 999px) {
        & {
            display: none;
        }
    }
`;

const ContactMode = styled.div`
    font-size: 18px;
    margin-top: 30px;
    font-family: 'Futura Light';
`;

const ContactPhone = styled.a`
    display: block;
	text-decoration: none;
`;
const ContactMail = styled.a`
    display: block;
    line-height: 40px;
	text-decoration: none;
`;

const Main = styled.div`
    font-family: 'Futura Light';
    height: 80vh;
    margin-top: 32px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 999px) {
        & {
            height: 90vh;
            margin-top: 32px 0px;
        }
    }
`;

const Logos = styled.div`
    img {
        max-width: ${props => props.mode.feat === true ? "none" : "200px"};
        height: 160px;
        @media (max-width: 999px) {
            & {
                height: 140px;
            }
        }
    }
    margin-bottom: ${props => props.mode.feat === true ? "30px" : "20px"};
`;

const Title = styled.div`
    max-width: 68%;
    font-size: 36px;
    line-height: 110%;
    @media (max-width: 999px) {
        & {
            max-width: 100%;
            font-size: 19px;
        }
    }
`;

const Subtitle = styled.div`
    line-height: 140%;
    @media (max-width: 999px) {
        & {
            font-size: 18px;
        }
    }
    margin-top: ${props => props.mode.feat===true? '30px' :'0'};
    margin-bottom: ${props => props.mode.feat===true? '20px' :'0'};
`;

const HowItWorks = styled.div`
    width: 480px;
    margin: 0px auto;
    line-height: 110%;
    text-align: left;
    font-size: 22px;
    ul {
        list-style: square;
        margin-left: 20px;
        font-size: 20px;
    }
    @media (max-width: 999px) {
        & {
            width: 100%;
            font-size: 18px;
        }
        ul {
            font-size: 16px;
        }
    }
`;

const CreateAccountButton = styled(Link)`
    font-family: 'Futura';
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    width: 100%;
    max-width: 420px;
    background-color: white;
    margin-top: ${props => props.mode.feat===true? '20px' :'0'};
    margin-bottom: ${props => props.mode.feat===true? '10px' :'0'};
    padding: 16px;
    border-radius: 10px;
    line-height: 24px;
    text-decoration: none;
`;

const LoginButton = styled(Link)`
    font-family: 'Futura';
    color: white;
    margin-bottom: ${props => props.mode && props.mode.feat === true ? "20px" : "0"};
`;

class ShowcaseContainer extends React.Component {
    render() {

        const { mode, isLoggedIn } = this.props;
        if (isLoggedIn) {
            return <Redirect to="/home" />;
        }

        return (
            <Container mode={mode}>
                {mode.feat === false && (
                    <Contact>
                        <ContactPhone href="tel:+33186767046">01.86.76.70.46</ContactPhone>
                        <ContactMail href="mailto:contact@lesripeurs.com">contact@lesripeurs.com</ContactMail>
                    </Contact>
                )}
                <Main mode={mode}>
                    {mode.feat === true ? (
                        <Logos mode={mode}>
                            <img src={mode.logo.light} alt={`Les Ripeurs logo, en partenariat avec ${mode.name}`} />
                        </Logos>
                    ) : (
                        <Logos mode={mode}>
                            <img src={(require("./../assets/img/logo-ripeurs-white.svg"))} alt="Logo Les Ripeurs" />
                        </Logos>
                    )}
                    {mode.feat === true ? (
                        <Title>
                            Évacuez tous vos déchets de chantier rapidement, aux meilleurs prix.
                        </Title>
                    ) : (
                        <Title>
                            Évacuation et revalorisation de vos déchets
                        </Title>
                    )}
                    <Subtitle mode={mode}>
                        <u>bennes</u> • <u>sacs à gravats</u> • <u>big bags</u><br/>
                        <u>amiante</u> • <u>déchêts dangereux</u><br/>
                        <u>gros volumes</u>
                    </Subtitle>
                    {mode.feat === true ? (
                        <>
                            <CreateAccountButton to="/signup" mode={mode}>
                                Je découvre tous les 
                                services d’évacuation
                            </CreateAccountButton>
                            <LoginButton mode={mode} to="/login">Me connecter</LoginButton>
                            <ContactMode>
                                <ContactPhone href="tel:+33186767046">01.86.76.70.46</ContactPhone>
                                <ContactMail href="mailto:contact@lesripeurs.com">contact@lesripeurs.com</ContactMail>
                            </ContactMode>
                        </>
                    ):(
                        <>
                            <CreateAccountButton to="/signup" mode={mode}>
                                Créer mon compte
                            </CreateAccountButton>
                            <LoginButton to="/login">Me connecter</LoginButton>
                        </>
                    )}
                    
                </Main>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        mode: state.mainReducer.mode,
        isLoggedIn: state.userReducer.isLoggedIn,
    };
};

export default connect(mapStateToProps)(ShowcaseContainer);