import {
	DISMISS_ERROR,
	AUTO_DISMISS
} from './../constants/action-types';

export const requestAlert = (type, message, messageSecondary=undefined) => ({
	type,
	payload: {
		message,
		messageSecondary,
	},
});

export const dismissError = () => ({
	type: DISMISS_ERROR,
});

export const autoDismiss = () => ({
	type: AUTO_DISMISS,
})
