import { combineReducers } from 'redux';
import errorsReducer from './errorsReducer';
import actionStateReducer from './actionStateReducer';
import userReducer from './userReducer';
import orderReducer from './orderReducer';
import initializationReducer from './initializationReducer';
import mainReducer from './mainReducer';

const globalReducer = combineReducers({
    errorsReducer,
    actionStateReducer,
    userReducer,
    orderReducer,
    initializationReducer,
    mainReducer,
});

export default globalReducer;
