import lsp from '../services/localStoragePower';

export const storeUtms = async (search) => {
    try {
        let utms = search.substr(1).split('&').filter((param) => {
            let [key] = param.split('=');
            if(key === 'utm_source'
                || key === 'utm_medium'
                || key === 'utm_campaign'
                || key === 'utm_content'
                || key === 'utm_term') {
                return true;
            }
            return false;
        });
        if(utms && utms.length > 0) {
            utms = Object.assign({}, ...utms.map((utm) => { 
                let [key, value] = utm.split('='); 
                return {
                    [key]: decodeURIComponent(value)
                }
            }));
            lsp.setItem('ripeurs_utms', JSON.stringify(utms));
        }        
    } catch (e) {
        throw e;
    }
};

export const loadUtms = async () => {
    try {
        const utms = await lsp.getItem('ripeurs_utms');
		return utms ? JSON.parse(utms) : {};
    } catch (e) {
        throw e;
    }
};