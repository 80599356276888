import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import lsp from '../services/localStoragePower';
import rootSaga from '../sagas';
import globalReducer from '../reducers';
import { APP_LOCK_ADD, GET_INSTALLATION, SET_MODE, LOGIN_TOKEN, RESET_STATE_LIGHT } from '../constants/action-types';


// const voucherDetailsBlacklistFilter = createBlacklistFilter(
// 	'orderReducer',
// 	['voucherDetails']
// );

// const priceBlacklistFilter = createBlacklistFilter(
// 	'orderReducer',
// 	['orderDetails.beforeVoucherPrice']
// )

/** Redux Persist */
const persistConfig = {
	key: 'root',
	storage: lsp,
	whitelist: ['userReducer', 'orderReducer', 'initializationReducer'],
	blacklist: ['mainReducer'],
	// transforms: [voucherDetailsBlacklistFilter, priceBlacklistFilter],
};

const persistedReducer = persistReducer(persistConfig, globalReducer);

/** Saga Middleware */
const sagaMiddleware = createSagaMiddleware();

/** Create middlewares for redux */
const middlewares = applyMiddleware(sagaMiddleware);

/** Create redux store */
export const store = createStore(
	persistedReducer,
	composeWithDevTools(middlewares),
);

export const persistor = persistStore(store);

/** Run saga watchers */
sagaMiddleware.run(rootSaga);

/** Get config values */
store.dispatch({
	type: APP_LOCK_ADD,
});
store.dispatch({
	type: GET_INSTALLATION,
});


const locationSearch = ''+window.location.search;

const tokenMatch = (locationSearch).match(/(\?|&)token=([^&]+)/);
const token = (tokenMatch!==null?tokenMatch[2]:null);
if(token !== null) {
	store.dispatch({
		type: APP_LOCK_ADD,
	});
	store.dispatch({
		type: LOGIN_TOKEN,
		payload: {
			token: token,
			appLock: true,
		},
	});
}

const resetMatch = (locationSearch).match(/(\?|&)reset=([^&]+)/);
const reset = (resetMatch!==null?resetMatch[2]:null);
if(reset !== null) {
	let resets = reset.split(',');
	if(resets.includes('flow') === true) {
		setTimeout(() => {
			store.dispatch({
				type: RESET_STATE_LIGHT,
			});
		}, 1);
	}
}

const modeMatch = locationSearch.match(/(\?|&)mode=([^&]+)/);
const mode = (modeMatch!==null?modeMatch[2]:null);
if(mode === 'native') {
	window.RIPEURS_MODE = mode;
	document.querySelector('body').classList.add('rip-mode-'+mode);
	store.dispatch({
		type: SET_MODE,
		payload: {
			feat: false,
			key: mode,
			name: null,
			logo: {},
			colors: {},
			options: {
				uiHeader: false,
				uiFooter: false,
				uiMessage: false,
			},
			texts: {},
		},
	});
} else if(mode === 'pdb') {
	window.RIPEURS_MODE = mode;
	document.querySelector('body').classList.add('rip-mode-'+mode);
	store.dispatch({
		type: SET_MODE,
		payload: {
			feat: true,
			key: mode,
			name: 'Plateforme du bâtiment',
			logo: {
				light: require('../assets/img/mode-pdb-logo-ripeurs-white.png'),
				main: require('../assets/img/mode-pdb-logo-ripeurs-orange.png'),
			},
			colors: {
				main: '#ff7c23',
				lightMain: '#fff8f3',
				headerBg: '#f5f5f5',
				homeBg: null,
			},
			options: {
				signupReferral: 'Plateforme du bâtiment',
				compact: true,
			},
			texts: {
				legal: `Les Ripeurs est un service partenaire proposé par La Plateforme du Bâtiment.`,
			},
		},
	});

	let appHeight = 0;
	setInterval(() => {
		const appHeightNew = document.querySelector('#root').clientHeight;
		if(appHeightNew > appHeight+2
		|| appHeightNew < appHeight-2) {
			appHeight = appHeightNew;
			window.parent.postMessage({
				type: 'IFRAME_SET_HEIGHT',
				data: {
					height: appHeight+'px',
				},
			}, '*');
		}
	}, 500);
} else if(mode === 'cedeo') {
	window.RIPEURS_MODE = mode;
	document.querySelector('body').classList.add('rip-mode-'+mode);
	store.dispatch({
		type: SET_MODE,
		payload: {
			feat: true,
			key: mode,
			name: 'CEDEO',
			logo: {
				light: require('../assets/img/mode-cedeo-logo-ripeurs-white.png'),
				main: require('../assets/img/mode-cedeo-logo-ripeurs-blue.png'),
			},
			colors: {
				main: '#294178',
				lightMain: '#e1e9fc',
				headerBg: '#ffffff',
				homeBg: '#294178',
			},
			options: {
				signupReferral: 'Cedeo',
				compact: true,
			},
			texts: {
				legal: `Les Ripeurs est un service partenaire proposé par CEDEO`,
			},
		},
	});
} else if(mode === 'rexel') {
	window.RIPEURS_MODE = mode;
	document.querySelector('body').classList.add('rip-mode-'+mode);
	store.dispatch({
		type: SET_MODE,
		payload: {
			feat: true,
			key: mode,
			name: 'Rexel',
			logo: {
				light: require('../assets/img/mode-rexel-logo-ripeurs-white.png'),
				main: require('../assets/img/mode-rexel-logo-ripeurs-blue.png'),
			},
			colors: {
				main: '#2E458B',
				lightMain: '#e1e9fc',
				headerBg: '#ffffff',
				homeBg: '#2E458B',
			},
			options: {
				signupReferral: 'Rexel',
				compact: false,
			},
			texts: {
				legal: `Les Ripeurs est un service partenaire proposé par Rexel`,
			},
		},
	});
} else {
	window.RIPEURS_MODE = null;
}
