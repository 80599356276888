import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.mode.feat===true?props.mode.colors.lightMain:'#fff8f3'};
    border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#FFD3B0'};
    border-radius: 12px;
    margin-top: 45px;
    margin-bottom: 40px;
    height: 90px;
`;

const LabelContainer = styled.div`
    padding-left: 45px;
    @media (max-width: 999px) {
        & {
            padding-left: 12px;
        }
    }
`;

const Label = styled.div`
    font-size: 20px;
    color: #404040;
    padding-bottom: 5px;
`;

const Info = styled.div`
    font-size: 14px;
    color: #8C97A1;
`;

const PriceTag = styled.div`
    font-family: 'Futura Bold';
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    font-size: 24px;
    min-width: 105px;
    padding-right: 45px;
    @media (max-width: 999px) {
        & {
            padding-right: 12px;
        }
    }
`;

const Price = ({
    price,
    beforeVoucherPrice,
    flowtype,
    mode,
}) => {
    return (
        <Container mode={mode}>
            {flowtype==='truck'?(
                <LabelContainer>
                    <Label>Estimation HT</Label>
                    <Info>Facturation finale selon les quantités constatées sur place</Info>
                </LabelContainer>
            ):(
                <LabelContainer>
                    <Label>Tarif HT</Label>
                </LabelContainer>
            )}
            {beforeVoucherPrice && (
                <div>Ancien prix: <span style={{ textDecoration: "line-through" }}>{beforeVoucherPrice} €</span></div>
            )}
            <PriceTag mode={mode} >{price} €</PriceTag>
        </Container>
    )
}

export default Price;