import {
    CREATE_ORDER,
    SET_ACTION_STATE,
    CREATE_CARD,
    CREATE_IBAN
} from './../constants/action-types';

export const createOrderLoading = (value) => ({
    type: SET_ACTION_STATE,
    payload: {
        variable: 'orderCreationLoading',
        value: value,
    }
})
export const createOrder = (orderData, selectedMethod, selectedMethodType, voucherDetails, isAdmin, userId) => ({
    type: CREATE_ORDER,
    payload: {
        orderData,
        selectedMethod,
        selectedMethodType,
        voucherDetails,
        isAdmin,
        userId,
    }
})

export const createCardLoading = (value) => ({
    type: SET_ACTION_STATE,
    payload: {
        variable: 'cardCreationLoading',
        value: value,
    }
})
export const createCard = (cardData) => ({
    type: CREATE_CARD,
    payload: {
        cardData
    }
})

export const createIban = (ibanData) => ({
    type: CREATE_IBAN,
    payload: {
        ibanData
    }
})