import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
	Formik, Form, Field, ErrorMessage,
} from 'formik';
import { passwordReset, passwordResetSuccessStop } from '../actions';
import Spinner from '../components/common/Spinner';

const Container = styled.div`
    min-height: ${props => props.mode.feat === true &&  props.mode.options.compact === true ? "1px" : "100vh"};
	display: flex;
	justify-content: space-between;
    @media (max-width: 999px) {
		& {
			flex-direction: column;
		}
	}
`;

const HelpContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	max-width: 30%;
    ${props => props.mode.feat===true&&props.mode.colors.homeBg?`
        background-color: ${props.mode.colors.homeBg};
    `:`
		background-image: url("${(require("./../assets/img/onboarding-bg.svg"))}");
    `}
	background-size: cover;
    @media (max-width: 999px) {
		& {
			flex: 1;
			max-width: 100%;
			min-height: 260px;
		}
	}
`;

const ContainerForm = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: start;
	align-items: center;
`;
const Space = styled.div`
	flex: 1;
`;

const HelpTextContainer = styled.div`
	padding: 8px;
	text-align: center;
`;

const HelpTitle = styled.div`
	font-size: 24px;
	font-family: 'Futura Bold';
	color: white;
	line-height: 110%;
	padding-bottom: 42px;
`;

const HelpNumber = styled.a`
	display: block;
	text-decoration: none;
	font-size: 18px;
	color: white;
	padding-bottom: 8px;
`;

const HelpEmail = styled.a`
	display: block;
	text-decoration: none;
	font-size: 18px;
	color: white;
`;

const Logos = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
	align-self: center;

    img {
		align-self: center;
		margin: ${props => props.mode.feat===false?"32px":"16px"} 8px;
		width: 128px;
    }
`;

const CustomForm = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	padding: 0px 16px 60px 16px;
`;

const Title = styled.div`
	font-family: 'Futura Bold';
	font-size: 24px;
	line-height: 110%;
	margin-bottom: 32px;
`;

const FieldsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const FieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	width: 100%;
`;

const CustomLabel = styled.label`
	font-size: 13px;
`;

const CustomField = styled(Field)`
	width: 310px;
	border: 1px solid #EEEEEE;
	border-radius: 5px;
	width: 100%;
	height: 40px;
	padding-left: 16px;
`;

const SubmitButton = styled.button`
	font-family: 'Futura';
	color: white;
	background-color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	cursor: pointer;
	font-size: 16px;
	line-height: 16px;
	margin-bottom: 16px;
	width: 100%;
	max-width: 640px;
	height: 45px;
	border: none;
	border-radius: 5px;
`;

const SignupButtonContainer = styled.div`
	text-align: center;
	text-decoration: underline;
`;

const SignupButton = styled(Link)`
	color: #546476;
`;

const CustomError = styled(ErrorMessage)`
	font-family: "Futura Italic";
	color: #cc0000;
	margin-top: 5px;
    font-size: 14px;
`;

class PasswordResetContainer extends React.Component {
	render() {
		const {
			isLoggedIn,
			passwordReset,
			passwordResetLoading,
			passwordResetSuccess,
			passwordResetSuccessStop,
			mode,
		} = this.props;

		if (isLoggedIn) {
			return <Redirect to="/home" />;
		} else if(passwordResetSuccess) {
			passwordResetSuccessStop();
			return <Redirect to="/login" />;
		}

		return (
			<Container mode={mode}>
				<ContainerForm>
                    {mode.feat === true ? (
                        <Logos mode={mode}>
                            <img src={mode.logo.main} alt={`Les Ripeurs logo, en partenariat avec ${mode.name}`} />
                        </Logos>
                    ) : (
                        <Logos mode={mode}>
							<img src={(require("./../assets/img/logo-ripeurs-orange.svg"))} alt="logo-ripeurs" />
                        </Logos>
                    )}
					<Space></Space>
					<Formik
						initialValues={{ email: '' }}
						validate={values => {
							let errors = {};
							if (!values.email) {
								errors.email = 'Champ obligatoire';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = 'Adresse email invalide';
							}
							return errors;
						}}
						onSubmit={(values, actions) => {
							passwordReset(values);
							actions.setSubmitting(false);
						}}
					>
						{({ isValid, isSubmitting }) => (
							<CustomForm>
								<Title>Mot de passe oublié</Title>

								<FieldsContainer>
									<FieldContainer>
										<CustomLabel htmlFor="email">Adresse e-mail</CustomLabel>
										<CustomField type="email" name="email" />
										<CustomError name="email" component="div" />
									</FieldContainer>
								</FieldsContainer>

								{passwordResetLoading ? (
									<Spinner mode={mode} />
								) : (
										<SubmitButton type="submit" mode={mode}>
											M'envoyer un mail de changement de mot de passe
										</SubmitButton>
									)}

								<SignupButtonContainer>
									<SignupButton to="/login">
										Retour à la page de connexion
									</SignupButton>
								</SignupButtonContainer>
							</CustomForm>
						)}
					</Formik>
					<Space></Space>
				</ContainerForm>
				<HelpContainer mode={mode}>
					<HelpTextContainer>
						<HelpTitle>
							Vous avez une question ?
						</HelpTitle>
						<HelpNumber href="tel:+33186767046">
							01.86.76.70.46
						</HelpNumber>
						<HelpEmail href="mailto:contact@lesripeurs.com">
							contact@lesripeurs.com
						</HelpEmail>
					</HelpTextContainer>
				</HelpContainer>

			</Container>
		);
	}
}

const mapStateToProps = state => ({
	passwordResetLoading: state.actionStateReducer.passwordResetLoading,
	passwordResetSuccess: state.actionStateReducer.passwordResetSuccess,
	isLoggedIn: state.userReducer.isLoggedIn,
	mode: state.mainReducer.mode,
});

const mapDispatchToProps = {
	passwordReset,
	passwordResetSuccessStop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer);
