import {
    SIGNUP,
    LOGIN,
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS_STOP,
    LOGOUT,
} from '../constants/action-types';

export const signUp = user => ({
    type: SIGNUP,
    payload: user,
});

export const login = user => ({
    type: LOGIN,
    payload: user,
});
export const passwordReset = user => ({
    type: PASSWORD_RESET,
    payload: user,
});
export const passwordResetSuccessStop = () => ({
    type: PASSWORD_RESET_SUCCESS_STOP,
    payload: {},
});

export const logout = () => ({
    type: LOGOUT,
});
