class LocalStoragePower {
	#mode = 'LOCALSTORAGE';
	#data = {};

	constructor() {
		try {
			let key = 'LocalStoragePower_'+Math.round(Math.random()*1e12);
			localStorage.setItem(key, 'OK');
			localStorage.getItem(key);
			localStorage.removeItem(key);
		} catch(e) {
			this.#mode = 'INMEMORYSTORAGE';
		}
	}

	async getItem(key) {
		if(this.#mode === 'LOCALSTORAGE') {
			return localStorage.getItem(key);
		} else {
			return this.#data[key];
		}
	}
	async setItem(key, value) {
		if(this.#mode === 'LOCALSTORAGE') {
			localStorage.setItem(key, value);
		} else {
			this.#data[key] = value;
		}
	}
	async removeItem(key) {
		if(this.#mode === 'LOCALSTORAGE') {
			localStorage.removeItem(key);
		} else {
			this.#data[key] = undefined;
		}
	}
	async clear() {
		if(this.#mode === 'LOCALSTORAGE') {
			localStorage.clear();
		} else {
			this.#data = {};
		}
	}
}

export default new LocalStoragePower();
