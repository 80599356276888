import {
    SET_MODE,
    APP_LOCK_ADD,
    APP_LOCK_SUB,
} from '../constants/action-types';

const initialState = {
    mode: {
        feat: false,
        key: null,
        name: null,
        logo: null,
        colors: {},
        options: {},
        texts: {},
    },
    appLock: 0,
}

function mainReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MODE:
			(window.dataLayer||[]).push({
				event: 'rip_mode',
				mode: action.payload.name,
			});
            return {
                ...state,
                mode: action.payload,
            };
        case APP_LOCK_ADD:
            return {
                ...state,
                appLock: state.appLock+1,
            };
        case APP_LOCK_SUB:
            return {
                ...state,
                appLock: Math.max(state.appLock-1, 0),
            };
        default:
            return state;
    }
}

export default mainReducer;