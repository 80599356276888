import React from "react";
import styled from "styled-components";

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 35px;
  height: 35px;
  align-self: center;
  margin: 15px 0;

  & .path {
    stroke: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner = (props) => (
  <StyledSpinner mode={props.mode} viewBox="0 0 50 50">
    <circle
      stroke={props.mode.feat===true?props.mode.colors.lightMain:"#ffd8b7"}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    />
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    />
  </StyledSpinner>
);

export default Spinner;
