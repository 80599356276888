import {
    LOGOUT,
    RESET_STATE,
    RESET_STATE_LIGHT,
    SET_CURRENT_FLOW,
    VALIDATE_FLOW,
    GET_PRICE_SUCCESS,
    VALIDATE_VOUCHER_SUCCESS,
    VALIDATE_VOUCHER_SUCCESS_AMOUNT,
    VALIDATE_VOUCHER_SUCCESS_PERCENT,
    VALIDATE_VOUCHER_FAILURE,
    USER_BAD_SUBMIT,
    USER_GOOD_SUBMIT,
    ORDER_SUCCESS,
    GET_ORDERS_LOADING,
    GET_ORDERS_SUCCESS,
} from '../constants/action-types';

const initialState = {
    flow: 'noflow',
    truckOrderDetails: {},
    bigbagOrderDetails: {},
    dumpsterOrderDetails: {},
    craneTruckOrderDetails: {},
    voucherDetails: undefined,
    userBadSubmit: false,
    badSubmitErrors: [],
    isOrderSuccessful: false,
    orders: null,
    lastOrderId: '',
}

function orderReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_STATE:
            return {
                ...initialState,
                lastOrderId: state.lastOrderId
            };
        case RESET_STATE_LIGHT:
            return {
                ...initialState,
                flow: state.flow,
            };
        case LOGOUT:
			(window.dataLayer||[]).push({
				event: 'rip_user_logout',
			});
            return initialState;
        case SET_CURRENT_FLOW:
			(window.dataLayer||[]).push({
				event: 'rip_flow_select',
				flow: action.payload.flow,
			});
            return {
                ...state,
                flow: action.payload.flow,
            };
        case VALIDATE_FLOW:
            switch (state.flow) {
                case "truck":
                    return {
                        ...state,
                        truckOrderDetails: action.payload.orderData
                    }
                case "bigbag":
                    return {
                        ...state,
                        bigbagOrderDetails: action.payload.orderData
                    }
                case "dumpster":
                    return {
                        ...state,
                        dumpsterOrderDetails: action.payload.orderData
                    }
                case "craneTruck":
                    return {
                        ...state,
                        craneTruckOrderDetails: action.payload.orderData
                    }
                default:
                    return state
            }
        case GET_PRICE_SUCCESS:
            (window.dataLayer||[]).push({
                event: 'rip_order_get-price',
                flow: state.flow,
                price: action.payload.price,
            });
            switch (state.flow) {
                case "truck":
                    return {
                        ...state,
                        truckOrderDetails: {
                            ...state.truckOrderDetails,
                            price: action.payload.price
                        }
                    }
                case "bigbag":
                    return {
                        ...state,
                        bigbagOrderDetails: {
                            ...state.bigbagOrderDetails,
                            price: action.payload.price
                        }
                    }
                case "dumpster":
                    return {
                        ...state,
                        dumpsterOrderDetails: {
                            ...state.dumpsterOrderDetails,
                            price: action.payload.price
                        }
                    }
                case "craneTruck":
                    return {
                        ...state,
                        craneTruckOrderDetails: {
                            ...state.craneTruckOrderDetails,
                            price: action.payload.price,
                            priceDetails: action.payload.priceDetails,
                        }
                    }
                default:
                    return state
            }

        case VALIDATE_VOUCHER_SUCCESS:
            (window.dataLayer||[]).push({
                event: 'rip_order_coupon',
                coupon: action.payload.code,
            });
            return {
                ...state,
                voucherDetails: action.payload
            }
        case VALIDATE_VOUCHER_SUCCESS_AMOUNT:
            switch (state.flow) {
                case "truck":
                    return {
                        ...state,
                        truckOrderDetails: {
                            ...state.truckOrderDetails,
                            beforeVoucherPrice: state.truckOrderDetails.price,
                            price: state.truckOrderDetails.price - action.payload
                        }
                    }
                case "bigbag":
                    return {
                        ...state,
                        bigbagOrderDetails: {
                            ...state.bigbagOrderDetails,
                            beforeVoucherPrice: state.truckOrderDetails.price,
                            price: state.bigbagOrderDetails.price - action.payload
                        }
                    }
                case "dumpster":
                    return {
                        ...state,
                        dumpsterOrderDetails: {
                            ...state.dumpsterOrderDetails,
                            beforeVoucherPrice: state.truckOrderDetails.price,
                            price: state.dumpsterOrderDetails.price - action.payload
                        }
                    }
                case "craneTruck":
                    return {
                        ...state,
                        craneTruckOrderDetails: {
                            ...state.craneTruckOrderDetails,
                            beforeVoucherPrice: state.truckOrderDetails.price,
                            price: state.craneTruckOrderDetails.price - action.payload
                        }
                    }
                default:
                    return state
            }
        case VALIDATE_VOUCHER_SUCCESS_PERCENT:
            switch (state.flow) {
                case "truck":
                    return {
                        ...state,
                        truckOrderDetails: {
                            ...state.truckOrderDetails,
                            beforeVoucherPrice: state.truckOrderDetails.price,
                            price: state.truckOrderDetails.price - (state.truckOrderDetails.price * action.payload / 100)
                        }
                    }
                case "bigbag":
                    return {
                        ...state,
                        bigbagOrderDetails: {
                            ...state.bigbagOrderDetails,
                            beforeVoucherPrice: state.bigbagOrderDetails.price,
                            price: state.bigbagOrderDetails.price - (state.bigbagOrderDetails.price * action.payload / 100)
                        }
                    }
                case "dumpster":
                    return {
                        ...state,
                        dumpsterOrderDetails: {
                            ...state.dumpsterOrderDetails,
                            beforeVoucherPrice: state.dumpsterOrderDetails.price,
                            price: state.dumpsterOrderDetails.price - (state.dumpsterOrderDetails.price * action.payload / 100)
                        }
                    }
                case "craneTruck":
                    return {
                        ...state,
                        craneTruckOrderDetails: {
                            ...state.craneTruckOrderDetails,
                            beforeVoucherPrice: state.craneTruckOrderDetails.price,
                            price: state.craneTruckOrderDetails.price - (state.craneTruckOrderDetails.price * action.payload / 100)
                        }
                    }
                default:
                    return state
            }
        case VALIDATE_VOUCHER_FAILURE:
            return {
                ...state,
                voucherDetails: action.payload
            }
        case USER_BAD_SUBMIT:
            return {
                ...state,
                userBadSubmit: true,
                badSubmitErrors: action.payload
            }
        case USER_GOOD_SUBMIT:
            return {
                ...state,
                userBadSubmit: false,
                badSubmitErrors: []
            }
        case ORDER_SUCCESS:
            return {
                ...state,
                lastOrderId: action.payload.orderId,
                isOrderSuccessful: true
            }

        case GET_ORDERS_LOADING:
            return {
                ...state,
                orders: null,
            }
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders,
            }

        default:
            return state;
    }
}

export default orderReducer;