/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import Content from './../components/common/Content';
import FlowButtonsContainer from './FlowButtonsContainer';
import WasteSummary from '../components/summary/WasteSummary';
import AdditionnalDataSummary from '../components/summary/AdditionnalDataSummary';
import Price from '../components/summary/Price';
import Voucher from '../components/summary/Voucher';
import PickUpConditions from '../components/summary/PickUpConditions';
import AcceptCGV from '../components/summary/AcceptCGV';
import PaymentSelector from '../components/summary/PaymentSelector';
import AddPaymentMethod from '../components/checkout/AddPaymentMethod';
import ErrorsPopup from '../components/common/ErrorsPopup';
import "@reach/dialog/styles.css"

import {
    getPrice,
    validateVoucher,
    getCardsAndIbans,
    createCardLoading,
    createOrderLoading,
    createOrder,
    createCard,
    createIban,
    userBadSubmit,
    requestAlert,
} from '../actions';
import { cardV2Setup, cardV2TokenToPm, mapOrder } from '../services/api';
import Spinner from '../components/common/Spinner';
import { ENVIRONMENT } from '../config';

import { parseJwt } from '../@helpers/jwt';

const summarySchema = (props) => Yup.lazy(obj => Yup.object(
    _.mapValues(obj, (value, key) => {
        if (key === "conditions") {
            return Yup.array().of(
                Yup.object().shape({
                    checked: Yup.bool().oneOf([true], "Toutes les conditions d'évacuation doivent être acceptées")
                })
            )
        } else if (key === "cgv") {
            return Yup.bool().oneOf([true], "Veuillez accepter les conditions générales de ventes (CGV) et les conditions générales d'utilisation (CGU)")
        } else if (key === "paymentMethod") {
            return Yup.string().required("Sélectionnez une méthode de paiement")
        } else if (key === "address") {
            return Yup.string().required("Entrez une adresse d'évacuation")
        } else if (key === "postalCode") {
            return Yup.string().required("Le code postal de l'adresse d'évacuation est incorrect")
        } else if (key === "timeSlot") {
            return Yup.string().required("Sélectionnez un créneau horaire")
        } else if (key === "access") {
            if (props.flowtype === "truck" || "dumpster") {
                return Yup.string().required("Sélectionnez un type d'accès aux déchets")
            } else {
                return Yup.string()
            }
        } else if (key === "floor") {
            return Yup.number().when('access', { is: 'upstairs', then: Yup.number().required("Sélectionnez un étage d'accès aux déchets").min(1, "Entrez un étage supérieur à 0").max(10, "L'étage doit être inférieur ou égal à 10") })
        }
    })
))

const MainContainer = styled(Content)``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const PriceContainer = styled.div`
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    margin-top: 20px;
`;

const CustomForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const PaymentContainer = styled.div`
    margin-bottom: 45px;
`;

const PaymentFormContainer = styled.div`
    border-radius: 4px;
    border: ${props => props.userbadsubmit ? "2px solid #cc0000" : "none"};
    padding-top: 10px;
    padding-left: 10px;
`;

const SubmitButtonContainer = styled.div`
    align-self: center;
`;

const QuotesButtonContainer = styled.div`
    align-self: center;
`;

const SubmitButton = styled.button`
    font-size: 18px;
    font-family: 'Futura';
    height: 50px;
    width: 250px;
    background-color: ${props => props.disabled ? "#8C8D8C" : (props.mode.feat === true ? props.mode.colors.main : '#ff7d12')};
    color: white;
    border-radius: 5px;
    align-self: center;
    margin-top: 50px;
    margin-bottom: 60px;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

const GenerateQuotesButton = styled.button`
    font-size: 18px;
    font-family: 'Futura';
    height: 50px;
    width: 250px;
    background-color: ${props => props.disabled ? "#8C8D8C" : '#FF1D12'};
    color: white;
    border-radius: 5px;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

class SummaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conditions: [],
            isVoucherDisplayed: false,
            isCardDisplayed: false,
            isIbanDisplayed: false,
            isLoading: true,
            ibanData: {
                name: "",
                owner: "",
                number: "",
                address: ""
            },
            isQuotesGenerating: false,
        }
    }

    componentDidMount() {
        const {
            orderDetails,
            getPrice,
            getCardsAndIbans,
            flowtype
        } = this.props;

        if (orderDetails) {
            getCardsAndIbans();

            this.getPickUpConditions();

            const common = {
                pickUpDate: orderDetails.timeSlot,
                location: orderDetails.address,
                postalCode: orderDetails.postalCode,
                floor: (orderDetails.access === "upstairs" ? orderDetails.floor : 0),
                ground: (orderDetails.access === "groundFloor" ? true : false),
                flowtype: flowtype,
            }

            switch(flowtype) {
                case 'truck':
                    getPrice({
                        ...common,
                        rubblesBags: orderDetails.rubbleBag || 0,
                        mixBags: orderDetails.mixBag || 0,
                        plasterBags: orderDetails.plasterBag || 0,
                        ironBags: orderDetails.ironBag || 0,
                        woodBags: orderDetails.woodBag || 0,
                        vegetalBags: orderDetails.vegetalBag || 0,
                        bulkyItems: orderDetails.bulky || 0,
                        mixM3: orderDetails.mixM3 || 0,
                        plasterM3: orderDetails.plasterM3 || 0,
                        woodM3: orderDetails.woodM3 || 0,
                        cartonM3: orderDetails.cardboardM3 || 0,
                        ironM3: orderDetails.ironM3 || 0,
                        vegetalM3: orderDetails.vegetalM3 || 0,
                        slateM3: orderDetails.slateM3 || 0,
                    });
                    break;
                case 'bigbag':
                    getPrice({
                        ...common,
                        bigBags: orderDetails.bigBagNumber || 0,
                        bigBagsRubble: orderDetails.bigBagRubbleNumber || 0,
                    });
                    break;
                case 'dumpster':
                    getPrice({
                        ...common,
                        dumpsterType: "chain",
                        dumpsterDrop: "depot",
                        dumpsterWasteType: orderDetails.wasteType,
                        dumpsterValue: orderDetails.wasteSize,
                    });
                    break;
                case 'craneTruck':
                    getPrice({
                        ...common,
                        craneTruck: {
                            mixed: orderDetails.mixM3,
                            rubble: orderDetails.rubbleM3,
                            dirt: orderDetails.dirtM3,
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    getWasteBorderColor = (type) => {
        switch (type) {
            case "rubble":
                return "#EEB054";
            case "mixed":
                return "#8C8D8C";
            case "wood":
                return "#7294EA";
            case "furniture":
                return "#59BDBC";
            case "vegetal":
                return "#7CCF6B";
            case "iron":
                return "#FA6684";
            case "dirt":
                return "#795548";
            default:
                return "#ff7d12";
        }
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.props && this.props.flowtype !== prevprops.flowtype) {
            this.getPickUpConditions();
        }
    }

    getPickUpConditions() {
        const { pickUpConditions, flowtype } = this.props;
        const newConditions = [];

        if (flowtype === "bigbag") {
            pickUpConditions.bigBag.forEach((value, index) => {
                newConditions[index] = {
                    name: `condition_${index}`,
                    label: value,
                    checked: false
                };
            })
        } else {
            pickUpConditions[flowtype].forEach((value, index) => {
                newConditions[index] = {
                    name: `condition_${index}`,
                    label: value,
                    checked: false
                };
            })
        }

        return this.setState({ conditions: newConditions })
    }

    displayVoucher = () => {
        this.setState({ isVoucherDisplayed: true });
    }

    handleVoucherValidation = (voucher) => {
        const { validateVoucher, userId } = this.props;
        validateVoucher(voucher, userId);
    }

    displayCard = () => {
        this.setState(prevState => ({ isCardDisplayed: !prevState.isCardDisplayed }));
    }

    displayIban = () => {
        this.setState(prevState => ({ isIbanDisplayed: !prevState.isIbanDisplayed }));
    }

    selectDefaultMethod = (cards, ibans) => {
        let creationDates = [];

        if ((Array.isArray(cards) && cards.length) || (Array.isArray(ibans) && ibans.length)) {
            if (cards) {
                cards.forEach(card => {
                    if(card.status !== 'EXPIRED') {
                        creationDates.push(card);
                    }
                });
            }

            if (ibans) {
                ibans.forEach(iban => {
                    if(iban.status !== 'EXPIRED') {
                        creationDates.push(iban);
                    }
                });
            }

            const lastCreated = creationDates.sort((a, b) => {
                return (a.createdAt < b.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0)
            });

            if(creationDates.length === 0) {
                return '';
            }
            return lastCreated[0].id.toString();
        } else {
            return (cards && cards[0] && cards[0].id) || (ibans && ibans[0] && ibans[0].id.toString()) || undefined;
        }
    }

    handleIbanChange = (e) => {
        this.setState({
            ibanData: {
                ...this.state.ibanData,
                [e.target.name]: e.target.value
            }
        })
    }

    stripeResponseHandler = async (pm, si) => {
        const { userId, stripeId, createCard, requestAlert } = this.props;

        this.setState({
            isCardDisplayed: false,
        });

        if(!pm.error
        && !si.error) {
            createCard({
                last4: pm.paymentMethod.card.last4,
                brand: pm.paymentMethod.card.brand.toLowerCase(),
                expiry: `${pm.paymentMethod.card.exp_month}/${pm.paymentMethod.card.exp_year}`,
                token: si.setupIntent.id,
                createdById: userId,
                customerId: stripeId,
                stripeId: pm.paymentMethod.id,
            })
        } else {
            console.log('Stripe error :');
            console.log('paymentMethod', pm);
            console.log('setupIntent', si);
            let messageExtend = '';
            if(typeof(si.error) === 'object'
            && si.error !== null
            && si.error.message) {
                messageExtend = `ERREUR: ${si.error.message}`;
            }
            this.props.createCardLoading(false);
            // LOGS TEMPORAIRE SCA
            await fetch('https://hook.integromat.com/iyvd8yarijgsrtx92d28q2xls806ujud', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        type: "creation",
                        paymentMethod: pm.error,
                        setupIntent: si.error,
                    },
                    user: {
                        id: userId,
                    },
                    client: {
                        type: 'WEB',
                        environment: ENVIRONMENT,
                    },
                }),
            });
            requestAlert(
                'ERROR',
                `Une erreur s'est produite lors de l'ajout de votre carte bancaire. Merci de vérifier la valider de votre carte. Si le problème persiste, appelez le service client.`,
                `${messageExtend}`,
            );
        }
    }

    validateCard = async (stripeElements) => {
        if (this.props.stripe) {
            try {
                this.props.createCardLoading(true);
                
                let paymentMethodResult = await this.props.stripe.createPaymentMethod({
                    type: 'card',
                    card: stripeElements,
                });
    
                let apiSetupResult = await cardV2Setup();
    
                let cardSetupResult = await this.props.stripe.confirmCardSetup(
                    apiSetupResult.setupIntent.clientSecret,
                    {
                        payment_method: paymentMethodResult.paymentMethod.id,
                    }
                );
    
                await this.stripeResponseHandler(
                    paymentMethodResult,
                    cardSetupResult,
                );
            } catch(error) {
                await this.stripeResponseHandler({ error: true }, { error: true });
                this.props.createCardLoading(false);
            }
        } else {
            alert('Impossible de traiter votre moyen de paiement, merci de recommencer.');
            window.location.href = '/';
        }
    }

    validateIban = () => {
        const { name, owner, number, address } = this.state.ibanData;
        const { createIban } = this.props;

        createIban({
            nameCustomer: name,
            name: owner,
            iban: number,
            address: address
        })

        this.setState({
            ibanData: {
                name: "",
                owner: "",
                number: "",
                address: ""
            },
            isIbanDisplayed: false
        })
    }

    preventEnterSubmit = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    registerErrors = (errors) => {
        this.props.userBadSubmit(Object.keys(errors))
    }

    onPressSubmit = async (values) => {
        const {
            orderDetails,
            cards,
            ibans,
            voucherDetails,
            userId,
        } = this.props;
        let setupResult, cardSetupResult, tokenToPmResult;
        try {
            this.props.createOrderLoading(true);

            let selectedMethod = cards.find(el => el.id === values.paymentMethod) || ibans.find(el => el.id === parseInt(values.paymentMethod))
            let selectedMethodType = typeof selectedMethod.id === "string" ? "card" : "iban";

            if(selectedMethodType === 'card') {
                if(selectedMethod.type === 'TOKEN') {
                    setupResult = await cardV2Setup();
        
                    cardSetupResult = await this.props.stripe.confirmCardSetup(
                        setupResult.setupIntent.clientSecret,
                        {
                            payment_method: selectedMethod.stripeId,
                        }
                    );
                    tokenToPmResult = await cardV2TokenToPm(selectedMethod.id, cardSetupResult.setupIntent.id);
                    selectedMethod = tokenToPmResult.card;
                }
            }

            const isAdmin = parseJwt(this.props.jwt).scope.includes('Admin');
            this.props.createOrder(orderDetails, selectedMethod.id, selectedMethodType, voucherDetails, isAdmin, userId);

        } catch(error) {
            // LOGS TEMPORAIRE SCA
            await fetch('https://hook.integromat.com/iyvd8yarijgsrtx92d28q2xls806ujud', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        type: "conversion",
                        setupV2: setupResult,
                        setupIntent: cardSetupResult,
                        tokenToPm: tokenToPmResult,
                    },
                    user: {
                        id: userId,
                    },
                    client: {
                        type: 'WEB',
                        environment: ENVIRONMENT,
                    },
                }),
            });
        }
    }

    generateQuotes = async () => {
        if(
            this.props.orderDetails.address === ""
            || (
                this.props.orderDetails.access === ""
                && this.props.orderDetails.flowtype !== "craneTruck"
            )) {
            alert("Veuillez rentrer l'adresse et l'accès du chantier");
        } else {
            this.setState({ isQuotesGenerating: true });
            const body = mapOrder(this.props.orderDetails, null, null, (this.props.voucherDetails || {}).discount);

            fetch('https://hook.integromat.com/huqs8crhgc4vca2wrf1lfrzlgyx7q818', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.props.jwt,
                },
                body: JSON.stringify(body),
            }).then(response => response.json()).then(data => {
                if (!data.link) {
                    alert('Erreur de generation de devis: \n' + data.error && data.error.message ? data.error.message : 'erreur inconnue');
                } else {
                    
                    window.open(data.link, "_blank");
                }
                this.setState({ isQuotesGenerating: false });
            }).catch(error => {
                alert('Erreur de generation de devis: \n' + error.message);
                this.setState({ isQuotesGenerating: false });
            });
        }
    }

    render() {
        const {
            orderDetails,
            flowtype,
            voucherDetails,
            voucherLoading,
            cardsLoading,
            cards,
            ibans,
            summaryValidationLoading,
            cardCreationLoading,
            ibanCreationLoading,
            isOrderSuccessful,
            badSubmit,
            badSubmitErrors,
            mode,
            legal,
        } = this.props

        const {
            conditions,
            isVoucherDisplayed,
            isCardDisplayed,
            isIbanDisplayed,
            ibanData,
            isQuotesGenerating,
        } = this.state;

        const isAdmin = parseJwt(this.props.jwt).scope.includes('Admin');

        if (isOrderSuccessful) {
            return <Redirect to="/end" />
        } else if (flowtype === "noflow" || flowtype === undefined) {
            return <Redirect to="/home" />
        }

        return (
            <MainContainer>
                <FlowButtonsContainer showBackLink={true} />

                <Container>
                    <WasteSummary
                        mode={mode}
                        flow={flowtype}
                        orderDetails={orderDetails}
                        getColor={this.getWasteBorderColor}
                    />

                    <AdditionnalDataSummary
                        flow={flowtype}
                        orderDetails={orderDetails}
                    />

                    <PriceContainer>
                        <Price
                            mode={mode}
                            price={orderDetails.price}
                            beforeVoucherPrice={orderDetails.beforeVoucherPrice}
                            flowtype={flowtype}
                        />

                        <Voucher
                            mode={mode}
                            handleVoucherValidation={this.handleVoucherValidation}
                            voucherDetails={voucherDetails}
                            voucherLoading={voucherLoading}
                            hasAppliedVoucher={orderDetails.beforeVoucherPrice}
                            isDisplayed={isVoucherDisplayed}
                            displayVoucher={this.displayVoucher}
                        />
                    </PriceContainer>

                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            address: orderDetails.address || "",
                            postalCode: orderDetails.postalCode || "",
                            timeSlot: orderDetails.timeSlot || "",
                            access: flowtype === "bigbag" || flowtype === "craneTruck" ? "no access for bigbags or craneTrucks" : (orderDetails.access || ""),
                            floor: orderDetails.floor || "",
                            conditions: conditions || "",
                            cgv: false,
                            paymentMethod: this.selectDefaultMethod(cards, ibans) || ""
                        }}
                        validationSchema={summarySchema(this.props)}
                        onSubmit={(values, actions) => {
                            this.onPressSubmit(values);
                        }}
                    >
                        {({
                            isSubmitting, isValid, handleSubmit, values, setFieldValue, errors, validateForm
                        }) => {
                            return (
                                <CustomForm onSubmit={handleSubmit} onKeyDown={this.preventEnterSubmit}>

                                    <PaymentContainer>
                                        {cardsLoading ? (
                                            <Spinner mode={mode} />
                                        ) : (
                                                <div>
                                                    <PaymentSelector
                                                        mode={mode}
                                                        cards={cards}
                                                        ibans={ibans}
                                                        paymentMethod={values.paymentMethod}
                                                        setFieldValue={setFieldValue}
                                                    />

                                                    <PaymentFormContainer userbadsubmit={badSubmit && badSubmitErrors.indexOf("paymentMethod") > -1}>
                                                        <AddPaymentMethod
                                                            mode={mode}
                                                            method="card"
                                                            isOpened={isCardDisplayed}
                                                            triggerDisplay={this.displayCard}
                                                            handleValidation={this.validateCard}
                                                            creationLoading={cardCreationLoading}
                                                        />
                                                        <AddPaymentMethod
                                                            mode={mode}
                                                            method="iban"
                                                            isOpened={isIbanDisplayed}
                                                            triggerDisplay={this.displayIban}
                                                            iban={ibanData}
                                                            handleIbanChange={this.handleIbanChange}
                                                            handleValidation={this.validateIban}
                                                            creationLoading={ibanCreationLoading}
                                                        />
                                                    </PaymentFormContainer>

                                                </div>
                                            )}
                                    </PaymentContainer>

                                    <PickUpConditions
                                        mode={mode}
                                        values={values}
                                        userBadSubmit={badSubmit && badSubmitErrors.indexOf("conditions") > -1 && !values.conditions.every((i) => i.checked)}
                                    />

                                    <AcceptCGV
                                        mode={mode}
                                        cgv={values.cgv}
                                        setFieldValue={setFieldValue}
                                        legal={legal}
                                        userBadSubmit={badSubmit && badSubmitErrors.indexOf("cgv") > -1 && values.cgv === false}
                                    />

                                    <Field hidden type="input" name="address" value={values.address} />
                                    <Field hidden type="input" name="postalCode" value={values.postalCode} />
                                    <Field hidden type="input" name="timeSlot" value={values.timeSlot} />
                                    <Field hidden name="access" value={values.access} />
                                    {orderDetails.access === "upstairs" && (
                                        <Field hidden name="floor" value={values.floor} />
                                    )}

                                    {isValid ? (
                                        <SubmitButtonContainer>
                                            {summaryValidationLoading ? (
                                                <Spinner mode={mode} />
                                            ) : (
                                                    <SubmitButton type="submit" disabled={isSubmitting} mode={mode}>
                                                        Valider la commande
                                                    </SubmitButton>
                                                )}
                                        </SubmitButtonContainer>
                                    ) : (
                                            <>  {isAdmin ?
                                                <QuotesButtonContainer>
                                                    {isQuotesGenerating ? (
                                                        <Spinner mode={mode} />
                                                    ) : (
                                                            <GenerateQuotesButton onClick={this.generateQuotes} disabled={isSubmitting} mode={mode}>
                                                                Générer un devis
                                                            </GenerateQuotesButton>
                                                        )}
                                                </QuotesButtonContainer> : null}
                                                <ErrorsPopup errors={errors}>
                                                    {display => (
                                                        <SubmitButton type="button" onClick={() => validateForm().then(display(this.registerErrors))} mode={mode}>
                                                            Valider la commande
                                                        </SubmitButton>
                                                    )}
                                                </ErrorsPopup>
                                            </>
                                        )}
                                </CustomForm>
                            )
                        }}
                    </Formik>
                </Container>
            </MainContainer>
        )
    }
}


const mapStateToProps = (state) => {
    const baseObject = {
        flowtype: state.orderReducer.flow,
        pickUpConditions: state.initializationReducer.pickUpConditions,
        userId: _.get(state.userReducer, "user.id", null),
        user: state.userReducer.user,
        jwt: state.userReducer.token,
        voucherDetails: state.orderReducer.voucherDetails,
        voucherLoading: state.actionStateReducer.voucherLoading,
        cardsLoading: state.actionStateReducer.cardsAndIbansLoading,
        cards: state.userReducer.cards,
        ibans: state.userReducer.ibans,
        summaryValidationLoading: state.actionStateReducer.summaryValidationLoading,
        stripeId: _.get(state.userReducer, "user.stripeId", null),
        cardCreationLoading: state.actionStateReducer.cardCreationLoading,
        ibanCreationLoading: state.actionStateReducer.ibanCreationLoading,
        badSubmit: state.orderReducer.userBadSubmit,
        badSubmitErrors: state.orderReducer.badSubmitErrors,
        isOrderSuccessful: state.orderReducer.isOrderSuccessful,
        mode: state.mainReducer.mode,
        legal: state.initializationReducer.legal,
    }
    switch (state.orderReducer.flow) {
        case 'truck':
            return {
                ...baseObject,
                orderDetails: state.orderReducer.truckOrderDetails
            }
        case 'bigbag':
            return {
                ...baseObject,
                orderDetails: state.orderReducer.bigbagOrderDetails
            }
        case 'dumpster':
            return {
                ...baseObject,
                orderDetails: state.orderReducer.dumpsterOrderDetails
            }
        case 'craneTruck':
            return {
                ...baseObject,
                orderDetails: state.orderReducer.craneTruckOrderDetails
            }
        default:
            return {}
    }
};

const mapDispatchToProps = {
    getPrice,
    validateVoucher,
    getCardsAndIbans,
    createOrderLoading,
    createOrder,
    createCardLoading,
    createCard,
    createIban,
    userBadSubmit,
    requestAlert,
}

let SummaryContainerConnect = connect(mapStateToProps, mapDispatchToProps)(SummaryContainer);

const SummaryContainerConnectWithStripe = () => (
    <ElementsConsumer>
        {({stripe, elements}) => (
            <SummaryContainerConnect stripe={stripe} />
        )}
    </ElementsConsumer>
);

export default SummaryContainerConnectWithStripe;