import React from 'react';
import { Dialog } from '@reach/dialog'
import styled from 'styled-components';
import moment from 'moment';

const CustomDialog = styled(Dialog)`
    &[data-reach-dialog-content] {
        position: relative;
        width: 100%;
        max-width: 600px;
    }
`;

const Title = styled.div`
    font-size: 24px;
    text-align: center;
    line-height: 110%;
`;

const Container = styled.div`
    border: 2px solid #FF7D12;
    border-radius: 4px;
    background-color: #FFF8F3;
    margin-top: 20px;
`;

const ContainerTitle = styled.div`
    font-size: 20px;
    text-align: center;
    border-bottom: 2px solid #FF7D12;
    padding: 10px 0 5px;
    margin: 0 10px 15px;
    text-transform: uppercase;
    line-height: 110%;
`;

const DataContainer = styled.div`
    padding: 0 10px 10px;
`;

const Data = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    &:last-child {
        padding-bottom: 0;
    }
`;

const DataLabel = styled.div`
    font-family: 'Futura Bold';
    width: 93px;
`;

const DataValue = styled.div`
    width: calc(100% - 93px);
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

const Disclaimer = styled.div`
    font-size: 14px;
    color: #8C8D8C;
    text-align: justify;
    margin: 15px 0;
    &:last-child {
        margin: 15px 0 0;
    }
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0;
    &:focus {
        outline: none;
    }
`;

const ValidateButton = styled.button`
    background-color: #FF7D12;
    border: none;
    cursor: pointer;
    color: white;
    width: 100%;
    height: 50px;
    font-size: 18px;
    text-transform: uppercase;
    &:focus {
        outline: none;
    }
`;

class ConfirmPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            callback: null
        }
    }

    show = (callback) => (event) => {
        event.preventDefault()

        event = {
            ...event,
            target: { ...event.target, value: event.target.value }
        }

        this.setState({
            open: true,
            callback: () => callback(event)
        })
    }

    hide = () => this.setState({ open: false, callback: null })

    confirm = () => {
        this.state.callback()
        this.hide()
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children(this.show)}

                {this.state.open && (
                    <CustomDialog>
                        <Title>Mandat de prélèvement SEPA</Title>

                        <Container>
                            <ContainerTitle>Les Ripeurs</ContainerTitle>
                            <DataContainer>
                                <Data>
                                    <DataLabel>Créancier:</DataLabel>
                                    <DataValue>GB31ZZZSDDBARC0000007495895843</DataValue>
                                </Data>
                                <Data>
                                    <DataLabel>Adresse:</DataLabel>
                                    <DataValue>9 rue d'Alexandrie, 75002 Paris</DataValue>
                                </Data>
                                <Data>
                                    <DataLabel>Référence:</DataLabel>
                                    <DataValue>Disponible après validation</DataValue>
                                </Data>
                                <Data>
                                    <DataLabel>Date:</DataLabel>
                                    <DataValue>{moment().format("DD/MM/YYYY")}</DataValue>
                                </Data>
                            </DataContainer>
                        </Container>

                        <Container>
                            <ContainerTitle>{this.props.company}</ContainerTitle>
                            <DataContainer>
                                <Data>
                                    <DataLabel>IBAN:</DataLabel>
                                    <DataValue>{this.props.number}</DataValue>
                                </Data>
                                <Data>
                                    <DataLabel>Adresse:</DataLabel>
                                    <DataValue>{this.props.address}</DataValue>
                                </Data>
                            </DataContainer>
                        </Container>

                        <Disclaimer>
                            Votre confirmation implique que vous acceptez de recevoir une pré-notification 3 jours civils avant tout prélèvement.
                        </Disclaimer>

                        <CloseButton onClick={this.hide}>
                            <img src={(require("./../../assets/img/close-icon.svg"))} alt="close-icon" />
                        </CloseButton>
                        <ValidateButton onClick={this.confirm}>Valider le mandat</ValidateButton>

                        <Disclaimer>
                            En signant ce formulaire de mandat, vous autorisez (A) LES RIPEURS à envoyer des instructions à votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux instructions de la société LES RIPEURS. Vous bénéficiez d'un droit de remboursement par votre banque selon les conditions décrites dans la convention que vous avez passé avec elle. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte. Vos droits sont expliqués dans un document que vous pouvez obtenir auprès de votre banque.
                        </Disclaimer>
                    </CustomDialog>
                )}
            </React.Fragment>
        )
    }
};

export default ConfirmPopup;