import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { dismissError, autoDismiss } from '../actions';

const ErrorWrapper = styled.div`
	background-color: ${props => setAlertType(props.alertType)};
	min-height: 40px;
	padding: 16px;
	position: fixed;
	bottom: 100px;
	left: 10%;
	right: 10%;
	z-index: 1000;
	border-radius: 10px;
`;

const ErrorText = styled.p`
	margin: 0px 12px 4px 0px;
	color: #ffffff;
	text-align: center;
`;
const ErrorClose = styled.div`
	position: absolute;
	top: 4px;
	right: 0px;
	width: 20px;
	height: 20px;
	color: #ffffff;
	font-size: 18px;
`;

const setAlertType = (type) => {
	if (type === 'ERROR') {
		return '#dc3545';
	} else if (type === 'WARNING') {
		return '#ffc107';
	} else if (type === 'SUCCESS') {
		return '#28a745';
	}
}

class ErrorsDisplayContainer extends Component {

	componentDidUpdate(prevProps) {
		if (this.props.errors.list.length > prevProps.errors.list.length) {
			setTimeout(() => {
				this.props.autoDismiss();
			}, 30*1000);
		}
	}

	handleDismiss = () => {
		this.props.dismissError();
	};

	render() {
		const { errors } = this.props;

		if (errors.list.length > 0) {
			return (
				<ErrorWrapper alertType={errors.list[0].type} onClick={this.handleDismiss}>
					<ErrorText>{errors.list[0].message||'Erreur, merci de ressayer plus tard ou de contacter le support téléphonique.'}</ErrorText>
					<ErrorText>{errors.list[0].messageSecondary||''}</ErrorText>
					<ErrorClose>&#10006;</ErrorClose>
				</ErrorWrapper>
			);
		}
		return null;
	}
}

const mapStateToProps = ({ errorsReducer }) => ({ errors: errorsReducer });

const mapDispatchToProps = {
	dismissError,
	autoDismiss
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ErrorsDisplayContainer);
