/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Content from './../components/common/Content';
import { setCurrentFlow, validateFlow } from '../actions';

const Container = styled(Content)`
	display: flex;
	flex-direction: column;
`;

const Title = styled.h1`
	font-family: "Futura Bold";
	font-size: 24px;
	line-height: 110%;
	color: #FF7D12;
	padding-top: 15px;
	padding-bottom: 48px;
    @media (max-width: 999px) {
        & {
			text-align: center;
			padding-bottom: 32px;
        }
    }
`;

const CustomLink = styled(Link)`
	width: 100%;
	max-width: 850px;
	height: 140px;
	border: 2px solid #dbdee7;
	border-radius: 10px;
	padding: 24px;
	margin: 0px auto 16px auto;
	background-color: #ffffff;
	text-decoration: none;
	&:hover {
		border: 2px solid ${props => props.mode.feat===true ? props.mode.colors.main : '#ff7d12'};
		background-color: ${props => props.mode.feat===true ? props.mode.colors.lightMain : '#fff8f3'};
	}
    @media (max-width: 999px) {
        & {
			height: initial;
			padding: 16px;
			border-width: 1px;
        }
		&:hover {
			border-width: 1px;
		}
    }
`;

const LinkContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
    @media (max-width: 999px) {
        & {
			flex-direction: column;
			align-items: center;
			text-align: center;
        }
    }
`;

const ImageContainer = styled.div`
	width: 130px;
	height: 95px;
	margin-right: 35px;
    line-height: 140px;
	
    @media (max-width: 999px) {
        & {
			width: 33%;
			margin-right: 0px;
			margin-bottom: 8px;
			max-width: 120px;
        }
    }
`;
const Image = styled.img`
	object-fit: contain;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
`;

const LinkText = styled.div`
	flex: 1;
`;
const LinkTitle = styled.h3`
	color: #404040;
	margin-top: 0;
	margin-bottom: 8px;
	line-height: 110%;
	font-size: 22px;
	font-weight: 600;
    @media (max-width: 999px) {
        & {
			font-size: 20px;
        }
    }
`;
const LinkInfo = styled.div`
	line-height: 110%;
	font-size: 18px;
	color: #8C97A1;
    @media (max-width: 999px) {
        & {
			font-size: 16px;
        }
    }
`;

class HomeContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			images: {
				truck: {
					now: require('./../assets/img/truck-icon.svg'),
					main: require('./../assets/img/truck-icon.svg'),
					hover: require('./../assets/img/truck-icon-active.svg'),
				},
				bigbag: {
					now: require('./../assets/img/bigbag-icon.svg'),
					main: require('./../assets/img/bigbag-icon.svg'),
					hover: require('./../assets/img/bigbag-icon-active.svg'),
				},
				dumpster: {
					now: require('./../assets/img/dumpster-icon.svg'),
					main: require('./../assets/img/dumpster-icon.svg'),
					hover: require('./../assets/img/dumpster-icon-active.svg'),
				},
				orders: {
					now: require('./../assets/img/orders-icon.svg'),
					main: require('./../assets/img/orders-icon.svg'),
					hover: require('./../assets/img/orders-icon-active.svg'),
				},
				dumpsterPw: {
					now: require('./../assets/img/dumpster-pw-dark.png'),
					main: require('./../assets/img/dumpster-pw-dark.png'),
					hover: require('./../assets/img/dumpster-pw.png'),
				},
				craneTruck: {
					now: require('./../assets/img/truck-crane-dark.png'),
					main: require('./../assets/img/truck-crane-dark.png'),
					hover: require('./../assets/img/truck-crane.png'),
				},
				gearRental: {
					now: require('./../assets/img/gear-rental-dark.png'),
					main: require('./../assets/img/gear-rental-dark.png'),
					hover: require('./../assets/img/gear-rental.png'),
				},
				asbestos: {
					now: require('./../assets/img/asbestos-dark.png'),
					main: require('./../assets/img/asbestos-dark.png'),
					hover: require('./../assets/img/asbestos.png'),
				},
				dangerousWaste: {
					now: require('./../assets/img/dangerous-waste-dark.png'),
					main: require('./../assets/img/dangerous-waste-dark.png'),
					hover: require('./../assets/img/dangerous-waste.png'),
				},
			},
		}
	}

	componentDidMount() {
		if(this.props.flowtype === 'dumpster') {
			this.props.validateFlow({...this.props.dumpsterOrderDetails, previousOrderId: null});
		}
	}

	setFlow = flow => () => {
		this.props.setCurrentFlow(flow);
	};

	handleLogoMouseOver = flow => () => {
		this.setState({
			images: {
				...this.state.images,
				[flow]: {
					...this.state.images[flow],
					now: this.state.images[flow].hover,
				},
			},
		});
	}

	handleLogoMouseOut = flow => () => {
		this.setState({
			images: {
				...this.state.images,
				[flow]: {
					...this.state.images[flow],
					now: this.state.images[flow].main,
				},
			},
		});
	}

	render() {
		const { activeCraneTrucks, mode } = this.props;
		return (
			<Container>
				<Title>Comment voulez-vous évacuer vos déchets ?</Title>

				<CustomLink mode={mode}
					to="/truck"
					onClick={this.setFlow('truck')}
					onMouseOver={this.handleLogoMouseOver('truck')}
					onMouseOut={this.handleLogoMouseOut('truck')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.truck.now} alt="logo-truck" />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Sacs à gravats et vrac</LinkTitle>
							<LinkInfo>
								Une équipe de deux Ripeurs se déplace sur votre chantier en camion utilitaire pour évacuer vos déchets en sacs / vrac sur le trottoir ou à l'étage.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>

				<CustomLink mode={mode}
					to="/bigbag"
					onClick={this.setFlow('bigbag')}
					onMouseOver={this.handleLogoMouseOver('bigbag')}
					onMouseOut={this.handleLogoMouseOut('bigbag')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.bigbag.now} alt="logo-big-bags" />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Big bags</LinkTitle>
							<LinkInfo>
								Nous collectons vos big bags en camion-grappin sur vos chantiers de toute l'Île de France.
								</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>

				<CustomLink mode={mode}
					to="/dumpster"
					onClick={this.setFlow('dumpster')}
					onMouseOver={this.handleLogoMouseOver('dumpster')}
					onMouseOut={this.handleLogoMouseOut('dumpster')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.dumpster.now} alt="logo-dumpster" />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Bennes</LinkTitle>
							<LinkInfo>
								Nous proposons un service de pose, de retrait et de stationnement (45min) de bennes.
								</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>

				<CustomLink mode={mode}
					to="/orders"
					onMouseOver={this.handleLogoMouseOver('orders')}
					onMouseOut={this.handleLogoMouseOut('orders')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.orders.now} alt="logo-orders" />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Mes commandes bennes</LinkTitle>
							<LinkInfo>
								Historique de vos commandes bennes. Vous avez la possibilité d'effectuer des retraits directement dans cette section.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>

				{Object.entries(activeCraneTrucks).some((item) => item[1].active === true) && (
					<CustomLink mode={mode}
						to="/crane-truck"
						onClick={this.setFlow('craneTruck')}
						onMouseOver={this.handleLogoMouseOver('craneTruck')}
						onMouseOut={this.handleLogoMouseOut('craneTruck')}
					>
						<LinkContentContainer>
							<ImageContainer>
								<Image src={this.state.images.craneTruck.now} />
							</ImageContainer>
							<LinkText>
								<LinkTitle>Camions-grues</LinkTitle>
								<LinkInfo>
									La solution sans manutention, adaptée aux chantiers difficiles d’accès, en 48h dans toute l'Île-de-France.
								</LinkInfo>
							</LinkText>
						</LinkContentContainer>
					</CustomLink>
				)}

				<CustomLink mode={mode}
					to="/new-services#construction-equipment"
					onMouseOver={this.handleLogoMouseOver('dumpsterPw')}
					onMouseOut={this.handleLogoMouseOut('dumpsterPw')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.dumpsterPw.now} />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Bennes TP</LinkTitle>
							<LinkInfo>
								La solution adaptée aux chantiers de terrassement et décharges sauvages, en 48h dans toute l'Île-de-France.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>
				<CustomLink mode={mode}
					to="/new-services#construction-equipment"
					onMouseOver={this.handleLogoMouseOver('gearRental')}
					onMouseOut={this.handleLogoMouseOut('gearRental')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.gearRental.now} />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Location d'engins</LinkTitle>
							<LinkInfo>
								La solution adaptée aux chantiers de terrassement et décharges sauvages, en 48h dans toute l'Île-de-France.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>
				<CustomLink mode={mode}
					to="/new-services#dangerous"
					onMouseOver={this.handleLogoMouseOver('asbestos')}
					onMouseOut={this.handleLogoMouseOut('asbestos')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.asbestos.now} />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Amiantes</LinkTitle>
							<LinkInfo>
								La solution adaptée aux chantiers comportant de l'amiante, en 72h dans toute l'Île-de-France.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>
				<CustomLink mode={mode}
					to="/new-services#dangerous"
					onMouseOver={this.handleLogoMouseOver('dangerousWaste')}
					onMouseOut={this.handleLogoMouseOut('dangerousWaste')}
				>
					<LinkContentContainer>
						<ImageContainer>
							<Image src={this.state.images.dangerousWaste.now} />
						</ImageContainer>
						<LinkText>
							<LinkTitle>Déchets dangereux</LinkTitle>
							<LinkInfo>
								La solution adaptée aux chantiers comportant des déchets spécifiques, en 72h dans toute l'Île-de-France.
							</LinkInfo>
						</LinkText>
					</LinkContentContainer>
				</CustomLink>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	flowtype: state.orderReducer.flow,
	activeCraneTrucks: state.initializationReducer.activeCraneTrucks,
    dumpsterOrderDetails: state.orderReducer.dumpsterOrderDetails,
	mode: state.mainReducer.mode,
});

const mapDispatchToProps = {
	setCurrentFlow,
	validateFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
