import React from 'react';
import styled from 'styled-components';


const Results = styled.div`
	position: absolute;
	width: 100%;
	font-family: 'Futura';
    font-size: 14px;
	border: 1px solid ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	border-radius: 4px;
	background-color: #ffffff;
`;
const Result = styled.div`
	overflow: hidden;
	padding: 10px 10px 10px 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-bottom: 1px solid #eeeeee;
	cursor: ${props => props.selectable!==false?'pointer':''};
	:last-child {
		border-bottom: 0px none;
	}
	${props => props.selectable!==false?`
	:hover {
		color: #ffffff;
		background-color: ${props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
	}
	`:''}
`;


class Autocomplete extends React.Component {
    render() {
		let {
			results,
			resultText,
			resultEmptyText,
			resultEndText,
			resultSelect,
			mode,
		} = this.props;
		results = results||[]

		let variables = resultText.match(/\{\{([a-z]+)\}\}/ig)||[];
		
        return (
			<Results mode={mode}>
				{results.length>0?(
					results.map((result) => {
						let text = resultText;
						for(let variable of variables) {
							let key = variable.replace('{{','').replace('}}','');
							text = text.replace(variable, result[key]);
						}
						return (
							<Result
								mode={mode}
								key={text}
								onClick={() => resultSelect(result)}
							>
								{text}
							</Result>
						);
					})
				):(
					<Result selectable={false}>
						{resultEmptyText}
					</Result>
				)}
				{resultEndText?(
					<Result onClick={() => resultSelect(null)}>
						{resultEndText}
					</Result>
				):null}
			</Results>
		);
    }
}

export default Autocomplete;