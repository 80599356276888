import { takeLatest, call, put } from 'redux-saga/effects';
import { signUp, login, getUserMe, passwordReset } from '../services/api';
import lsp from '../services/localStoragePower';

export const userSaga = {
    watchLoginRequest: takeLatest('LOGIN', loginProcess),
    watchLoginTokenRequest: takeLatest('LOGIN_TOKEN', loginTokenProcess),
    watchPasswordResetRequest: takeLatest('PASSWORD_RESET', passwordResetProcess),
    watchPasswordResetSucessStop: takeLatest('PASSWORD_RESET_SUCCESS_STOP', passwordResetSucessStop),
    watchSignUpRequest: takeLatest('SIGNUP', signUpProcess),
}

function* loginProcess(action) {
    try {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'loginLoading', value: true } });

        const result = yield call(login, action.payload);

        lsp.setItem('token', result.data.token);

        yield put({ type: 'LOGIN_SUCCESS', payload: result.data });
    } catch (error) {
        yield put({ type: 'ERROR', payload: error.response.data });
    } finally {
        yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'loginLoading' } });
    }
}
function* loginTokenProcess(action) {
    try {
        lsp.setItem('token', action.payload.token);
        const result = yield call(getUserMe);
        yield put({
            type: 'LOGIN_SUCCESS',
            payload: {
                token: action.payload.token,
                user: result.data,
            },
        });
        if(action.payload.appLock === true) {
            yield put({ type: 'APP_LOCK_SUB' });
        }
    } catch (error) {
        if(action.payload.appLock === true) {
            yield put({ type: 'APP_LOCK_SUB' });
        }
        yield put({ type: 'ERROR', payload: error.response.data });
    }
}
function* passwordResetProcess(action) {
    yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'passwordResetLoading', value: true } });

    const result = yield call(passwordReset, action.payload);
    if(result.reset === true) {
        yield put({
            type: 'SET_ACTION_STATE',
            payload: {
                variable: 'passwordResetSuccess',
                value: true,
            },
        });
        yield put({
            type: 'SUCCESS',
            payload: {
                message: `Vous allez recevoir un email (à l'adresse "${action.payload.email}") pour changer votre mot de passe, dans quelques minutes.`,
            },
        });
    } else {
        yield put({
            type: 'ERROR',
            payload: {
                message: `L'adresse email entrée n'existe pas, merci de la vérifier et recommencez.`,
                messageSecondary: `Si le problème persiste, contactez notre service client.`,
            },
        });
    }

    yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'passwordResetLoading' } });
}
function* passwordResetSucessStop() {
    yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'passwordResetSuccess' } });
}

function* signUpProcess(action) {
    try {
        yield put({ type: 'SET_ACTION_STATE', payload: { variable: 'signUpLoading', value: true } });

        const result = yield call(signUp, action.payload);

        lsp.setItem('token', result.data.token);

        (window.dataLayer||[]).push({
            event: 'rip_user_sign-up',
            method: 'email',
        });

        yield put({ type: 'LOGIN_SUCCESS', payload: result.data });
    } catch (error) {
        if(error.response !== undefined
        && error.response.data !== undefined
        && error.response.data.error !== undefined
        && error.response.data.error.code === 'EMAIL_ALREADY_TAKEN') {
            yield put({ type: 'ERROR', payload: {
                message: `Cette adresse email existe déjà.`,
                messageSecondary: `Connectez-vous pour accéder à votre compte.`,
            } });
        } else {
            yield put({ type: 'ERROR', payload: error.response.data.error });
        }
    } finally {
        yield put({ type: 'CLEAR_ACTION_STATE', payload: { variable: 'signUpLoading' } });
    }
}
