import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Content from './../components/common/Content';
import { resetState } from './../actions'

const Container = styled(Content)`
	min-height: calc(100vh - 92px - 70px);
    padding-bottom: 60px;
    text-align: center;
`;

const Title = styled.div`
    padding: 120px 0;
    font-size: 46px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    @media (max-width: 999px) {
        & {
            padding: 60px 0;
        }
    }
`;

const Text = styled.div`
    font-size: 24px;
    color: #8C97A1;
    text-align: left;
    padding-bottom: 100px;
`;

const Paragraph = styled.div`
    margin: 0px auto;
    max-width: 800px;
    padding-bottom: 30px;
    line-height: 110%;
`;

const HomeButton = styled(Link)`
    font-family: 'Futura';
    font-size: 18px;
    text-decoration: none;
    padding: 15px 40px;
    color: ${props => props.mode.feat===true?props.mode.colors.main:'#ff7d12'};
    color: white;
    border: none;
    border-radius: 5px;
    width: 260px;
    cursor: pointer;
`;

class EndContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flowtype: props.flowtype,
        };
    }

    componentDidMount() {
        this.props.resetState();
    }

    render() {
        const { flowtype } = this.state;
        const { orderId, mode } = this.props;

        return (
            <Container>
                <Title mode={mode}>Merci !</Title>
                <Text>
                    {flowtype === 'truck' ? (
                        <Paragraph>
                            Lors de l'évacuation, les Ripeurs vous appeleront 30 minutes avant d'arriver sur le créneau que vous avez choisi.
                        </Paragraph>
                    ) : (
                        <Paragraph>
                            Je vous confirme que nous avons bien reçu votre demande 
                            {(flowtype === 'bigbag') && " d'enlèvement de big bags"}
                            {(flowtype === 'dumpster') && " de benne"}
                            {(flowtype === 'craneTruck') && " d'évacuation par camion-grue"}
                            .
                            Vous allez recevoir un SMS de confirmation dans les plus brefs délais, dès que la commande sera mise au planning.
                        </Paragraph>
                    )}
                    <Paragraph>Numéro de commande : {orderId}</Paragraph>
                </Text>
                <HomeButton mode={mode} to="/home">Revenir à l'accueil</HomeButton>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        orderId: state.orderReducer.lastOrderId,
        flowtype: state.orderReducer.flow,
        mode: state.mainReducer.mode,
    };
};

const mapDispatchToProps = {
    resetState
}

export default connect(mapStateToProps, mapDispatchToProps)(EndContainer);